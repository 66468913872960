import React, { useState } from 'react';
import CustomCheckBox from '../../users/coursesuser/checkbox';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

const CategoryFilter = () => {
  // State to manage visibility of additional checkboxes
  const [showMore, setShowMore] = useState(false);

  // State to track which checkboxes are selected
  const [checkboxState, setCheckboxState] = useState({
    'Mobile & Laptop': false,
    'Chairs': false,
    'Mobile': false,
    'Laptop': false,
    'tv': false,
    'iphone': false,
    // Add more categories if needed
  });

  // List of categories
  const categories = [
    'Mobile & Laptop', 'Chairs', 'Mobile ', 
    'Mobile & Laptop', 'tv', 'Laptop', 'iphone',
  ];

  // Toggle checkbox state
  const handleCheckboxChange = (category) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [category]: !prevState[category], // Toggle the checkbox state
    }));
  };

  return (
    <div>
      {/* Displaying the checkboxes for the first 3 categories */}
      {categories.slice(0, 3).map((category, index) => (
        <CustomCheckBox
          key={index}
          title={category}
          isChecked={checkboxState[category]} // Pass the state of the checkbox
          onChange={() => handleCheckboxChange(category)} // Handle checkbox toggle
        />
      ))}

      {/* Displaying the remaining categories if "Show More" is toggled */}
      {showMore &&
        categories.slice(3).map((category, index) => (
          <CustomCheckBox
            key={index + 3}
            title={category}
            isChecked={checkboxState[category]} // Pass the state of the checkbox
            onChange={() => handleCheckboxChange(category)} // Handle checkbox toggle
          />
        ))}

      {/* Button to toggle visibility of more checkboxes */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="text-custom-border font-bold flex items-center space-x-2"
          onClick={() => setShowMore(!showMore)}
        >
          <span>{showMore ? 'Show Less' : 'Show More'}</span>
          <div className="flex justify-between items-center">
            {showMore ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default CategoryFilter;
