import React, { useState } from 'react';
import CustomCheckBox from './checkbox';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';
const DataFilter = () => {
  const [showMore, setShowMore] = useState(false);
  const dates = [
    'January', 'February', 'March', 'April',
    'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December',
  ];

  return (
    <div>
      {dates.slice(0, 3).map((location, index) => (
        <CustomCheckBox key={index} title={location} />
      ))}
      {showMore && dates.slice(3).map((location, index) => (
        <CustomCheckBox key={index + 3} title={location} />
      ))}
      <div className='flex justify-between items-center mt-4'>
        <button 
          className='text-custom-border font-bold flex items-center space-x-2'
          onClick={() => setShowMore(!showMore)}
        >
          <span>{showMore ? 'Show Less' : 'Show More'}</span>
          <div className='flex justify-between items-center '>
            {showMore ? (
              <ChevronUpIcon className='h-5 w-5' />
            ) : (
              <ChevronDownIcon className='h-5 w-5' />
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default DataFilter;
