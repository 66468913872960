import React, { useRef } from 'react';
import CustomButton from '../sharedcomponent/custombutton';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import imageUrl from '../../../asset/images/Rectangle 42326.png';
import iconImage from '../../../asset/images/webinar 1 (1).png';
import CustomBigTitle from './custombigtitle';

const HorizontalScrollList = () => {
  const scrollRef = useRef(null);

  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -300, behavior: 'smooth' });
    }
  };

  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 300, behavior: 'smooth' });
    }
  };

  return (
    <div className='
       xxxs:mx-12 xxs:mx-12 xs:mx-12 
    xxxs:px-4 xxs:px-4 xs:px-4 xxxxs:px-4 xxxxxs:px-4
     lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 22xl:px-24 3xl:px-24
        mx-2
      mb-20 pt-20 justify-center items-center'>
      <CustomButton name='Consultations' />
      <div className='mb-10'>
        <CustomBigTitle title='SEE A LOT OF OUR CONSULTATIONS HERE' />
      </div>
      <div className="relative
      flex items-center">
        {/* Left Arrow */}
        <button
          onClick={scrollLeft}
          className="absolute left-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md hover:bg-yellow-800 focus:outline-none"
        >
          <ArrowLeftIcon className='w-6 h-6' />
        </button>

        {/* Scrollable Container */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto space-x-6 p-4 w-full scrollbar-hidden"
          style={{ scrollSnapType: 'x mandatory', paddingLeft: '30px', paddingRight: '30px' }}
        >
          {/* List Items */}
          {[...Array(10).keys()].map((i) => (
            <div
              key={i}
              className="relative flex-none 
            
              xxxs:w-full xxs:w-full 
              xs:w-full sm:w-1/2 lg:w-1/4 xl:w-1/4 1xl:w-1/4 11xl:w-1/4 2xl:w-1/4 
              3xl:w-1/4  rounded-lg shadow-lg py-4 bg-white
              xxxs:px-2 xxs:px-2 xs:px-2
              sm:px-8 lg:px-8 xl:px-8 1xl:px-8 11xl:px-8 2xl:px-8 22xl:px-8 3xl:px-8
              "
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="relative mb-5 h-40 overflow-hidden rounded-md bg-gray-200 group-hover:opacity-75">
                <img
                  alt="Main"
                  src={imageUrl}
                  className="h-full w-full object-cover object-center"
                />
              </div>
              <div className="absolute xxxs:bottom-[180px] xxs:bottom-[150px] 
                xs:bottom-[150px] sm:bottom-[100px] lg:bottom-[180px] xl:bottom-[130px]
                1xl:bottom-[120px] 11xl:bottom-[130px] 2xl:bottom-[150px] 22xl:bottom-[100px]  3xl:bottom-[120px] 
                left-1/2 transform -translate-x-1/2 w-14 h-14 bg-white rounded-custom overflow-hidden shadow-lg flex items-center justify-center">
                <img
                  alt="Overlapping"
                  src={iconImage}
                  className="h-8 w-8 object-center"
                />
              </div>
              <div className='flex flex-col items-center justify-center mt-5'>
              <div className="flex items-center justify-center">
    <h2 
  className="lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm  2xl:text-lg 22xl:text-lg 3xl:text-lg text-big-text-color font-semibold cursor-pointer" 
>
Management Consulting
</h2>

</div>

                <div className='flex items-center'>
                  <p className='sm:text-xs lg:text-xs xl:text-xs 1xl:text-xs 11xl:text-xs  2xl:text-sm  22xl:text-sm  3xl:text-sm text-gray-600 text-center'>
                    Providing administrative and investment consultations and specialized advisory sessions to companies, businessmen, and individuals.
                  </p>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        <button
          onClick={scrollRight}
          className="absolute right-[-50px] z-10 p-2 text-white bg-primary-color rounded-full shadow-md hover:bg-yellow-800 focus:outline-none"
        >
          <ArrowRightIcon className='w-6 h-6' />
        </button>
      </div>
    </div>
  );
};

export default HorizontalScrollList;
