import React from 'react';
import {BookOpenIcon ,TvIcon,TrophyIcon,UsersIcon,GiftIcon,CurrencyDollarIcon,UserIcon,ArrowTrendingUpIcon} from '@heroicons/react/24/outline'
import CustomSectionDashBoard from './customsectiondashboard';
const DashboardCard =()=>{

    return (
        <div className="mt-5">
        <h2 className="text-3xl text-big-text-color font-bold">
          Dashboard
        </h2>
        <hr className=" mt-4 mb-4 ml-2 mr-40"></hr>
         <div className="flex">
        <CustomSectionDashBoard
            title="30"
            subTitle="Enrolled Courses"
            icon={BookOpenIcon }
            iconColor="bg-blue-200"
            backgroundColor="bg-blue-100"
            titleColor="text-blue-700"
            subTitleColor="text-blue-500"
          />
            <CustomSectionDashBoard
            title="40"
            subTitle="Active Courses"
            icon={TvIcon }
            iconColor="bg-purple-200"
            backgroundColor="bg-purple-100"
            titleColor="text-purple-700"
            subTitleColor="text-purple-500"
          />
            <CustomSectionDashBoard
            title="50"
            subTitle="Complete Courses"
            icon={TrophyIcon}
            iconColor="bg-yellow-200"
            backgroundColor="bg-yellow-100"
            titleColor="text-yellow-700"
            subTitleColor="text-yellow-500"
          />
        </div>
        <div className='flex'>
        <CustomSectionDashBoard
            title="20"
            subTitle="Instructors"
            icon={UserIcon }
            iconColor="bg-pink-300"
            backgroundColor="bg-pink-200"
            titleColor="text-pink-800"
            subTitleColor="text-pink-600"
          />
             <CustomSectionDashBoard
            title="160"
            subTitle="compaines"
            icon={ArrowTrendingUpIcon}
            iconColor="bg-pink-200"
            backgroundColor="bg-pink-100"
            titleColor="text-pink-700"
            subTitleColor="text-pink-500"
          />
            <CustomSectionDashBoard
            title="40"
            subTitle="Books"
            icon={TvIcon }
            iconColor="bg-purple-200"
            backgroundColor="bg-purple-100"
            titleColor="text-purple-700"
            subTitleColor="text-purple-500"
          />
        </div>
        <div className="flex">
        <CustomSectionDashBoard
            title="160"
            subTitle="Total Students"
            icon={UsersIcon }
            iconColor="bg-pink-200"
            backgroundColor="bg-pink-100"
            titleColor="text-pink-700"
            subTitleColor="text-pink-500"
          />
            <CustomSectionDashBoard
            title="20"
            subTitle="rate"
            icon={GiftIcon }
            iconColor="bg-pink-300"
            backgroundColor="bg-pink-200"
            titleColor="text-pink-800"
            subTitleColor="text-pink-600"
          />
            <CustomSectionDashBoard
            title="20.00+"
            subTitle="Total"
            icon={CurrencyDollarIcon}
            iconColor="bg-blue-300"
            backgroundColor="bg-blue-200"
            titleColor="text-blue-800"
            subTitleColor="text-blue-600"
          />
        </div>
       
       </div>
    )
}

export default DashboardCard;