import React from "react";
import { StarIcon } from '@heroicons/react/24/solid';

const StarReview = () => {
  return (
    <div className="flex items-center">
      <div className="flex">
        <StarIcon className="h-4 w-4 text-custom-border" />
        <StarIcon className="h-4 w-4 text-custom-border" />
        <StarIcon className="h-4 w-4 text-custom-border" />
        <StarIcon className="h-4 w-4 text-custom-border" />
        <StarIcon className="h-4 w-4 text-custom-border" />
      </div>
      <p className="ml-1 truncate text-xs leading-5 text-gray-500">(18 reviews)</p>
    </div>
  );
};

export default StarReview;
