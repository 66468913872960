import React from 'react';

const ListInBell = () => { 
    const items = [
        {
            title: 'Default Course Title',
            instructor: 'Default Instructor',
        },
        {
            title: 'Another Default Course Title',
            instructor: 'Another Instructor',
        },
    ];

    return (
        <div className='space-y-4'>
            {items.map((item, index) => (
                <div key={index} className='flex justify-between'>
                        <div className='ml-4'>
                            <h3 className='text-lg text-big-text-color font-bold'>
                                {item.title}
                            </h3>
                            <h3 className='text-sm text-big-text-color'>
                                {item.instructor}
                            </h3>
                        </div>
                
    
                </div>
            ))}
        </div>
    );
};

export default ListInBell;
