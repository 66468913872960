import React, { useState, useEffect ,useRef } from 'react';
import DropDownButton from './dropdownbutton';
import arrowIcon from '../../../asset/images/searchicon.png';
import { MagnifyingGlassIcon } from '@heroicons/react/24/outline';
import SearchList from './searchListCourses'; // Make sure to import your SearchList component

const menuListSearchName = {
  name: 'all categories ',
};

const menuItemsSearchName = [
  { id: 1, name: 'Course', link: '#' },
  { id: 2, name: 'Book', link: '#' },
  { id: 3, name: 'Product', link: '#' },
  { id: 4, name: 'Course', link: '#' },
  { id: 5, name: 'Book', link: '#' },
  { id: 6, name: 'Product', link: '#' },
];


const SearchBarHomeUser = () => {
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [searchText, setSearchText] = useState(''); // State to store the input text
  const [isOpen, setIsOpen] = useState(false); // State to track if the icon is "open" or clicked
  const iconRef = useRef(null); // Reference to the icon container

  const handleFocus = () => {
    setDropdownOpen(true); // Show the dropdown when input is focused
  };

  const handleBlur = () => {
    // Close the dropdown when input loses focus
    setTimeout(() => {
      setDropdownOpen(false);
    }, 100); // Allow click event on dropdown to register before closing
  };

  const handleClickOutside = (event) => {
    // Close dropdown if the user clicks outside of the search input or dropdown
    if (!event.target.closest('.dropdown-container')) {
      setDropdownOpen(false);
    }
  };

  // Use effect to handle click outside the component
  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  // Handler for updating the search text
  const handleInputChange = (e) => {
    setSearchText(e.target.value); // Update state when user types
  };
  return (
    <div className="relative flex rounded-custom bg-transparent w-[420px] border border-custom-gray p-2 items-center justify-center hidden-mobile">
      <div className="p-1 flex items-center justify-center">
        <DropDownButton
          menuItems={menuItemsSearchName}
          menuList={menuListSearchName}
          textColor="custom-gray"
          arrowimage={arrowIcon}
        />
      </div>
      <div
        className="mt-2 inline-block h-[15px] min-h-[1em] w-[1px] self-stretch bg-custom-gray text-custom-gray items-center"
      ></div>
      <div className="flex flex-1 items-center px-2">
        {/* First input */}
        <input
          type="text"
          className="bg-transparent flex-1 pl-2 text-big-text-color placeholder-custom-gray outline-0"
          placeholder="Search..."
          value={searchText} // Bind to state
          onChange={handleInputChange} // Update state on change
          onFocus={handleFocus}
          onBlur={handleBlur}
        />
          <div
      ref={iconRef} // Reference to the clickable container
      onFocus={handleFocus}
      onBlur={handleBlur}
      className="cursor-pointer"
      role="button"
      tabIndex={0} // Makes the div focusable via keyboard
    >
      <MagnifyingGlassIcon className="w-6 h-6 ml-2 text-custom-gray" />
      {isOpen && <div className="absolute bg-white p-2">Icon clicked or focused</div>}
    </div>
      </div>

      {/* Dropdown */}
      {isDropdownOpen && (
        <div className="dropdown-container fixed inset-0 w-full h-full flex justify-center items-center z-50">
          <div className="absolute left-1/2 transform -translate-x-1/2 px-6 top-[150px]
          py-4 w-[90%] sm:w-full bg-gray-50 border border-gray-300 rounded-md shadow-lg">
            <div className="relative mt-2 rounded-md shadow-sm">
              {/* Second input */}
              <input
                id="price"
                name="price"
                type="text"
                placeholder="What are you looking for?"
                value={searchText} // Bind to the same state
                className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
            <SearchList searchText={searchText} />
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchBarHomeUser;