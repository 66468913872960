import React, { useRef } from 'react';
import image from '../../../asset/images/team-03.webp.png';
import CustomTitleAndImage from '../../../components/users/sharedcomponent/customtitleandimage'; // Make sure the path is correct
import { ArrowLeftIcon,ArrowRightIcon } from '@heroicons/react/24/solid';
const ReviewsInsideCourse = () => {
  const scrollContainerRef = useRef(null);

  const scroll = (direction) => {
    if (scrollContainerRef.current) {
      const scrollAmount = direction === 'left' ? -300 : 300; // Adjust the scroll amount as needed
      scrollContainerRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  return (
    <div className='xxxs:w-[350px] xxs:w-[350px] xs:w-[380px] 
       sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
     bg-white rounded-custom mt-5 p-4 relative'>
       <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl
        sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl
         font-bold text-big-text-color'>
       Reviews
      </h3>
      <div className="relative mt-5 mb-5 mr-10 ml-10 pr-6 pl-6">
        {/* Left Arrow */}
        <button
          className="absolute top-1/2 left-0 transform -translate-y-1/2 bg-big-text-color p-2 rounded-full shadow-md hover:bg-gray-400"
          onClick={() => scroll('left')}
        >
          <ArrowLeftIcon className='w-4 h-4 text-white'/>
        </button>

        {/* Right Arrow */}
        <button
          className="absolute top-1/2 right-0 transform -translate-y-1/2 bg-big-text-color p-2 rounded-full shadow-md hover:bg-gray-400"
          onClick={() => scroll('right')}
        >
          <ArrowRightIcon className='w-4 h-4 text-white'/>
        </button>

        <div
          className="flex overflow-x-auto scrollbar-hidden whitespace-nowrap scroll-smooth"
          ref={scrollContainerRef}
        >
          {/* Example of multiple items */}
          {[1, 2, 3, 4, 5].map((item) => (
            <div key={item} className="flex-shrink-0  xxxs:w-[200px] xxs:w-[200px] xs:w-[200px]
             sm:w-[350px] lg:w-[350px] xl:w-[350px] 1xl:w-[350px] 11xl:w-[350px] 2xl:w-[350px]
              3xl:w-[350px] p-4 bg-white rounded-lg shadow-lg mr-4 ml-4 ">
        
            <p className="xxxs:text-xs xxs:text-xs xs:text-xs sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm mt-2 text-center whitespace-normal mb-4 text-big-text-color">
            Online leaning, vulputate at sapien sit amet,
auctor iaculis lorem. In vel hend rerit nisi.
Vestibulum eget risus velit.
              </p>
              <div className="flex items-center justify-center">
      <CustomTitleAndImage
        imageSrc={image}
        title="Shams"
      />
    </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewsInsideCourse;
