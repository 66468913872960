import React from "react";
import CustomTextRefund from './customtext'; // A custom component for reusable text blocks
import image from '../../../asset/images/Rectangle 42337.png'; // Use an image if necessary

const StackRefund = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Gradient Background */}
      <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        Refund Policy
      </div>
      <div
        className="relative top-[-230px] xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-28 lg:mx-28 xl:mx-28 1xl:mx-28 11xl:mx-28 2xl:mx-28 3xl:mx-28 mt-10 mb-10 bg-white flex flex-col shadow-lg items-center justify-center rounded-lg shadow-lg"
      >
        <h3 className="text-2xl font-bold mb-2 mt-10 text-big-text-color">
          Refund Policy
        </h3>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Refund Eligibility
          </h3>
          <CustomTextRefund title="You can request a refund within [X] days of purchase. To be eligible for a refund, the product must be unused and in the original packaging." />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Non-Refundable Items
          </h3>
          <CustomTextRefund title="Certain items, such as gift cards, downloadable software, or customized products, are not eligible for refunds. Please check the product description before purchasing." />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Refund Process
          </h3>
          <CustomTextRefund title="To request a refund, please contact us via email or the contact form on our website. Once your refund is approved, we will process it within [X] business days." />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Damaged or Defective Products
          </h3>
          <CustomTextRefund title="If you receive a damaged or defective product, please notify us immediately at [Your Contact Email] and we will arrange for a return, replacement, or refund." />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Refund Method
          </h3>
          <CustomTextRefund title="Refunds will be issued to the original payment method. Please note that depending on your bank or payment provider, it may take additional business days for the funds to reflect in your account." />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold text-start mb-3 text-big-text-color">
            Contact Information
          </h3>
          <CustomTextRefund title="For questions regarding refunds or to request a refund, please contact us at [Your Contact Email] or [Your Contact Phone Number]." />
        </div>
      </div>
    </div>
  );
};

export default StackRefund;
