import React from 'react';
import { Link } from 'react-router-dom';
import img from '../../../asset/images/Icon.png';
const CustomLink = ({ to, children }) => {
  return (
    <Link
      to={to}
      className="flex xxxs:text-xs xxs:text-xs xs:text-xs  sm:text-sm mid:text-sm md:text-sm lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg  3xl:text-lg
       items-center text-big-text-color hover:text-big-text-color mb-2"
    >
    <img
    src={img}
    alt={img}
    className='mr-2'
    />
      {children}
    
    </Link>
  );
};

export default CustomLink;
