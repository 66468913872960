import React, { useState, useRef, useEffect } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import IndustryFilter from '../eventsuser/industryfilter';

const CustomSortedForMobileMembership = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative justify-center items-center" ref={dropdownRef}>
      {/* Button to Toggle Dropdown */}
      <button
        onClick={toggleDropdown}
        className="p-2 bg-primary-color text-white rounded justify-center items-center"
      >
        Sort Options
      </button>

      {/* Dropdown Content */}
      {isDropdownOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 mt-2 rounded shadow-lg z-10 p-4">
          {/* INDUSTRY Section */}
          <div>
            <CustomSideTitle title="INDUSTRY" />
            <IndustryFilter />
          </div>

          {/* ROLE Section */}
          <div>
            <CustomSideTitle title="Role" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBox title="Student" />
              <CustomCheckBox title="Employee" />
              <CustomCheckBox title="Manager" />
            </div>
          </div>

          {/* DURATION Section */}
          <div>
            <CustomSideTitle title="DURATION" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBox title="1 Month" />
              <CustomCheckBox title="5 Months" />
              <CustomCheckBox title="1 Year" />
              <CustomCheckBox title="2 Years" />
              <CustomCheckBox title="5 Years" />
              <CustomCheckBox title="10 Years" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileMembership;
