import { faker } from "@faker-js/faker";
import {
  useTable,
  useGlobalFilter,
  useSortBy,
  usePagination,
} from "react-table";
import { useMemo, useCallback } from "react";
import {
  ArrowLongLeftIcon,
  ArrowLongRightIcon,
  ArrowUpIcon,
  ArrowLongDownIcon,
} from "@heroicons/react/24/outline";

const generateData = (numberOfRows = 25) =>
  [...Array(numberOfRows)].map(() => ({
    name: faker.name.fullName(),
    accountNumber: faker.finance.account(8),
    accountName: faker.finance.accountName(),
    amount: faker.finance.amount(500, 1e4, 2, "$"),
  }));

const getColumns = () => [
  {
    Header: "Student",
    accessor: "name",
    Cell: ({ value }) => (
      <div className="flex gap-2 items-center">{value}</div>
    ),
  },
  {
    Header: "Data",
    accessor: "accountNumber",
  },
  {
    Header: "Feedback",
    accessor: "accountName",
  },
];

function Button2({ content, onClick, active, disabled }) {
  return (
    <button
      className={`flex flex-col text-big-text-color cursor-pointer items-center justify-center w-9 h-9 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-sm font-normal transition-colors rounded-lg
      ${active ? "bg-red-500 text-white" : "text-red-500"}
      ${!disabled ? "bg-white hover:bg-red-500 hover:text-black" : "text-red-300 bg-red cursor-not-allowed"}
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function PaginationNav1({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
}) {
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 3;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });
    return pageIndices.map((pageIndexToMap) => (
      <li key={pageIndexToMap}>
        <Button2
          content={pageIndexToMap + 1}
          onClick={() => gotoPage(pageIndexToMap)}
          active={pageIndex === pageIndexToMap}
        />
      </li>
    ));
  }, [pageCount, pageIndex]);

  return (
    <ul className="flex gap-2">
      <li>
        <Button2
          content={<ArrowLongLeftIcon className="h-4 w-4" />}
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
      </li>
      {renderPageLinks()}
      <li>
        <Button2
          content={<ArrowLongRightIcon className="h-4 w-4" />}
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </li>
    </ul>
  );
}

function TableComponent({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
}) {
  return (
    <div className="w-full max-w-screen-lg mx-auto p-4 bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
      <table {...getTableProps()} className="min-w-full">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-6 py-4 text-start text-2xl text-big-text-color cursor-pointer"
                >
                  <div className="flex gap-2 items-center">
                    <div className="text-big-text-color">
                      {column.render("Header")}
                    </div>
                    <div className="flex flex-col">
                      <ArrowUpIcon
                        className={`text-sm translate-y-1/2 ${
                          column.isSorted && !column.isSortedDesc
                            ? "text-big-text-color"
                            : "text-gray-500"
                        }`}
                      />
                      <ArrowLongDownIcon
                        className={`text-sm -translate-y-1/2 ${
                          column.isSortedDesc ? "text-big-text-color" : "text-gray-500"
                        }`}
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-100">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="p-4 text-lg font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function Table1() {
  const data = useMemo(() => generateData(100), []);
  const columns = useMemo(getColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page: rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex flex-col gap-4">
      <TableComponent
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        prepareRow={prepareRow}
      />
      <div className="flex justify-center">
        <PaginationNav1
          gotoPage={gotoPage}
          canPreviousPage={canPreviousPage}
          canNextPage={canNextPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
        />
      </div>
    </div>
  );
}

function TableReviewReceived() {
  return (
    <div className="flex flex-col overflow-auto py-4 sm:py-0">
      <Table1 />
    </div>
  );
}

export default TableReviewReceived;
