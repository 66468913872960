import React from 'react';
import StarReview from '../../admin/reviews';
import CustomTitleAndImage from '../sharedcomponent/customtitleandimage';
import image from '../../../asset/images/logo.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { ClockIcon, GlobeAltIcon} from '@heroicons/react/24/outline';
import img from '../../../asset/images/Icongray.png';
import { Link } from 'react-router-dom';
import CustomIconAndTextDate from '../sharedcomponent/customTitleAndIconDates';

const CustomDivCourseInside = ({title , navTitle ,navTitleTwo, onClick,
  reviewTitle = 'By Tarek Tantawy',
  imageSrc,
  price ,
  date ,
  language ,
  description = 'No description available.',
  showReview = false,
  showPrice = true,
  showDate = true,
  showLanguage = true,
  showLanguageIcon = false, 
  showLanguageText = true, 
  showDateIcon = true, 
  showDateText = true ,
  languageIcon
}) => {
  return (
    <div className='pt-4 pb-4 xxxs:px-8 xxs:px-8 xs:px-8 xxxxs:px-8  xxxxxs:px-8
     sm:px-8 mid:px-8 md:px-8 lg:px-28 xl:px-28 1xl:px-28 11xl:px-28 2xl:px-28 3xl:px-28 bg-custom-gradient-div min-h-[300px] lg:min-h-[400px] text-white'>
         <div className='flex items-center mt-10 '>
         <Link to="/">
        <h3 className="text-xs font-bold mr-4 text-big-text-color cursor-pointer">
          Home
        </h3>
      </Link> 
          <img
            src={img}
            alt="Icon"
            className=' w-2 h-3'
          />
        <h3 className='text-xs  font-normal ml-4  mr-4 text-big-text-color'
            onClick={onClick}
        >
        {navTitle}
        </h3>
           <img
            src={img}
            alt="Icon"
            className=' w-2 h-3'
          />
           <h3 className='text-xs  font-bold ml-4 text-big-text-color'
           >{navTitleTwo}</h3>

        </div>
      <div className='flex'>
        <h3 className='text-2xl font-bold mt-3 mr-5 text-big-text-color mb-3'>{title}</h3>
      </div>
   <div className='xxxs:w-[400px] xxs:w-[400px] xs:w-[400]
    sm:w-[700px] lg:w-[700px] xl:w-[780px] 1xl:w-[780px] 11xl:w-[780px] 2xl:w-[900px] 3xl:w-[900px]'>
   <p className="mb-5 text-sm text-gray-700 pr-20">
  Enhance your understanding of English grammar and improve your writing techniques. 
  Emphasis is placed on sentence structure, punctuation, and style to develop clear, effective communication. 
  Analyze a diverse range of texts, including novels, short stories, poetry, and drama. 
  Learn to interpret themes, motifs, and literary devices while honing your critical thinking and analytical skills.
</p>
   </div>

   {showReview && <StarReview />}
      {imageSrc && <CustomTitleAndImage imageSrc={imageSrc} title={reviewTitle} />}
      {showPrice && <h3 className='text-lg font-bold mt-3 mr-5 text-gray-800'>{price}</h3>}
      <div className="flex mt-5">
        {showDate && (
          <CustomIconAndTextDate
            icon={showDateIcon ? <ClockIcon className="h-4 w-4 text-gray-500" /> : null}
            title={showDateText ? date : ''}
          />
        )}
       {showLanguage && (
          <CustomIconAndText
            icon={showLanguageIcon ? languageIcon : null}
            title={showLanguageText ? language : ''}
          />
        )}
      </div>
  
    </div>
  );
};

export default CustomDivCourseInside;
