import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomNameAndImageOnly = ({ imageSrc, title, navigateTo }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Handler for the title click event
  const handleTitleClick = () => {
    // Navigate to the provided route when the title is clicked
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div className="flex items-center mt-2">
      <img 
        alt="" 
        src={imageSrc} 
        className="xxxs:h-5 xxxs:w-5 xxs:h-5 xxs:w-5 xs:h-5 xs:w-5 
        sm:h-10 sm:w-10
         lg:h-8 lg:w-8 xl:h-8 xl:w-8 1xl:h-8 1xl:w-8 11xl:h-8 11xl:w-8 2xl:h-8 2xl:w-8 3xl:h-8 3xl:w-8 rounded-custom" 
      />
      <div className='flex'>
        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-500 ml-2">By</h3>
        <button 
          onClick={handleTitleClick} 
          className="text-base
          lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm
          font-semibold leading-7 tracking-tight text-gray-500 ml-1 underline cursor-pointer"
        >
          {title}
        </button>
      </div>
    </div>
  );
};

export default CustomNameAndImageOnly;
