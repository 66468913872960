import React from 'react';

const CustomButton = ({ name }) => {
  return (
    <div className='flex justify-center'>
      <div className='mb-5 items-center'>
        <div
          className=" bg-transparent
           xxxs:text-xl xxs:text-xl xs:text-xl xxxxs:text-xl xxxxxs:text-xl
           sm:text-xl lg:text-2xl xl:text-2xl 1xl:text-3xl
           11xl:text-3xl 2xl:text-3xl 22xl:text-3xl 3xl:text-3xl
            font-semibold text-primary-color  font-sans " 
        >
          {name}
        </div>
      </div>
    </div>
  );
};

export default CustomButton;
