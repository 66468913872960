import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomCheckBoxStar from '../coursesuser/checkboxreview';
import CategoryFilter from '../shopuser/categoryfilter';
import DataFilter from '../coursesuser/datafilteration';
import IndustryFilter from '../eventsuser/industryfilter';

const CustomSortedForMobileBlogs = () => {
  const [isExpanded, setIsExpanded] = useState(false); // For main filters toggle
  const [expandedSections, setExpandedSections] = useState({}); // For individual sections

  // Toggles the visibility of the entire filter panel
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  // Toggles the visibility of specific sections
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="w-full max-w-xs mx-auto bg-white mt-2 rounded-custom justify-center">
      {/* Master Header */}
      <div
        className="flex justify-between items-center p-4 cursor-pointer border-b border-gray-300"
        onClick={toggleExpand}
      >
        <h3 className="text-lg font-semibold">Filters</h3>
        <span>{isExpanded ? '-' : '+'}</span>
      </div>

      {/* Expandable Content */}
      {isExpanded && (
        <div className="p-4 space-y-4">
          {/* ACCESS Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('access')}
            >
              <CustomSideTitle title="ACCESS" />
              <span>{expandedSections.access ? '-' : '+'}</span>
            </div>
            {expandedSections.access && <CustomCheckBox title="Member Benefits" />}
          </div>

          {/* CATEGORIES Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('categories')}
            >
              <CustomSideTitle title="CATEGORIES" />
              <span>{expandedSections.categories ? '-' : '+'}</span>
            </div>
            {expandedSections.categories && <CategoryFilter />}
          </div>

          {/* INDUSTRY Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('industry')}
            >
              <CustomSideTitle title="INDUSTRY" />
              <span>{expandedSections.industry ? '-' : '+'}</span>
            </div>
            {expandedSections.industry && <IndustryFilter />}
          </div>

          {/* DATE Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('date')}
            >
              <CustomSideTitle title="DATE" />
              <span>{expandedSections.date ? '-' : '+'}</span>
            </div>
            {expandedSections.date && <DataFilter />}
          </div>

          {/* Language Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('language')}
            >
              <CustomSideTitle title="Sort By Language" />
              <span>{expandedSections.language ? '-' : '+'}</span>
            </div>
            {expandedSections.language && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="Arabic" />
                <CustomCheckBox title="English" />
              </div>
            )}
          </div>

          {/* Rating Section */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('rating')}
            >
              <CustomSideTitle title="RATING" />
              <span>{expandedSections.rating ? '-' : '+'}</span>
            </div>
            {expandedSections.rating && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                  ]}
                  title="5 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-gray-300',
                  ]}
                  title="4 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="3 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="2 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="1 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="0 STAR"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileBlogs;
