import React from "react";

const CustomSideTitle =({title})=>{
    return (
     <div>
            <h3 className=" mt-5 mb-2 lg:text-lg  xl:text-lg 2xl:text-2xl font-bold text-big-text-color">{title}</h3>
     <hr className="mb-2"></hr>
     </div>
    )
}
export default CustomSideTitle;