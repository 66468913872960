import React from 'react';

const Divider =()=>{

    return (
        <div
        className=" mt-1 inline-block h-[20px] min-h-[1em] w-0.5 self-stretch bg-gray-600  items-center"
      ></div>
    )
}
export default Divider;