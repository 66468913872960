import React from 'react';
import GrayIcon from '../../../asset/images/Icongray.png';
const CustomTitleAndSubTitle =({title,subTitle})=>{
    return (
       <div>
        <div className='flex justify-between py-3 px-6'>
        <div className='flex'>
            <img 
            src={GrayIcon}
            alt={GrayIcon}
            className='w-2 h-2 mr-5'
            />
      <h2 className='text-xs text-gray-500 '>
      {title}
      </h2>
        </div>
        <div className='bg-gray-200 text-xs p-1 text-big-text-color'>
        {subTitle}
        </div>
        </div>
        <hr>
        </hr>
       </div>
    )

}

export default CustomTitleAndSubTitle;