import React from "react";
import imageLeadOne from '../../../asset/images/leadone.png';
import imageLeadTwo from '../../../asset/images/leadtwo.png';
import CustomText from '../privacyuser/customText';

const StackLeads = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Gradient Background */}
        <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        Gradient Background
      </div>
      <div
        className="relative top-[-230px] xxxs:mx-4 xxs:mx-4 xs:mx-4 
        sm:mx-28 lg:mx-28 xl:mx-28 1xl:mx-28 11xl:mx-28 2xl:mx-28 3xl:mx-28 mt-10  bg-white flex flex-col  shadow-lg items-center justify-center rounded-lg shadow-lg"
      >
        <h3 className="text-2xl font-bold mb-2 mt-10 text-big-text-color">
        LEADS
        </h3>
        <h3 className="text-lg text-gray-600 text-center">
        Course Leads Here.
        </h3>
        <img
          src={imageLeadOne}
          alt="Privacy Policy"
          className='w-full h-[400px] object-cover mt-20'
        />
        <div className='
        xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
            Welcome to Privacy Policy
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='4. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
        <div className=' xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl 
          font-bold text-start mb-3 text-big-text-color">
            The type of personal information we collect
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
        <div className=' xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
          font-bold text-start mb-3 text-big-text-color">
            How we collect personal information
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
        <img
          src={imageLeadTwo}
          alt="Privacy Policy"
          className='w-full h-[400px] object-cover mt-20'
        />
        <div className=' xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
          font-bold text-start mb-3 text-big-text-color">
            Welcome to Privacy Policy
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='4. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
        <div className=' xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
          font-bold text-start mb-3 text-big-text-color">
            The type of personal information we collect
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='3. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
        <div className=' xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="
          xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
            How we collect personal information
          </h3>
          <CustomText title='1. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
          <CustomText title='2. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure .'/>
        </div>
      </div>
    </div>
  );
};

export default StackLeads;
