import React from 'react';

const ResourceDetials =()=>{
    return (
      <div className='xxxs:w-[350px] xxs:w-[350px] xs:w-[380px] 
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px]  
        bg-white rounded-custom mt-5 p-2'>
      <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
      Details
        </h3>
         <div className="mx-auto my-5 w-full max-w-4xl px-5">
      <div className="overflow-hidden rounded-lg border border-big-text-color">
        <table className="min-w-full border-collapse w-full">
          <tbody className="bg-white">
          <tr className="border-b border-gray-300">
          <td className="px-4 py-2 xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  text-big-text-color font-bold border-r border-big-text-color">
          ISBN
  </td>
  <td className="px-4 py-2  xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  border-r ">
 9781957157344
  </td>
</tr>
<tr className="border-b border-gray-300">
<td className="px-4 py-2 xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  text-big-text-color font-bold border-r border-big-text-color">
Pages
  </td>
  <td className="px-4 py-2  xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  border-r ">
  528
  </td>
</tr>
<tr className="border-b border-gray-300">
<td className="px-4 py-2 xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  text-big-text-color font-bold border-r border-big-text-color">
Publication Date
  </td>
  <td className="px-4 py-2  xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  border-r ">
  May 2024
  </td>
</tr>
<tr className="border-b border-gray-300">
<td className="px-4 py-2 xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  text-big-text-color font-bold border-r border-big-text-color">
Formats
  </td>
  <td className="px-4 py-2  xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  border-r ">
  Paperback
  </td>
</tr>
<tr className="border-b border-gray-300">
<td className="px-4 py-2 xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  text-big-text-color font-bold border-r border-big-text-color">
Product Code
  </td>
  <td className="px-4 py-2  xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg  border-r ">
  112402
  </td>
</tr>

     
        </tbody>
      </table>
    </div>
    </div>
      </div>
    )
}

export default ResourceDetials;