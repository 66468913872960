import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomCheckBoxStar from '../coursesuser/checkboxreview';
import CategoryFilter from '../shopuser/categoryfilter';
import DataFilter from '../coursesuser/datafilteration';
import IndustryFilter from '../eventsuser/industryfilter';
import CollapsAll from '../coursesuser/collapsall';

const SortedSideBarBlogs = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Default collapsed

  // Manage the checked state for all checkboxes
  const [checkedState, setCheckedState] = useState({
    "Member Benefits": false,
    "Arabic": false,
    "English": false,
    "5 STAR": false,
    "4 STAR": false,
    "3 STAR": false,
    "2 STAR": false,
    "1 STAR": false,
    "0 STAR": false,
  });

  const handleCheckboxChange = (title) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the specific checkbox
    }));
  };

  const handleCollapseToggle = () => setIsCollapsed(prev => !prev);

  return (
    <div
      className={`lg:w-[300px] xl:w-[300px] 2xl:w-[300px] border border-custom-border p-4 rounded-lg bg-white 
           sm:mr-10 lg:mr-10 xl:mr-10 1xl:mr-10 11xl:mr-10 2xl:mr-10 3xl:mr-10 
      transition-all duration-300 ${isCollapsed ? 'sticky top-[80px] z-30' : 'relative'}`}
    >
      <CollapsAll onClick={handleCollapseToggle} />
      <div className={`transition-all ${isCollapsed ? 'opacity-100 ' : 'opacity-100 max-h-[1500px]'}`}>
        {/* Default content visible when collapsed */}
        <div className={`transition-all ${isCollapsed ? 'block' : 'hidden'}`}>
          <CustomSideTitle title='ACCESS' />
          <CustomCheckBox 
            title='Member Benefits' 
            isChecked={checkedState['Member Benefits']} 
            onChange={() => handleCheckboxChange('Member Benefits')} 
          />
          <CustomSideTitle title='CATEGORIES' />
          <CategoryFilter />
        </div>

        {/* Full content visible when expanded */}
        <div className={`transition-all ${isCollapsed ? 'hidden' : 'block'}`}>
          <CustomSideTitle title='ACCESS' />
          <CustomCheckBox 
            title='Member Benefits' 
            isChecked={checkedState['Member Benefits']} 
            onChange={() => handleCheckboxChange('Member Benefits')} 
          />
          <CustomSideTitle title='CATEGORIES' />
          <CategoryFilter />
          <CustomSideTitle title='INDUSTRY' />
          <IndustryFilter />
          <CustomSideTitle title='DATE' />
          <DataFilter />
          <CustomSideTitle title='Sort By Language' />
          <CustomCheckBox 
            title='Arabic' 
            isChecked={checkedState['Arabic']} 
            onChange={() => handleCheckboxChange('Arabic')} 
          />
          <CustomCheckBox 
            title='English' 
            isChecked={checkedState['English']} 
            onChange={() => handleCheckboxChange('English')} 
          />
          <CustomSideTitle title="Rating" />
          <CustomCheckBoxStar 
            starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border']} 
            title="5 STAR" 
            isChecked={checkedState['5 STAR']} 
            onChange={() => handleCheckboxChange('5 STAR')} 
          />
          <CustomCheckBoxStar 
            starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300']} 
            title="4 STAR" 
            isChecked={checkedState['4 STAR']} 
            onChange={() => handleCheckboxChange('4 STAR')} 
          />
          <CustomCheckBoxStar 
            starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300']} 
            title="3 STAR" 
            isChecked={checkedState['3 STAR']} 
            onChange={() => handleCheckboxChange('3 STAR')} 
          />
          <CustomCheckBoxStar 
            starColors={['text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
            title="2 STAR" 
            isChecked={checkedState['2 STAR']} 
            onChange={() => handleCheckboxChange('2 STAR')} 
          />
          <CustomCheckBoxStar 
            starColors={['text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
            title="1 STAR" 
            isChecked={checkedState['1 STAR']} 
            onChange={() => handleCheckboxChange('1 STAR')} 
          />
          <CustomCheckBoxStar 
            starColors={['text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
            title="0 STAR" 
            isChecked={checkedState['0 STAR']} 
            onChange={() => handleCheckboxChange('0 STAR')} 
          />
        </div>
      </div>
    </div>
  );
};

export default SortedSideBarBlogs;
