import React from 'react';
import { ChevronDoubleRightIcon } from '@heroicons/react/24/solid';

const CustomDivAuth = () => {
  return (
    <div className='flex justify-center items-center pt-4 pb-4 xxxs:px-10 xxs:px-10 xs:px-10 sm:px-20 lg:px-28 xl:px-28 1xl:px-28 11xl:px-28 2xl:px-28 3xl:px-28 bg-custom-gradient-div min-h-[300px] lg:min-h-[350px] text-white'>
      <div className='flex items-center'>
        <h3 className='text-3xl text-big-text-color mr-3'>Login</h3>
        <ChevronDoubleRightIcon className='w-12 h-12 mr-3' />
        <h3 className='text-3xl text-big-text-color'>Registration</h3>
      </div>
    </div>
  );
};

export default CustomDivAuth;

