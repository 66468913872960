import React , { useState } from 'react';
import { HomeIcon,UserIcon,BookOpenIcon,BookmarkIcon ,StarIcon,ExclamationCircleIcon,
    DocumentDuplicateIcon,
    ComputerDesktopIcon,
    SpeakerWaveIcon,
    ChatBubbleLeftIcon,
    Bars3Icon,
    Cog8ToothIcon,
    ArrowLeftEndOnRectangleIcon
} from '@heroicons/react/24/outline'; 
import MyProfileDescriptionCard from './descriptionmyprofilecard';
import ListOfWishListCourses from './listofwishlist';
import ReviewAdmin from '../admin/reviewadminview';
import MyAttempts from '../instructor/myattempts';
import HistoryOrders from '../admin/historyorders';
import CustomDashBoard from './customdashboard';
import {Tablecoursesdashboard} from './tablecourse';
import ListOfMyCourses from './listofmycourses';
import AnnouncementsView from './announcemnetview';
import AssignmentsView from './assignmentsview';
import SettingInstructorFormUser from './settinginstructorform';
const SidebarInstructor = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard'); // State to keep track of selected menu item

    const handleMenuItemClick = (menuItem) => {
      setSelectedMenuItem(menuItem);
    };
  return (
   <div className='flex'>
     <div className=" border border-primary-color rounded-custom mt-5 mr-20 ml-20 mb-20 w-64 h-200 ">
      <div className="p-4  text-2xl font-semibold  text-big-text-color">
Welcome, Jone Due
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'dashboard' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('dashboard')}>
      <HomeIcon className="h-5 w-5 mr-2"/>
        Dashboard</li>
        <li className={`flex items-center px-4 py-2  text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'profile' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('profile')}>
        <UserIcon className="h-5 w-5 mr-2"/>
        My Profile</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Enrolled' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Enrolled')}>
            <BookOpenIcon className="h-5 w-5 mr-2"/>
         Courses</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Wishlist' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Wishlist')}>
            <BookmarkIcon className="h-5 w-5 mr-2"/>
        Coming Courses</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Reviews' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Reviews')}>
            <StarIcon  className="h-5 w-5 mr-2"/>
        Reviews</li>
      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Students
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Courses' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Courses')}>
      <ComputerDesktopIcon className="h-5 w-5 mr-2"/>
            My Courses</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Announcements' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Announcements')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            Announcements</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'MyAttempts' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('MyAttempts')}>
            <ChatBubbleLeftIcon className="h-5 w-5 mr-2"/>
            Quiz Attempts</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Assignments' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Assignments')}>
            <Bars3Icon className="h-5 w-5 mr-2"/>
            Assignments</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      User
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Settings' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Settings')}>
      <Cog8ToothIcon className="h-5 w-5 mr-2"/>          
        Settings</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div  cursor-pointer ${selectedMenuItem === 'Logout' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Logout')}>
        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2"/>         
           Logout</li>
      </ul>
   
    </div>
    {selectedMenuItem === 'dashboard' && (
          <div>
         <div className='w-[1000px]'>
         <CustomDashBoard/>
         <Tablecoursesdashboard />
          </div>
          </div>
        )}
        {
          selectedMenuItem === 'Settings'&&(
            <div className='w-[1050px]'>
              <SettingInstructorFormUser/>
              </div>
          )
        }
          {selectedMenuItem === 'profile' && (
          <div>
            <MyProfileDescriptionCard/>
          </div>
        )}
        {selectedMenuItem === 'Wishlist'&&(
             <div className='w-[1000px]'>
                <ListOfWishListCourses/>
                </div>
        )}
       
        {selectedMenuItem === 'Reviews' && (
            <div className='w-[1000px]'>
                <ReviewAdmin/>
            </div>
        )}
        {selectedMenuItem === 'Attempts'&& (
           <div className='w-[1000px]'>
                <MyAttempts/>
                </div>
        )}
          {selectedMenuItem === 'Enrolled'&& (
            <div className='w-[1000px]'>
                <ListOfMyCourses/>
                </div>
        )}
        {selectedMenuItem === 'History'&&(
            <div>
               <HistoryOrders/>
                </div>
        )
        }
        {
          selectedMenuItem === 'Courses' && (
            <div>
               <div className='w-[1000px]'>
                <ListOfWishListCourses/>
                </div>
              </div>
          )
        }
        {
          selectedMenuItem === 'Announcements'&& (
            <div className='w-[1000px]'>
              <AnnouncementsView/>
              </div>
          )
        }
        {
          selectedMenuItem === 'MyAttempts'&&(
            <div>
              <MyAttempts/>
              </div>

          )
        }
        {
          selectedMenuItem === 'Assignments'&&(
            <div className='w-[1000px]'>
              <AssignmentsView/>
              </div>
          )
        }

   </div>
  );
};

export default SidebarInstructor;

