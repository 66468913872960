import React, { useState } from 'react';
import HeadUser from './headuser';
import CustomNavBarWithFlayout from './customnavbarwithflayout';
import CustomIconAndText from './customiconandtext';
import { BookmarkIcon, CreditCardIcon, UsersIcon } from '@heroicons/react/24/outline';
import StarReview from '../../admin/reviews';
import CustomTitleAndImageOnly from './imageandtitleonly';
import BookmarkButton from './customBookMark';
import { useNavigate } from 'react-router-dom';
import imagecourseone from '../../../asset/images/events.png';
import imageTeamOne from '../../../asset/images/team-1.webp.png';
import imageTeamTwo from '../../../asset/images/team-02.webp.png';
import imageTeamThree from '../../../asset/images/team-03.webp.png';
import imagecoursethree from '../../../asset/images/istock-1220226086.jpg';
import imageCourseTwo from '../../../asset/images/Capstone_Course.jpeg.webp';
import CustomParagraph from './customParagraph';

const products = [
  {
    id: 1,
    name: 'INSTRUCTIONAL DESIGN',
    href: '#',
    imageTeam: imageTeamOne,
    imageSrc: imagecourseone,
    studentNum: '22',
    courseNum: '44',
    contant: 'This course offers an education to the principles and practices of instructional design...',
    price: '$84',
  },
  {
    id: 2,
    name: 'UI DESIGN',
    href: '#',
    studentNum: '65',
    courseNum: '87',
    imageTeam: imageTeamTwo,
    imageSrc: imagecoursethree,
    contant: 'This course offers the principles and practices of UI design...',
    price: '$98',
  },
  {
    id: 3,
    name: 'Frontend DESIGN',
    href: '#',
    studentNum: '88',
    courseNum: '12',
    imageTeam: imageTeamThree,
    imageSrc: imageCourseTwo,
    contant: 'This course offers a common course to the principles and practices of frontend design...',
    price: '$85',
  },
  // Additional products...
];

const SearchView = ({ searchText = '' }) => {
  const [searchQuery, setSearchQuery] = useState(searchText); // State to track input text
  const navigate = useNavigate();

  // Filter products based on the searchText
  const filteredProducts = products.filter(
    (product) =>
      product.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      product.contant.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Handle input change
  const handleInputChange = (e) => {
    setSearchQuery(e.target.value); // Update searchQuery as the user types
  };

  const handleClick = () => {
    navigate('/courseInside');
  };

  return (
    <div>
      <HeadUser />
      <CustomNavBarWithFlayout />
      <div className="w-full mx-auto px-4 py-6 bg-gray-50">
        <div className="relative mt-2 rounded-md shadow-sm">
          {/* Search Input */}
          <input
            id="price"
            name="price"
            type="text"
            placeholder="What are you looking for?"
            value={searchQuery} // Bind to state
            onChange={handleInputChange} // Update state on change
            className="block w-full rounded-md border-0 py-1.5 pl-7 pr-20 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
          {filteredProducts.length > 0 ? (
            filteredProducts.map((product) => (
              <div key={product.id} className="relative flex-none rounded-lg shadow-lg bg-white p-8">
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-white lg:aspect-none group-hover:opacity-75 lg:h-40 mb-3">
                  <img alt={product.name} src={product.imageSrc} className="h-full w-full object-cover object-center" />
                </div>
                <div className="mr-5 ml-5">
                  <div className="flex justify-between">
                    <StarReview />
                    <BookmarkButton />
                  </div>
                  <h2 className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold mt-4">
                    {product.name}
                  </h2>
                  <div className="flex space-x-4 mt-2">
                    <CustomIconAndText icon={<CreditCardIcon className="h-4 w-4 text-gray-500" />} title={`${product.courseNum} Courses`} />
                    <CustomIconAndText icon={<UsersIcon className="h-4 w-4 text-gray-500" />} title={`${product.studentNum} Students`} />
                  </div>
                  <CustomParagraph title={product.contant} />
                  <CustomTitleAndImageOnly imageSrc={product.imageTeam} title="Tarek Tantawy" />
                  <div className="mt-4 mb-5 flex justify-between items-center">
                    <p className="text-lg font-medium text-gray-900">{product.price}</p>
                    <button onClick={handleClick} className="mt-1 text-sm text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center">
                      ENROLL COURSE
                    </button>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full text-center text-gray-500 mt-4">No courses found.</div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SearchView;
