import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'; // Import the component
import { faFacebookF, faGoogle } from '@fortawesome/free-brands-svg-icons';

const SocialMediaAuth = () => {
   return (
      <div className='flex justify-center p-4'>
         <FontAwesomeIcon icon={faFacebookF} className='text-big-text-color mx-2 w-8 h-8' />
         <FontAwesomeIcon icon={faGoogle} className='text-big-text-color mx-2 w-8 h-8' />
      </div>
   );
}

export default SocialMediaAuth;

