import React from 'react';
import StarReview from '../../admin/reviews';
import { BookmarkIcon, UsersIcon, CreditCardIcon } from '@heroicons/react/24/outline';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import CustomTitleAndImage from '../sharedcomponent/customtitleandimage';
import image from '../../../asset/images/team-02.webp.png';
import imagecourse from '../../../asset/images/events.png';
import img from '../../../asset/images/iconwhiteright.png';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../sharedcomponent/custombutton';
import CustomParagraph from '../sharedcomponent/customParagraph';
import BookmarkButton from '../sharedcomponent/customBookMark';
const products = [
  {
    id: 1,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },

];

const ListInsideShopsMobile = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/ShopInside');
  };

  return (
    <div className="w-full lg:w-[1400px] xl:w-[1300px] 1xl:w-[1400px] 11xl:w-[1400px] 2xl:w-[1400px] 3xl:w-[1500px] mx-auto px-2 py-6">
              <div className="  flex justify-center items-center mb-2">
              <CustomButton name='Top Shops' />

</div>
      <div className="grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 11xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-4 3xl:grid-cols-4">
        {products.map((product) => (
          <div
            key={product.id}
            className="group relative bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300"
          >
            <div>
              <img
                alt={imagecourse}
                src={imagecourse}
                className="h-50 w-full object-cover object-center rounded-md p-4"
              />
              <div className="flex-1 flex flex-col ml-4">
                <div className="flex justify-between items-center">
                  <StarReview />
                  <BookmarkButton/>
                </div>
                <h3 
  className="text-lg font-semibold text-gray-900 cursor-pointer" 
  onClick={handleClick} // Replace 'handleClick' with your actual function
>
  {product.name}
</h3>

                <div className="flex space-x-3 mt-2">
                  <CustomIconAndText icon={<CreditCardIcon className="h-5 w-5 text-gray-500" />} title='14 courses' />
                  <CustomIconAndText icon={<UsersIcon className="h-5 w-5 text-gray-500" />} title='14 students' />
                </div>
             
                <CustomParagraph
                title=' This course offers a comprehensive introduction to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.'
                />
                <div className='flex justify-between'>
                  <CustomTitleAndImage
                    imageSrc={image}
                    title="By Tarek Tantawy"
                    className="mt-2"
                  />
                  <button
                    onClick={handleClick}
                    className="mt-1 text-sm text-white border rounded-custom bg-primary-color px-4 py-1 flex items-center justify-center"
                  >
                    See Details
                    <img
                      src={img}
                      alt="Course Icon"
                      className="ml-2 justify-center items-center"
                    />
                  </button>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ListInsideShopsMobile;
