import React from "react";
import {TableCellsIcon} from "@heroicons/react/24/outline";
import StarReview from "../admin/reviews";
import image from '../../asset/images/OIF.jpg';
const products = [
    {
      id: 1,
      name: 'phone',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
    {
        id: 1,
        name: 'Tv',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 item',
      },
      {
        id: 1,
        name: 'playstation',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 item',
      },
      {
        id: 1,
        name: 'playstation',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 item',
      },
      {
        id: 1,
        name: 'playstation',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 item',
      },
      {
        id: 1,
        name: 'tv',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 item',
      },
  ]
  
  export default function ListOfProducts() {
    return (
      <div className="bg-white shadow-lg shadow-black/5 mt-10 ">
        <div className=" px-14 py-16 sm:px-12 sm:py-8  lg:px-18">
          <h2 className="text-3xl font-bold tracking-tight text-big-text-color px-4 mb-4">Products</h2>
     <hr className="px-8 py-4">
     </hr>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id} className="group relative shadow-lg rounded-custom p-2">
                <div className=" overflow-hidden rounded-md   group-hover:opacity-75 ">
                  <img
                    alt={image}
                    src={image}
                    className="h-30 w-30 object-cover object-center p-4"
                  />
                </div>
                <div className="mt-4 flex justify-between pr-4 pl-4 ">
                  <div>
<StarReview/>
                    <h3 className="text-lg text-gray-700">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0 text-big-text-color font-bold" />
                        {product.name}
                      </a>
                    </h3>
                    <div className="flex">
                    <TableCellsIcon className="w-4 h-4 text-gray-500 mr-2 mt-1"/>
                  <p className="text-sm text-gray-500">{product.subname}</p>
                    </div>
                  </div>
                  <p className="text-sm font-medium text-gray-900">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }