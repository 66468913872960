import * as React from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import Home from './pages/admin/homeadmin';
import HomeUser from './pages/users/homeuser';
import MyDashBoardAdmin from './pages/admin/mydashboardadmin';
import ContactUsUser from './pages/users/contactususer';
import PrivacyUser from './pages/users/privacyuser';
import LeadsUser from './pages/users/leadsuser';
import AllCourses from './pages/users/allcorses';
import AllEvents from './pages/users/allevents';
import AllShops from './pages/users/allshops';
import AllBlogs from './pages/users/allblogs';
import AllResourses from './pages/users/allresourses';
import AllMemberShip from './pages/users/allmembership';
import CourseInside from './pages/users/courseinside';
import SearchView from './components/users/sharedcomponent/searchview';
import EventInside from './pages/users/eventsinside';
import BlogInside from './pages/users/bloginside';
import ResourcesInside from './pages/users/resourcesinside';
import StoreInside from './pages/users/storeinside';
import MemberShipInside from './pages/users/membershipinside';
import HomeAdmin from './pages/admin/homeadmin';
import HomeInstructor from './pages/instructor/homeinstructor';
import DashBoardInstructor from './pages/instructor/dashboardinstructor';
import HomeCompany from './pages/company/homecompany';
import DashBoardCompany from './pages/company/dashboardcompany';
import  ShoppingCartDrawer  from './components/users/sharedcomponent/shoppingcartdrawer';
import Login  from './pages/auth/login';
import Registration from './pages/auth/register';
import TermsAndConditions from './pages/users/termsandconditions';
import RefundPolicy from './pages/users/refundpolicy';
const ResetScroll = ({ children }) => {
  const location = useLocation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return children;
};

const App = () => {
  return (
    <div className="App">
      <ResetScroll>
        <Routes>
          <Route path="/" element={<HomeUser/>} />
          <Route path='/mydashboardadmin' element={<MyDashBoardAdmin />} />
          <Route path='/contactus' element={<ContactUsUser />} />
          <Route path='/privacyuser' element={<PrivacyUser />} />
          <Route path='/leadsuser' element={<LeadsUser />} />
          <Route path='/allcourses' element={<AllCourses />} />
          <Route path='/allevents' element={<AllEvents />} />
          <Route path='/allshops' element={<AllShops />} />
          <Route path='/allblogs' element={<AllBlogs />} />
          <Route path='/allresourses' element={<AllResourses />} />
          <Route path='/allmembership' element={<AllMemberShip />} />
          <Route path='/courseinside' element={<CourseInside />} />
          <Route path='/searchview' element={<SearchView />} />
          <Route path ='/courseInside' element ={<CourseInside/>}/>
          <Route path = '/eventInside' element ={<EventInside/>}/>
          <Route path='/resourcesInside' element={<ResourcesInside />} />
          <Route path='/storeInside' element = {<StoreInside/>}/>
          <Route path ='/memberShipInside' element = {<MemberShipInside/>}/>
          <Route path = '/blogInside' element = {<BlogInside/>}/>
          <Route path = '/DashBoardInstructor' element ={<DashBoardInstructor/>}/>
          <Route path = '/DashBoardCompany' element ={<DashBoardCompany/>}/>
          <Route path = '/ShoppingCartDrawer' element = {< ShoppingCartDrawer />}/>
          <Route path = '/Registration' element = {<Registration/>}/>
          <Route path = '/login' element = {<Login/>}/>
          <Route path = '/TermsAndConditions' element ={<TermsAndConditions/>}/>
          <Route path = '/RefundPolicy' element={<RefundPolicy/>}/>
        </Routes>
      </ResetScroll>
    </div>
  );
}

export default App;