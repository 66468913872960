import React, { useState, useEffect } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import CustomDiv from '../../components/users/coursesuser/customdiv';
import SortedSideBarBlogs from '../../components/users/bloguser/sortsidebarblogs';
import AllBlogsGrid from '../../components/users/bloguser/allblogsgrid';
import AllBlogsList from '../../components/users/bloguser/allblogslist';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomSortedForMobileBlogs from '../../components/users/bloguser/customSortedForMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import CustomLoadMoreButton from '../../components/users/sharedcomponent/customLoadMoreButton';
const AllBlogs = () => {
    const [view, setView] = useState('grid');
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);

    const handleGridPress = () => setView('grid');
    const handleListPress = () => setView('list');

    useEffect(() => {
        const handleScroll = () => {
            setIsNavBarVisible(window.scrollY > 100);
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    return (
        <div className="relative">
            <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
                <HeadUser />
                <HeaderHomeUse />
                <CustomDivider />
                <div className='flex justify-center items-center hidden-mobile'>
                    <h2 className='text-custom-border text-lg mr-5'>Home</h2>
                    <CustomStaticFlyoutMenu  />
                </div>
            </div>
            <CustomDivider />
            <div className={`fixed mobile-only top-0 left-0  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white
             z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
            <div className='bg-gray-50 z-0'>
                <CustomDiv
                navTitle='Blogs'
                    onGridPress={handleGridPress}
                    onListPress={handleListPress}
                    title='BLOGS'
                    subTitle="Suggestions for other articles that might interest the reader, displayed at the end of each post."
                    buttonTitle="20 blogs"
                    activeView={view}
               
                />

                <div className='mobile-only'>
                <CustomSortedForMobileBlogs/>
                </div>
          <div className='relative 
        lg:top-[-140px] xl:top-[-140px]
         1xl:top-[-170px] 11xl:top-[-160px]
         2xl:top-[-140px] 22xl:top-[-150px] 3xl:top-[-150px]
            lg:mx-24 xl:mx-24 1xl:mx-24 11xl:mx-24 2xl:mx-24 22xl:mx-24 3xl:mx-24 px-1 mt-10  flex'>
        <div className='sticky top-[80px] hidden-mobile'>
                        <SortedSideBarBlogs />
                    </div>
                    <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 xxxxs:mx-4 xxxxxs:mx-4
                     sm:mx-0 mid:mx-0 md:mx-0 lg:mx-0 xl:mx-0 1xl:mx-0 11xl:mx-0 2xl:mx-0 22xl:mx-0 3xl:mx-0 ' >
                        {view === 'grid' ? <AllBlogsGrid /> : <AllBlogsList />}
                        <div className='flex justify-center items-center mt-20 xxxs:mb-10 xxs:mb-10 xs:mb-10 xxxxs:mb-10 xxxxxs:mb-10'>
  <CustomLoadMoreButton children='Load More'/>
</div>
                    </div>
                </div>
                <FooterUser />
            </div>
        </div>
    );
};

export default AllBlogs;
