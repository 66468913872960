import React from 'react';

const CustomTextRefund = ({ title }) => {
  return (
    <div className="mb-4 text-start text-gray-800">
      <p className="xxxs:text-xs xxs:text-sm xs:text-sm sm:text-md lg:text-lg xl:text-lg text-base">{title}</p>
    </div>
  );
};

export default CustomTextRefund;
