import React from "react";
import {UsersIcon ,TableCellsIcon} from "@heroicons/react/24/outline";
import StarReview from "../admin/reviews";
import image from '../../asset/images/top10-fiction-1600x800.jpg';
const products = [
    {
      id: 1,
      name: 'Shams Khaled',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 student',
      lessons:'12 lesson',
      courses : 'javascript, html ,css ,react js ,react native '
    },
    {
        id: 1,
        name: 'Shams Khaled',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
          lessons:'12 lesson',
            courses : 'javascript, html ,css ,react js ,react native '
      },
      {
        id: 1,
        name: 'Shams Khaled',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
          lessons:'12 lesson',
            courses : 'javascript, html ,css ,react js ,react native '
      },
      {
        id: 1,
        name: 'Shams Khaled',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
          lessons:'12 lesson',
            courses : 'javascript, html ,css ,react js ,react native '
      },
      {
        id: 1,
        name: 'Shams Khaled',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
          lessons:'12 lesson',
            courses : 'javascript, html ,css ,react js ,react native '
      },
      {
        id: 1,
        name: 'Shams Khaled',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
          lessons:'12 lesson',
            courses : 'javascript, html ,css ,react js ,react native '
      },
  ]
  
  export default function ListOfCourseInstructorAdmin() {
    return (
      <div className="bg-white shadow-lg shadow-black/5 ">
        <div className=" px-4 py-2 sm:px-2 sm:py-2  lg:px-2">
          <h2 className="text-3xl font-bold tracking-tight text-big-text-color px-4 mb-4">Books</h2>
     <hr className="px-8 py-4">
     </hr>
          <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
            {products.map((product) => (
              <div key={product.id} className="group relative shadow-lg rounded-custom">
                <div className=" overflow-hidden rounded-md   group-hover:opacity-75 ">
                  <img
                    alt={image}
                    src={image}
                    className="h-30 w-30 object-cover object-center p-4"
                  />
                </div>
                <div className="mt-4 flex justify-between pr-4 pl-4 ">
                  <div>
<StarReview/>
                    <h3 className="text-lg text-big-text-color font-bold">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0 text-big-text-color font-bold" />
                        {product.name}
                      </a>
                    </h3>
                  <h3 className="text-sm text-big-text-color font-normal">
                    {product.courses}
                  </h3>
                   
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }