import React, { useMemo, useCallback } from "react";
import { useTable, useGlobalFilter, useSortBy, usePagination } from "react-table";
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";

// Helper function to render star ratings
function renderStars(rating) {
  const fullStars = Math.floor(rating);
  const halfStars = rating % 1 !== 0;
  const emptyStars = 5 - fullStars - (halfStars ? 1 : 0);
  return (
    <div className="flex">
      {Array(fullStars).fill().map((_, i) => (
        <svg key={i} className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27Z" />
        </svg>
      ))}
      {halfStars && (
        <svg className="w-6 h-6 text-yellow-400" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27Z" />
        </svg>
      )}
      {Array(emptyStars).fill().map((_, i) => (
        <svg key={i} className="w-6 h-6 text-gray-300" fill="currentColor" viewBox="0 0 24 24">
          <path d="M12 17.27L18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2 9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27Z" />
        </svg>
      ))}
    </div>
  );
}

// Static data generator
const names = [
  'Alice Johnson', 'Bob Smith', 'Carol Williams', 'David Brown', 'Eve Davis',
  'Frank Miller', 'Grace Wilson', 'Hank Moore', 'Ivy Taylor', 'John Anderson'
];

const reviewRatings = [1, 1.5, 2, 2.5, 3, 3.5, 4, 4.5, 5];

function getRandomItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function generateData(numRows) {
  return Array.from({ length: numRows }, () => ({
    name: getRandomItem(names),
    number: Math.floor(Math.random() * 100) + 1, // Random number between 1 and 100
    review: getRandomItem(reviewRatings) // Random review rating from predefined list
  }));
}

function getColumns() {
  return [
    {
      Header: 'Name',
      accessor: 'name',
    },
    {
      Header: 'Enroll',
      accessor: 'number',
    },
    {
      Header: 'Review',
      accessor: 'review',
      Cell: ({ value }) => renderStars(value), // Render stars for review
    },
  ];
}

function TableComponent({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
}) {
  return (
    <div className="w-full bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
        <h2 className="text-2xl font-bold text-big-text-color">
            Courses
        </h2>
        <hr className=" mt-4 mb-4 ml-2 mr-40"></hr>

      <table {...getTableProps()} className="w-full table-auto border-separate border-spacing-6">
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="pr-6 py-4 text-start text-2xl cursor-pointer"
                  style={{ width: column.width }}
                >
                  <div className="flex gap-2 items-center">
                    <div className="text-black">
                      {column.render("Header")}
                    </div>
                    <div className="flex flex-col">
                      <ArrowUpIcon
                        className={`text-lg translate-y-1/2 ${
                          column.isSorted && !column.isSortedDesc
                            ? "text-black" : "text-black"
                        }`}
                      />
                      <ArrowDownIcon
                        className={`text-lg -translate-y-1/2 ${
                          column.isSortedDesc ? "text-black" : "text-black"
                        }`}
                      />
                    </div>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-200">
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps()}
                    className="pr-6 py-3 text-xl font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

function PaginationNav1({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
}) {
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 5;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (
        pageNumberBefore >= 0 &&
        (itemIndex < numberOfButtons / 2 || pageNumberAfter > pageCount - 1)
      ) {
        pageIndices.unshift(pageNumberBefore);
      } else {
        pageIndices.push(pageNumberAfter);
      }
    });
    return pageIndices.map((pageIndexToMap) => (
      <li key={pageIndexToMap}>
        <Button2
          content={pageIndexToMap + 1}
          onClick={() => gotoPage(pageIndexToMap)}
          active={pageIndex === pageIndexToMap}
        />
      </li>
    ));
  }, [pageCount, pageIndex]);
  return (
    <ul className="flex gap-4">
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <ArrowLongLeftIcon className="w-6 h-6" />
              <ArrowLongLeftIcon className="w-6 h-6 -translate-x-1/2" />
            </div>
          }
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        />
      </li>
      {renderPageLinks()}
      <li>
        <Button2
          content={
            <div className="flex ml-1">
              <ArrowLongRightIcon className="w-6 h-6" />
              <ArrowLongRightIcon className="w-6 h-6 -translate-x-1/2" />
            </div>
          }
          onClick={() => gotoPage(pageCount - 1)}
          disabled={!canNextPage}
        />
      </li>
    </ul>
  );
}

function Button2({ content, onClick, active, disabled }) {
  return (
    <button
      className={`flex flex-col text-black cursor-pointer items-center justify-center w-12 h-12 shadow-[0_4px_10px_rgba(0,0,0,0.03)] text-lg font-normal transition-colors rounded-lg
      ${active ? "bg-red-500 text-white" : "text-red-500"}
      ${
        !disabled
          ? "bg-white hover:bg-red-500 hover:text-white"
          : "text-red-300 bg-red cursor-not-allowed"
      }
      `}
      onClick={onClick}
      disabled={disabled}
    >
      {content}
    </button>
  );
}

function Table1() {
  const data = useMemo(() => generateData(10), []); // Generate 10 rows of data
  const columns = useMemo(getColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    page: rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 }, // Initial page size
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex flex-col gap-6 w-full">
      <TableComponent
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        prepareRow={prepareRow}
      />
      <PaginationNav1
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
      />
    </div>
  );
}

function Tablecoursesdashboard() {
  return (
    <div className="flex flex-col overflow-auto py-6  w-full">
      <Table1 />
    </div>
  );
}

export { Tablecoursesdashboard };


