import React from 'react';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { ArrowRightIcon,MapPinIcon,ClockIcon,BookmarkIcon } from '@heroicons/react/24/outline';
import CustomTitleAndImage from '../sharedcomponent/customtitleandimage';
import image from '../../../asset/images/events.png';
import img from '../../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from '../sharedcomponent/customParagraph';
import BookmarkButton from '../sharedcomponent/customBookMark';
const products = [
  {
    id: 1,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
];

const AllEventsList = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/eventInside'); // Update with the path you want to navigate to
  };
  return (
   
    <div className=" mb-20 grid grid-cols-1 gap-5 px-2 lg:w-[1100px] xl:w-[1100px] 2xl:w-full ml-10">
      {products.map((product) => (
        <div key={product.id} className="group relative bg-white p-5 flex rounded-custom shadow-lg"> <div className="w-1/3 flex-shrink-0">
            <img
              alt={image}
              src={image}
              className="h-full w-full object-cover object-center rounded-md"
            />
          </div>
          <div className="flex-1 ml-4">
            <div className="flex justify-between mt-3">
            <h2 
  className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold cursor-pointer" 
  onClick={handleClick}
>
  {product.name}
</h2>

              <BookmarkButton/>
            </div>
            <div className="flex mt-2">
              <CustomIconAndText icon={<MapPinIcon className="h-4 w-4 text-gray-500" />} title='Cairo, Egypt' />
              <CustomIconAndText icon={<ClockIcon className="h-4 w-4 text-gray-500" />} title='16 Nov, 2024' />
            </div>
            <CustomParagraph
            title='Research your target market and understand their needs and preferences.
'
            />
            <CustomTitleAndImage imageSrc={image} title="By Work Brand" />
            <div className="flex justify-between mt-2 mb-2">
              <p className="text-sm font-medium text-gray-900">{product.price}</p>
              <button 
onClick={handleClick}
className="mt-1 text-sm text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center justify-center">
See Details
<img
    src={img}
    alt="Course Icon"
    className="ml-2 justify-center items-center"
  />
</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllEventsList;