import React from 'react';
import TableOfHistoryOrders from '../instructor/tableofhistoryorders';

const HistoryOrders =()=>{
return (
 <div>
    <div className='text-lg mt-40'>
    Order History
    </div>
    <hr className='mt-10'>
    </hr>
    <div>
        <TableOfHistoryOrders/>
    </div>
 </div>
)
  
}

export default HistoryOrders;