import React, { useState, useEffect, useRef } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import CustomDivCourseInside from '../../components/users/coursesuser/customdivcourseinside';
import SideBarCourseInside from '../../components/users/coursesuser/sidebarcourseinside';
import AboutCourse from '../../components/users/coursesuser/aboutcourse';
import CustomThingsToDo from '../../components/users/coursesuser/customthingstodo';
import CustomContactInsideCourse from '../../components/users/coursesuser/customcontactinsidecourse';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import BenefitsCourse from '../../components/users/membershipuser/benefitscourse';
import MembershipButtons from '../../components/users/membershipuser/membershipbutton';
import { useNavigate } from 'react-router-dom';
import ListInsideMemberShip from '../../components/users/membershipuser/listmembershipinside';

import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import SideBarMembershipInsideMobile from '../../components/users/membershipuser/customSideInsideMembershipMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import ListInsideMemberShipMobile  from '../../components/users/membershipuser/listMembershipInsideMobile';
import image from '../../asset/images/d1af7d099058eb24786ab692d7f35bbc.png';

const MemberShipInside = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/allmembership'); 
  };
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('info');
  const [isThreeButtonsVisible, setIsThreeButtonsVisible] = useState(false);

  const aboutCourseRef = useRef(null);
  const courseContentRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      // Update navbar visibility
      setIsNavBarVisible(currentScrollTop > 100);

      // Update active section based on scroll position
      const sections = ['info', 'Benefits','things-to-know'];
      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const { offsetTop, clientHeight } = element;
          if (currentScrollTop >= offsetTop && currentScrollTop < offsetTop + clientHeight) {
            setActiveSection(sectionId);
          }
        }
      });

      // Handle visibility of ThreeButtonsInRow
      if (courseContentRef.current) {
        const courseContentTop = courseContentRef.current.offsetTop;
        setIsThreeButtonsVisible(currentScrollTop >= courseContentTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-100 z-0'>
        <CustomDivCourseInside
         date="16 Nov, 2024" 
         onClick={handleClick}
         showDate={true}
         imageSrc={image}
        navTitle='Membership'
        navTitleTwo='Gold Membership'
        title='GOLD MEMBERSHIP'
        />
        <div className='mobile-only'>
        <SideBarMembershipInsideMobile/>
        </div>
        <div className="flex  
        xxxs:m-2 xxs:m-2 xs:m-2 sm:m-10 lg:m-10 xl:m-10 1xl:m-10 11xl:m-10 2xl:m-10 3xl:m-10
        lg:px-16 xl:px-16 1xl:px-16 11xl:px-16 2xl:px-16 3xl:px-20">
          <div className="flex-grow ">
            {isThreeButtonsVisible && (
              <div className={`transition-transform duration-200 ${isThreeButtonsVisible ? 'fixed top-[80px] z-40' : 'relative'}`}>
                <MembershipButtons activeSection={activeSection} />
              </div>
            )}
                  <MembershipButtons activeSection={activeSection} />
            <div id="info" ref={aboutCourseRef}>
              <AboutCourse />
            </div>
            <div id="Benefits" ref={courseContentRef}>
              <BenefitsCourse />
            </div>
            <div id='things-to-know'>
              <CustomThingsToDo title='Things To Know'/>
            </div>
            <CustomContactInsideCourse />
          </div>
          <div className=' relative top-[-350px] w-[400px] mb-10 mt-10
           sm:ml-10 lg:ml-10 xl:ml-20 1xl:ml-20 11xl:ml-20 2xl:ml-20 3xl:ml-20 pl-3 hidden-mobile '>
            <div className='sticky top-[80px] '> {/* Adjust top value to match height of CustomNavBarWithFlayout */}
              <SideBarCourseInside />
            </div>
          </div>
        </div>

   <div className='hidden-mobile'>
   <ListInsideMemberShip className="m-0 p-0" />
   </div>
<div className='mobile-only'>
<ListInsideMemberShipMobile />
</div>

      </div>
      <FooterUser />
    </div>
  );
};

export default MemberShipInside;