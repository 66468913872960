import React from 'react';
const CustomDivAdmin =({name})=>{
    return (
     <div className='bg-big-text-color m-4'>
       <a
      href="#"
      className="flex items-center justify-center h-[200px] w-[300px] rounded-custom bg-big-text-color px-6 py-4 m-4 text-sm font-semibold text-white shadow-sm hover:bg-custom-border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
    >
      {name}
    </a>
     </div>
    )
}

export default CustomDivAdmin;