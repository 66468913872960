import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CustomRightCheckBox from './customrightcheckbox'; // Ensure the path is correct
import imageMemberShip from '../../../asset/images/membershipimage.png';
import img from '../../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';

const products = [
  {
    id: 1,
    title: 'Professional Membership',
    subtitle: '5 Month',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    priceBefore: '$50',
    color: 'Black',
  },
  {
    id: 2,
    title: 'Professional Membership',
    subtitle: '5 Month',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    priceBefore: '$50',
    color: 'Black',
  },
];

const  AllMemberShipGrid =() =>{
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/memberShipInside'); 
  };
  return (
    <div className="flex w-full mx-auto xxxs:px-0 xxs:px-0 xs:px-0 
    sm:px-6 lg:px-8 xl:px-2 1xl:px-2  11xl:px-2  2xl:px-2 22xl:px-2 3xl:px-2">
    <div className="w-full  mx-auto">
      <div className="grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3
       xl:grid-cols-3 11xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
        {products.map((product) => (
           <div key={product.id}
           className="group relative
           xxxs:w-full xxs:w-full xs:w-full sm:w-full 
            lg:w-[240px] xl:w-[240px] 11xl:w-[250px] 1xl:w-[260px] 2xl:w-[310px]
             22xl:w-[350px]
              3xl:w-[300px] lg:mx-0 xl:mx-0 2xl:mx-10 xxxs:2xl:mx-0 xxs:mx-0 xs:mx-0 sm:mx-0  md:mx-0 mid:mx-0 
              p-2
               bg-white rounded-lg shadow-lg
                overflow-hidden transition-transform duration-300 transform hover:scale-105">
               <h2 
  className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold cursor-pointer text-center" 
  onClick={handleClick}
>
{product.title}
</h2>

              <h4 className="text-md font-semibold leading-6 
              tracking-tight text-gray-600 text-center">{product.subtitle}</h4>
              <img
                src={imageMemberShip}
                alt={imageMemberShip}
                className="w-66 h-50 rounded-custom object-center object-cover my-4"
              />
              <div>
                <div>
                  <CustomRightCheckBox title='NEW! Course Body of Knowledge' />
                  <CustomRightCheckBox title='Templates and Tools Library' />
                  <CustomRightCheckBox title='Videos and Webinars' />
                  <CustomRightCheckBox title='Digital Magazine Subscription' />
                  <CustomRightCheckBox title='Annual State of the Industry Report' />
                  <CustomRightCheckBox title='Members-Only Newsletters & Articles' />
                  <CustomRightCheckBox title='Case Studies' />
                  <CustomRightCheckBox title='Research Whitepapers' />
                  <CustomRightCheckBox title='Members-Only Discounts' />
                  <CustomRightCheckBox title='Unlimited Access to Professional Specialists' />
                </div>
                <div className="flex justify-between items-center mt-4">
                  <h3 className="text-xl font-semibold leading-8 tracking-tight text-yellow-600">{product.price}</h3>
                  <button
                  onClick={handleClick}
                  className="text-base text-custom-border border rounded-custom border-custom-border bg-transparent px-4 py-2 flex items-center hover:bg-gray-100 transition-colors justify-center items-center">
                  Join Now 
                  <img
src={img}
alt="Course Icon"
className="ml-2 justify-center items-center"
/>
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
export default AllMemberShipGrid