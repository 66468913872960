import React from 'react';

const CustomData = ({ icon: Icon, title, children }) => {
    return (
        <div className='flex flex-col items-center
         sm:mx-5 lg:mx-5 xl:mx-5 1xl:mx-5 11xl:mx-5 2xl:mx-5 22xl:mx-5 3xl:mx-5 
         xxxs:my-5 xxs:my-5 xs:my-5
         sm:my-2 lg:my-2 xl:my-2 1xl:my-2 11xl:my-2 2xl:my-2 22xl:my-2 3xl:my-2 
         p-6 
        xxxs:w-full xxs:w-full xs:w-full
        sm:w-1/2 lg:w-1/2 xl:w-1/2 1xl:w-1/2 11xl:w-1/2 2xl:w-1/2 22xl:w-1/2 3xl:w-1/2 bg-gray-100
         shadow-lg rounded-lg'>
            {/* Render the icon with a static size */}
            <div className='w-10 h-10 text-yellow-700 mb-5'>
                <Icon className='w-full h-full' />
            </div>
            <h3 className='text-2xl font-bold mb-3 text-big-text-color'>{title}</h3>
            <div>
                {children}
            </div>
        </div>
    );
};

export default CustomData;
