import React from 'react';

const CustomButton = ({ name }) => {
  return (
    <a
      href="#"
      className="flex items-center justify-center h-[50px] rounded-custom bg-primary-color px-6 py-1 text-sm font-semibold text-white shadow-sm hover:bg-custom-border focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
    >
      {name}
    </a>
  );
}

export default CustomButton;
