import React, { useState, useRef, useEffect } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CategoryFilter from '../shopuser/categoryfilter';
import DataFilter from '../coursesuser/datafilteration';
import IndustryFilter from '../eventsuser/industryfilter';
import LocationFilter from '../coursesuser/locationfilter';

const CustomSortedForMobileEvents = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative justify-center items-center" ref={dropdownRef}>
      {/* Button to Toggle Dropdown */}
      <button
        onClick={toggleDropdown}
        className="p-2 bg-primary-color text-white rounded justify-center items-center"
      >
        Sort Options
      </button>
      
      {/* Dropdown Content */}
      {isDropdownOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 mt-2 rounded shadow-lg z-10 p-4">
          {/* CATEGORIES Section */}
          <div>
            <CustomSideTitle title="CATEGORIES" />
            <CustomCheckBox title="Work Brand Events" />
            <CustomCheckBox title="Global Events" />
            <CustomCheckBox title="Others" />
          </div>

          {/* INDUSTRY Section */}
          <div>
            <CustomSideTitle title="INDUSTRY" />
            <IndustryFilter />
          </div>

          {/* LOCATIONS Section */}
          <div>
            <CustomSideTitle title="LOCATIONS" />
            <LocationFilter />
          </div>

          {/* DATE Section */}
          <div>
            <CustomSideTitle title="DATE" />
            <DataFilter />
          </div>

          {/* LANGUAGE Section */}
          <div>
            <CustomSideTitle title="Language" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBox title="Arabic" />
              <CustomCheckBox title="English" />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileEvents;
