import React from 'react';
const CustomText = ({title}) =>{
    return (
        <p class="text-left
        xxxs:text-xs xxs:text-xs xs:text-xs
        sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm 
        11xl:text-sm 2xl:text-sm 22xl:text-sm 3xl:text-sm mb-2 text-gray-700 ">{title}</p>

    )
}

export default CustomText;