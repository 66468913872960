import React from 'react';
import image from '../../asset/images/about-university.png';
import { Button } from '@headlessui/react';
import StarReview from '../admin/reviews';
import CustomButton from '../admin/custombutton';

const StackContainer = () => {
  return (
    <div className='relative'>
      {/* Gradient background section */}
      <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        Gradient Background
      </div>

      {/* Content section */}
      <div className='relative bg-gray-300 h-80 mt-[-10rem] mx-20 flex items-end justify-end'>
        <figure className='relative h-96 w-full'>
          <img
            className='h-full w-full rounded-xl object-cover object-center'
            src={image}
            alt='nature image'
          />
          
          <div className='absolute bottom-0 left-0 right-0 p-4 flex justify-between items-center'>
            <div className='flex items-center'>
              <img
                alt=''
                src={image}
                className='ml-10 mb-10 h-20 w-20 flex-none rounded-full'
              />
              <div className='ml-5 mb-10 min-w-0 flex-auto'>
                <p className='font-bold leading-6 text-big-text-color'>shams</p>
                <div className='flex items-center'>
                  <StarReview />
                </div>
                <p className='mt-1 truncate text-xs leading-5 text-gray-500'>
                  4 courses 45 students
                </p>
              </div>
            </div>
            <CustomButton name='Create A New Course' className='ml-10' />
          </div>
        </figure>
      </div>
    </div>
  );
}

export default StackContainer;


