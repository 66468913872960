import React,{useEffect,useRef,useState} from 'react'
import imageCourse from '../../asset/images/coursebar.png';
import imageResources from '../../asset/images/resoursesimage.png';

import imageStore from '../../asset/images/store.png';
import imageAboutAndContact from '../../asset/images/aboutcontact.png';
import arrowIcon from '../../asset/images/Iconarrow.png';

const menuData = {
    Products: {
        image: imageCourse,
        footerText: 'Explore our Courses',
        footerLinks: [
            { name: 'Approach To Learn', link: '#' },
            { name: 'Training Locations', link: '#' },
            { name: 'Registration Method', link: '#' },
            { name: 'Talk to Specialist', link: '#' },
            { name: 'FAQs', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Find Course',
                subItems: [
                    { name: '-Course Home', link: '#' },
                    { name: '-Course Categories', link: '#' },
                    { name: '-Explore By Role', link: '#' },
                    { name: '-Explore By Levels', link: '#' },
                    { name: '-Search All Courses', link: '#' },
                ]
            },
            {
                id: 2,
                headline1: 'Delivery Methods',
                subItems: [
                    { name: '-Offline', link: '#' },
                    { name: '-Online', link: '#' },
                    { name: '-Face To Face', link: '#' },
                    { name: '-On Demand', link: '#' },
                    { name: '-Corporate Training', link: '#' }
                ]
            },
            {
                id: 3,
                headline1: 'Get Certified',
                subItems: [
                    { name: '-Certification Home', link: '#' },
                    { name: '-Why get Certification?', link: '#' },
                    { name: '-Certification Portal', link: '#' },
                    { name: '-Recertification', link: '#' },
                ]
            },
        ]
    },
    Clients: {
        image: imageResources,
        footerText: 'Explore our Resources',
        footerLinks: [
            { name: 'Order information', link: '#' },
            { name: 'Rights & Permissions', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Books',
                subItems: [
                    { name: '-Books Home', link: '#' },
                    { name: '-Browse All Books', link: '#' },
                ]
            },
            {
                id: 2,
                headline1: 'Research',
                subItems: [
                    { name: '-Research Home', link: '#' },
                    { name: '-Browse All Reports', link: '#' },
                ]
            },
            {
                id: 3,
                headline1: 'Booklet',
                subItems: [
                    { name: '-Booklet Home', link: '#' },
                    { name: '-Browse All Files', link: '#' },
                ]
            },
        ]
    },
   " Support Team": {
        image: imageStore,
        footerText: 'Shop at our Store',
        footerLinks: [
            { name: 'Join Today', link: '#' },
            { name: 'Contact Us', link: '#' },
            { name: 'Downloads', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'Shop',
                subItems: [
                    { name: '-Store Home', link: '#' },
                    { name: '-Suppliers', link: '#' },
                    { name: '-See all Merchandise', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Store Resources',
                subItems: [
                    { name: '-Merchandise', link: '#' },
                    { name: '-Articles', link: '#' },
                    { name: '-Offers', link: '#' },
                    { name: '-Courses', link: '#' },
                    { name: '-Certifications', link: '#' },
                    { name: '-Membership', link: '#' },
                    { name: '-Webinar', link: '#' }
                ]
            },
        ]
    },
  
 
    'About/Contact': {
        image: imageAboutAndContact,
        footerText: 'About and Contact Us',
        footerLinks: [
            { name: 'Refer Your Friend', link: '#' },
            { name: 'Support (Live)', link: '#' },
            { name: ' FAQs', link: '#' },
            { name: 'Training Locations', link: '#' },
        ],
        sections: [
            {
                id: 1,
                headline1: 'About',
                subItems: [
                    { name: '-About Home', link: '#' },
                    { name: '-Board Of Directors', link: '#' },
                    { name: '-Awards', link: '#' },
                    { name: '-Press Room', link: '#' },
                    { name: '-Clients', link: '#' },
                    { name: '-Our Services', link: '#' }
                ]
            },
            {
                id: 2,
                headline1: 'Contact',
                subItems: [
                    { name: '-Reviews', link: '#' },
                    { name: '-Support (Live)', link: '#' },
                    { name: '-Submit a Request', link: '#' },
                    { name: '-Refer a Friend', link: '#' },
                    { name: '-Career Hub', link: '#' },
                    { name: '-Contact Us', link: '#' }
                ]
            },
        ]
    }
};

const FlyoutMenuCompany = () => {
    const [openMenu, setOpenMenu] = useState(null);
    const menuRef = useRef(null);

    const toggleMenu = (menuName) => {
        setOpenMenu(openMenu === menuName ? null : menuName);
    };

    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setOpenMenu(null);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <div className="relative items-center justify-center" ref={menuRef}>
            <div className="flex justify-center gap-1">
                {Object.keys(menuData).map((menuName) => (
                    <button
                        key={menuName}
                        onClick={() => toggleMenu(menuName)}
                        className="flex items-center gap-1 px-1 py-1 mr-5 text-big-text-color font-semibold rounded hover:bg-gray-100 focus:outline-none"
                    >
                        {menuName}
                        <img
                            src={arrowIcon}
                            alt={arrowIcon}
                            className='w-4 h-2 items-center justify-center'
                        />
                    </button>
                ))}
            </div>

            {openMenu && (
                <div className="absolute top-12 left-0 w-full bg-white text-black shadow-lg z-50 flex flex-col rounded-custom">
                    <div className="flex-grow flex">
                        <div className="flex-grow p-1">
                            <div className="flex flex-col lg:flex-row">
                                {menuData[openMenu].sections.map((section) => (
                                    <div key={section.id} className="flex-1 mb-4 lg:mb-0 ml-5">
                                        <h1 className="text-sm font-semibold mb-2 text-big-text-color">{section.headline1}</h1>
                                        <hr />
                                        <ul>
                                            {section.subItems.map((item, index) => (
                                                <li key={index} className="mb-2">
                                                    <a href={item.link} className="text-sm block hover:underline text-gray-500">{item.name}</a>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className="flex-shrink-0 p-4">
                            <img
                                src={menuData[openMenu].image}
                                alt={openMenu}
                                className="w-60 h-60 object-cover"
                            />
                        </div>
                    </div>
                    <div className="bg-black text-white text-center p-6 rounded-custom">
                        <div className='flex text-center justify-center '>
                            <h2 className='text-xs text-custom-border mr-5'>
                                Quick Links:
                            </h2>
                            <div className="flex justify-center space-x-4">
                                {menuData[openMenu].footerLinks.map((link, index) => (
                                    <a key={index} href={link.link} className="hover:underline text-xs">
                                        {link.name}
                                    </a>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FlyoutMenuCompany;