import React from "react";
import CustomButton from "../admin/custombutton";
import image from '../../asset/images/about-university.png';

export default function SettingCompanyFormUser() {
  return (
    <div className="ml-10 block rounded-lg bg-white p-6 shadow-lg mb-20 p-6 mt-5">
           <div className='text-2xl font-bold text-big-text-color mb-2'>
            Settings
          </div>
          <hr className="px-8 py-4 ">
          </hr>
          <div className='relative bg-gray-300 h-60 mb-5 mx-20 flex items-end justify-end'>
          <img
            className='h-full w-full rounded-xl object-cover object-center'
            src={image}
            alt='nature image'
          />
          
          <div className='absolute bottom-0 left-0 mt-5 right-0 p-4 flex justify-between items-center mb-5'>
            <div className='flex items-center'>
              <img
                alt=''
                src={image}
                className='ml-10 mb-10 h-20 w-20 flex-none rounded-full'
              />
              <div className='ml-5 mb-10 min-w-0 flex-auto mt-5'>
                <p className='font-bold leading-6 text-big-text-color'>Target Company</p>
              </div>
            </div>
            <CustomButton name='add new company image' className='ml-10' />
          </div>
  
      </div>
      <form>
        {/* Label and Input for First Name and Last Name */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="first-name"
              type="text" 
              placeholder="Target"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="last-name"
              type="text"
              placeholder="Company"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
        </div>

        {/* Label and Input for Email and Phone */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="email"
              type="email"
              placeholder="margotfoster@example.com"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="phone"
              type="tel"
              placeholder="+06584589859"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
        </div>

        
        <div className="w-full  pr-2 mb-10"> 
  <input
    id="nationality"
    type="text"
    placeholder="
     Fugiat ipsum ipsum deserunt culpa aute sint do nostrud anim incididunt cillum culpa consequat. ..."
    className="w-full px-4 text-lg border border-gray-300 rounded-lg h-40 " 
  />
</div>


<div className="flex justify-center mb-10">
<button
  type="submit"
  className="block w-1/2 rounded-lg bg-primary-color px-8 py-3 text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
>
Update Now
</button>
</div>
      </form>
    </div>
  );
}
