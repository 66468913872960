import React ,{useState,useEffect} from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import CustomDiv from '../../components/users/coursesuser/customdiv';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import SortedSideBarMemberShip from '../../components/users/membershipuser/sortedsidebarmembership';
import AllMemberShipGrid from '../../components/users/membershipuser/membersgipgrid';
import AllMemberShipList from '../../components/users/membershipuser/membershiplist';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomSortedForMobileMembership from '../../components/users/membershipuser/customSortMembershipMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import CustomLoadMoreButton from '../../components/users/sharedcomponent/customLoadMoreButton';
const AllMemberShip =()=>{ 
  const [view, setView] = useState('grid');

  const handleGridPress = () => {
    setView('grid');
  };

  const handleListPress = () => {
    setView('list');
  };

  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [scrollingDown, setScrollingDown] = useState(true);

  useEffect(() => {
    let lastScrollTop = 0;

    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      setScrollingDown(currentScrollTop > lastScrollTop);
      setIsNavBarVisible(currentScrollTop > 100); // Adjust threshold as needed

      lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
    };

    window.addEventListener('scroll', handleScroll);

    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      {/* This div will handle the padding-top adjustment for the fixed navbar */}
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
     <div className='flex justify-center items-center hidden-mobile'>
     
     <CustomStaticFlyoutMenu />
     </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
       <div>
       <CustomDiv
       navTitle='MemberShip'
       onGridPress={handleGridPress} onListPress={handleListPress}
       title='MemberShip'
       subTitle= "Joining Work Brand opens the door to a world of unparalleled professional development opportunities. Whether you are an established talent development expert or just beginning your journey in this dynamic field."
       buttonTitle= "20 Books"
       activeView={view} // Pass the current view to CustomDiv

       />
       <div className='mobile-only'>
       <CustomSortedForMobileMembership/>
       </div>
          <div className='relative 
        sm:top-[-220px] lg:top-[-140px] xl:top-[-140px]
         1xl:top-[-170px] 11xl:top-[-160px]
         2xl:top-[-140px] 22xl:top-[-150px] 3xl:top-[-150px]
           lg:mx-24 xl:mx-24 1xl:mx-24 11xl:mx-24 2xl:mx-24 22xl:mx-24 3xl:mx-24 px-1 mt-10  flex'>
        <div className='sticky top-[80px] hidden-mobile'>
        <SortedSideBarMemberShip />
        </div>
        <div className='xxxs:mx-4 xxs:mx-4 xs:mx-4 xxxxs:mx-4 xxxxxs:mx-4
                     sm:mx-0 mid:mx-0 md:mx-0 lg:mx-0 xl:mx-0 1xl:mx-0 11xl:mx-0 2xl:mx-0 22xl:mx-0 3xl:mx-0 ' >
        {view === 'grid' ? <AllMemberShipGrid /> : <AllMemberShipList />}
        <div className='flex justify-center items-center mt-20 xxxs:mb-10 xxs:mb-10 xs:mb-10 xxxxs:mb-10 xxxxxs:mb-10'>
  <CustomLoadMoreButton children='Load More'/>
</div>
      </div>
      </div>
       </div>
       <FooterUser/>
     </div>
    )
}
export default AllMemberShip;