import React from 'react';

const YouTubeEmbed = () => {
  return (
    <div className=' aspect-video  p-4 ' >
    <iframe 
        className=' h-full w-full rounded-lg'
        src="https://www.youtube.com/embed/4WiH9pf2ULQ?si=2TzjHgKzRDOgi528" 
        width="100%" 
        title="YouTube video player" 
        frameborder="0" 
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen> 
     </iframe>
</div> 
  );
};

export default YouTubeEmbed;