import React, { useState, useRef, useEffect } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomCheckBoxStar from '../coursesuser/checkboxreview';
import CategoryFilter from '../shopuser/categoryfilter';
import DataFilter from '../coursesuser/datafilteration';
import IndustryFilter from '../eventsuser/industryfilter';

const CustomSortedForMobileResourses = () => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsDropdownOpen(prev => !prev);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsDropdownOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="relative justify-center items-center" ref={dropdownRef}>
      {/* Button to Toggle Dropdown */}
      <button
        onClick={toggleDropdown}
        className="p-2 bg-primary-color text-white rounded justify-center items-center"
      >
        Sort Options
      </button>
      
      {/* Dropdown Content */}
      {isDropdownOpen && (
        <div className="absolute top-full left-0 w-full bg-white border border-gray-300 mt-2 rounded shadow-lg z-10 p-4">
          {/* ACCESS Section */}
          <div>
            <CustomSideTitle title="ACCESS" />
            <CustomCheckBox title="Member Benefits" />
          </div>

          {/* CATEGORIES Section */}
          <div>
            <CustomSideTitle title="CATEGORIES" />
            <CategoryFilter />
          </div>

          {/* INDUSTRY Section */}
          <div>
            <CustomSideTitle title="INDUSTRY" />
            <IndustryFilter />
          </div>

          {/* Format Section */}
          <div>
            <CustomSideTitle title="Format" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBox title="Paper Back" />
              <CustomCheckBox title="Pdf" />
            </div>
          </div>

          {/* DATE Section */}
          <div>
            <CustomSideTitle title="DATE" />
            <DataFilter />
          </div>

          {/* Language Section */}
          <div>
            <CustomSideTitle title="Language" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBox title="Arabic" />
              <CustomCheckBox title="English" />
            </div>
          </div>

          {/* RATING Section */}
          <div>
            <CustomSideTitle title="RATING" />
            <div className="flex flex-col space-y-2">
              <CustomCheckBoxStar 
                starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border']} 
                title="5 STAR" 
              />
              <CustomCheckBoxStar 
                starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300']} 
                title="4 STAR" 
              />
              <CustomCheckBoxStar 
                starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300']} 
                title="3 STAR" 
              />
              <CustomCheckBoxStar 
                starColors={['text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
                title="2 STAR" 
              />
              <CustomCheckBoxStar 
                starColors={['text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
                title="1 STAR" 
              />
              <CustomCheckBoxStar 
                starColors={['text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} 
                title="0 STAR" 
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileResourses;
