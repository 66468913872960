import React, { useRef, useState, useEffect } from 'react';
import { Menu } from '@headlessui/react';

const CustomDropdown = ({ menuList, menuItems, textColor, arrowimage }) => {
  // Ref to store the button and menu item widths
  const buttonRef = useRef(null);
  const [buttonWidth, setButtonWidth] = useState(0);

  // Update the button width when it changes
  useEffect(() => {
    if (buttonRef.current) {
      setButtonWidth(buttonRef.current.offsetWidth); // Get the button's width
    }
  }, []);

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        {/* Menu Button with ref */}
        <Menu.Button
          ref={buttonRef} // Set ref to the button
          className={`flex items-center rounded-md bg-transparent text-sm font-medium text-${textColor}`}
        >
          {menuList.name} {/* Display the menu list name */}
          <img
            src={arrowimage} // Arrow image
            alt="arrow icon"
            className="w-3 h-2 mr-2 ml-1"
          />
        </Menu.Button>
      </div>

      {/* Menu Items */}
      <Menu.Items
        className="absolute right-0 z-40 mt-1 origin-top-right rounded-custom top-7  bg-white shadow-lg focus:outline-none"
        style={{ width: buttonWidth }} // Dynamically set the width of the menu based on button width
      >
        {menuItems.map((item) => (
          <Menu.Item key={item.id} className="block px-4 py-2 text-sm rounded-custom bg-white text-gray-700 hover:bg-white">
            {({ active }) => (
              <a
                href={item.link}
                className={`block px-4 py-2 text-sm ${active ? 'bg-white text-gray-900' : 'text-gray-700'} hover:bg-white`}
              >
                {item.name}
              </a>
            )}
          </Menu.Item>
        ))}
      </Menu.Items>
    </Menu>
  );
};

export default CustomDropdown;
