import React, { useEffect, useState, useRef } from 'react';
import halfLogo from '../../../asset/images/half.png';

const CustomWhyUsCard = ({ image, title, subTitle }) => {
    const [count, setCount] = useState(0);
    const targetNumber = parseInt(title, 10); // Convert title to a number
    const countRef = useRef(null);
    const isCountingRef = useRef(false); // Track if counting has started

    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    // Reset count each time the element is visible
                    resetCounting();
                    observer.unobserve(entry.target); // Optional: Stop observing after first visibility
                }
            });
        });

        if (countRef.current) {
            observer.observe(countRef.current);
        }

        return () => {
            if (countRef.current) {
                observer.unobserve(countRef.current); // Cleanup observer
            }
        };
    }, []);

    const resetCounting = () => {
        setCount(0); // Reset count
        startCounting(); // Start counting
    };

    const startCounting = () => {
        let start = 0; // Starting from 0
        const end = targetNumber; // The target number from title
        const duration = 2000; // Duration of the counting in milliseconds
        const incrementTime = Math.ceil(duration / end); // Time per increment

        const counterInterval = setInterval(() => {
            if (start < end) {
                setCount(prevCount => prevCount + 1); // Increment the count
                start++;
            } else {
                clearInterval(counterInterval); // Stop counting when reaching the target
            }
        }, incrementTime);

        return () => clearInterval(counterInterval); // Cleanup on unmount
    };

    return (
        <div className='flex flex-col items-center' ref={countRef}>
            <div className='relative bg-white lg:mr-10 lg:ml-10 xl:mr-10 xl:ml-10 2xl:mr-20
             2xl:ml-20 rounded-lg shadow-lg'>
                <img
                    src={halfLogo}
                    alt="Half Target Logo"
                    className="w-50 h-50 mx-auto 
                    xxxs:px-2 xxs:px-2 xs:px-2
                    sm:px-4 lg:px-6 xl:px-6 1xl:px-6 11xl:px-6 2xl:px-6 22xl:px-6 3xl:px-6"
                />
              <div className='absolute
              xxxs:top-[25px] xxs:top-[25px] xs:top-[25px] xxxxs:top-[40px] xxxxxs:top-[40px]
               sm:top-[20px] lg:top-[30px] xl:top-[30px] 1xl:top-[30px] 11xl:top-[30px]
              2xl:top-[30px] 22xl:top-[30px] 3xl:top-[30px]
              left-1/2 transform -translate-x-1/2
                 z-10 bg-white
                 xxxs:p-2 xxs:p-2 xs:p-2 xxxxs:p-2 xxxxxs:p-2
                 sm:p-4 lg:p-4 xl:p-4 1xl:p-4 11xl:p-4 2xl:p-4 22xl:p-4 3xl:p-4 border border-gray-300 rounded-lg shadow-md flex flex-col items-center'>
    <img
        src={image}
        alt={title}
        className="
        xxxs:w-6 xxxs:h-6 xxs:w-6 xxs:h-6 xs:w-6 xs:h-6 xxxxs:w-8 xxxxs:h-8 xxxxxs:w-8 xxxxxs:h-8
        sm:w-8 sm:h-8 lg:w-8 lg:h-8 xl:w-8 xl:h-8 1xl:w-8 1xl:h-8 11xl:w-8 11xl:h-8 2xl:w-8 2xl:h-8
        22xl:w-8 22xl:h-8 3xl:w-8 3xl:h-8 items-center justify-center
        mb-2"

    />
</div>

                <h2 className='mt-10 
                xxxs:text-xl xxs:text-xl xs:text-xl
                sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color text-center'>
                    {count}+
                </h2>
                <h2 className='
                xxxs:text-xs xxs:text-xs xs:text-xs
                sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg font-bold text-gray-500 text-center mb-5'>
                    {subTitle}
                </h2>
                <hr className="border-t-2 border-yellow-700 mt-2 mx-auto w-full" />
            </div>
        </div>
    );
}

export default CustomWhyUsCard;


