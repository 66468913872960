import React, { useState } from 'react';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomSideTitle from '../coursesuser/customsidetitle';
import IndustryFilter from './industryfilter';
import LocationFilter from '../coursesuser/locationfilter';
import DataFilter from '../coursesuser/datafilteration';
import CollapsAll from '../coursesuser/collapsall';

const SortSideForEvents = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Default collapsed

  // State to track the checked status of checkboxes
  const [checkedState, setCheckedState] = useState({
    "Work Brand Events": false,
    "Global Events": false,
    "Others": false,
    "Arabic": false,
    "English": false,
  });

  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  // Handle checkbox state change
  const handleCheckboxChange = (title) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the checked state for this checkbox
    }));
  };

  return (
    <div
      className={`lg:w-[300px] xl:w-[300px] 2xl:w-[300px] border border-custom-border p-4 rounded-lg bg-white
         sm:mr-10 lg:mr-10 xl:mr-10 1xl:mr-10 11xl:mr-10 2xl:mr-10 3xl:mr-10 
         transition-all duration-300 ${isCollapsed ? 'sticky top-[80px] z-30' : 'relative'}`}
    >
      <CollapsAll onClick={handleCollapseToggle} />
      <div className={`transition-all ${isCollapsed ? 'opacity-100 ' : 'opacity-100 max-h-[1500px]'}`}>
        {/* Default content visible when collapsed */}
        <div className={`transition-all ${isCollapsed ? 'block' : 'hidden'}`}>
          <CustomSideTitle title="CATEGORIES" />
          <CustomCheckBox
            title="Work Brand Events"
            isChecked={checkedState["Work Brand Events"]}
            onChange={() => handleCheckboxChange("Work Brand Events")}
          />
          <CustomCheckBox
            title="Global Events"
            isChecked={checkedState["Global Events"]}
            onChange={() => handleCheckboxChange("Global Events")}
          />
          <CustomCheckBox
            title="Others"
            isChecked={checkedState["Others"]}
            onChange={() => handleCheckboxChange("Others")}
          />
          <CustomSideTitle title="INDUSTRY" />
          <IndustryFilter />
        </div>

        {/* Full content visible when expanded */}
        <div className={`transition-all ${isCollapsed ? 'hidden' : 'block'}`}>
          <CustomSideTitle title="CATEGORIES" />
          <CustomCheckBox
            title="Work Brand Events"
            isChecked={checkedState["Work Brand Events"]}
            onChange={() => handleCheckboxChange("Work Brand Events")}
          />
          <CustomCheckBox
            title="Global Events"
            isChecked={checkedState["Global Events"]}
            onChange={() => handleCheckboxChange("Global Events")}
          />
          <CustomCheckBox
            title="Others"
            isChecked={checkedState["Others"]}
            onChange={() => handleCheckboxChange("Others")}
          />
          <CustomSideTitle title="INDUSTRY" />
          <IndustryFilter />
          <CustomSideTitle title="LOCATIONS" />
          <LocationFilter />
          <CustomSideTitle title="DATE" />
          <DataFilter />
          <CustomSideTitle title="Language" />
          <CustomCheckBox
            title="Arabic"
            isChecked={checkedState["Arabic"]}
            onChange={() => handleCheckboxChange("Arabic")}
          />
          <CustomCheckBox
            title="English"
            isChecked={checkedState["English"]}
            onChange={() => handleCheckboxChange("English")}
          />
        </div>
      </div>
    </div>
  );
};

export default SortSideForEvents;
