import React, { useState } from 'react';

// DropdownWithHint Component
const DropdownWithHint = () => {
  const [selectedOption, setSelectedOption] = useState('');
  const handleChange = (e) => setSelectedOption(e.target.value);

  return (
    <div className="mb-6">
      <select
        id="nationality"
        value={selectedOption}
        onChange={handleChange}
        className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg bg-transparent"
        style={{ color: selectedOption ? '#4a4a4a' : '#9ca3af' }} // Custom gray color for text
      >
        <option value="" disabled className="text-gray-500">
          -- Select Your Course --
        </option>
        <option value="american">American</option>
        <option value="canadian">Canadian</option>
        <option value="british">British</option>
      </select>
    </div>
  );
};

export default DropdownWithHint;
