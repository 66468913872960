import React from 'react';
import CustomDivAdmin from './customdivadmin';
const CustomStackAdmin =()=>{
    return (
   <div>
      {/* Gradient background section */}
      <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        
      </div>
      <div className=' relative flex top-[-140px] items-center justify-center'>
<CustomDivAdmin name='Add A New Course'/>
<CustomDivAdmin name='Add A New Instructor'/>
<CustomDivAdmin name='Add A New Company'/>
<CustomDivAdmin name='Add A New Book'/>


      </div>
   </div>
    )
}

export default CustomStackAdmin;