import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
import { ur } from '@faker-js/faker';

const CustomMarkTextLink = ({ title, url }) => {
    return (
        <div className="relative flex">
            <div className="relative z-10 flex items-center">
                <CheckIcon className='w-4 h-4 text-primary-color'/>
                <h2 className='text-lg text-big-text-color ml-2 mr-2'>{title}</h2>
            </div>
             <a href={url} target="_blank" rel="noopener noreferrer" className='text-lg underline text-big-text-color'>{url}</a>

        </div>
    );
}

export default CustomMarkTextLink;
