import React from 'react' ;
import SettingCompanyFormUser from './settingform';

const SettingCompany =()=>{
    return (
        <div>
<SettingCompanyFormUser/>
        </div>
    )
}

export default SettingCompany;