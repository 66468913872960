import React from 'react';
import { Link } from 'react-router-dom';
import ButtonUnderLine from './buttonunderline';
import Logo from '../../../asset/images/BIS_png_1.png';
import CustomLink from './customlink';
import BorderButton from './borderbutton';
import Divider from './divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedinIn, faWhatsapp ,faYoutube,faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';

export default function FooterUser() {
  const handleClick = () => {
    console.log('Button clicked!');
  };

  return (
    <div>
      <hr className="h-0.5 border-t-0 bg-big-text-color opacity-100 dark:opacity-50" />
      <footer className="bg-neutral-100 text-center text-neutral-600 bg-white 
      dark:text-neutral-200 lg:text-left px-4 md:px-20 py-4">
        <div className="mx-6 py-10 text-center md:text-left">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-4">
            <div>
            <Link to="/">
           <img src={Logo} alt="Logo" className="
              xxxs:w-[100px] xxxs:h-[40px]   xxs:w-[100px] xxs:h-[40px]   xs:w-[100px] xs:h-[40px]
              sm:w-[170px] sm:h-[60px] lg:w-[170px] lg:h-[60px] 
               xl:w-[170px] xl:h-[60px] 1xl:w-[170px] 1xl:h-[60px]
                11xl:w-[170px] 11xl:h-[60px] 2xl:w-[170px] 2xl:h-[60px] 3xl:w-[170px] 3xl:h-[60px] mb-4" />
           </Link>
              <p className='mb-6 xxxs:text-sm xxs:text-sm xs:text-sm sm:text-sm 
              lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 22xl:text-sm
               3xl:text-sm text-big-text-color'>
                At Business Index Sentiments, we seek to meet the aspirations of our customers, and to be where their eyes are most visible.
              </p>
             
<div className="flex justify-start">
    <BorderButton onClick={handleClick}>Connect Us</BorderButton>
</div>

            </div>
            <div className="mobile-only block xxxs:flex xxs:flex xs:flex xxxxs:flex xxxxxs:flex   sm:flex md:flex mid:flex lg:hidden xl:hidden 1xl:hidden 11xl:hidden 2xl:hidden 22xl:hidden 3xl:hidden justify-between ">
        
      <div className='mr-4'>
              <h6 className="mb-4 text-big-text-color font-bold uppercase xxxs:text-xs
               xxs:text-xs xs:text-xs sm:text-lg mid:text-lg md:text-lg">IMPORTANT LINKS</h6>
              <div className='mb-2'>
              <CustomLink to="/allcourses">Courses</CustomLink>

              </div>
              <div className='mb-2'>
              <CustomLink to="/allresourses">Resources</CustomLink>

</div>
              <div className='mb-2'>
              <CustomLink to="/allblogs">Blogs</CustomLink>
</div>
          
              <div className='mb-2'>
              <CustomLink to="/allevents">Events</CustomLink>
</div>
          
              <div className='mb-2'>
              <CustomLink to="/allshops">Store</CustomLink>
</div>
            
          
            </div>

            <div>
              <div className='mb-2 mt-12'>
               <CustomLink to="/allmembership">MemberShip</CustomLink>
                              </div>
                              <div className='mb-2'>
                  <CustomLink to="/TermsAndConditions">Terms and condition</CustomLink>
                  </div>
                  <div className='mb-2'>
                  <CustomLink to="/privacyuser">Privacy Policy</CustomLink>
                  </div>
                  <div className='mb-2'>
                  <CustomLink to="/RefundPolicy">Refund Policy</CustomLink>
                  </div>
                 <div className='mb-2'>
                 <CustomLink to="/contactus">About Us</CustomLink>

                 </div>
            </div>

      </div>
            <div className='hidden-mobile'>
              <h6 className="mb-4 text-big-text-color font-bold uppercase text-lg ">IMPORTANT LINKS</h6>
              <div className='mb-2'>
              <CustomLink to="/allcourses">Courses</CustomLink>

              </div>
              <div className='mb-2'>
              <CustomLink to="/allresourses">Resources</CustomLink>

</div>
              <div className='mb-2'>
              <CustomLink to="/allblogs">Blogs</CustomLink>
</div>
          
              <div className='mb-2'>
              <CustomLink to="/allevents">Events</CustomLink>
</div>  
<div className='mb-2'>
             <CustomLink to="/allshops">Store</CustomLink>

             </div>
          
            </div>

            <div className='hidden-mobile'>
               <div className='mb-2 mt-12'>
               <CustomLink to="/allmembership">MemberShip</CustomLink>
                              </div>
                              <div className='mb-2'>
                  <CustomLink to="/TermsAndConditions">Terms and condition</CustomLink>
                  </div>
                  <div className='mb-2'>
                  <CustomLink to="/privacyuser">Privacy Policy</CustomLink>
                  </div>
                  <div className='mb-2'>
                  <CustomLink to="/RefundPolicy">Refund Policy</CustomLink>
                  </div>
                 <div className='mb-2'>
                 <CustomLink to="/contactus">About Us</CustomLink>

                 </div>
            </div>

            <div>
              <h6 className="mb-6 text-big-text-color font-bold uppercase
               xxxs:text-xs xxs:text-xs xs:text-xs sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg">Get Contact</h6>
              <p className="text-sm text-gray-500 mb-6 ">
  <span className='font-bold'>Phone:</span> 
  <a href="tel:+20125478965" className="text-gray-500 hover:underline">+20125478965</a>
</p>
              <p className="text-sm text-gray-500 mb-6">
  <span className='font-bold'>E-mail:</span> 
  <a href="mailto:info@example.com" className="text-gray-500 hover:underline">info@example.com</a>
</p>
              <p className="text-sm text-gray-500 mb-6 whitespace-nowrap">
  <span className='font-bold'>Address:</span> 
  <a 
    href="https://www.google.com/maps/search/?api=1&query=72+Gameat+Al-dewal+Al-Arabia,+Mohandessien" 
    target="_blank" 
    rel="noopener noreferrer" 
    className="text-gray-500 hover:underline"
  >
    72 Gameat Al-dewal Al-Arabia, Mohandessien
  </a>
</p>
              <div className="flex items-center border-2 border-gray-400 rounded-lg w-full">
                <input className="bg-transparent border-0 px-2 py-2 text-black placeholder
                -black text-xs focus:outline-none focus:ring-0 flex-grow" 
                type="email" placeholder="Newsletter" />
                <BorderButton onClick={handleClick}>Submit Now</BorderButton>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <div className="bg-custom-blue py-2 text-center
       justify-center items-center dark:bg-custom-blue
       text-white
      ">
<footer className="text-center 

lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 22xl:flex 3xl:flex justify-between
 lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 22xl:px-24 3xl:px-24">
    <span>
        © 2024  
        <a href="https://example.com" className="text-white hover:underline   hover:text-primary-color mx-1">Business Index Sentiments</a> 
      Developed by 
        <a href="https://targetdigitalsolutions.com" className="text-white hover:underline 
         hover:text-primary-color mx-1">Target Digital Solutions</a>
    </span>
    <div className='mb-2 flex
    xxxs:justify-center xxs:justify-center xs:justify-center xxxxs:justify-center xxxxxs:justify-center sm:justify-center md:justify-center  md:justify-center 
    lg:justify-start xl:justify-start 1xl:justify-start 11xl:justify-start 2xl:justify-start 22xl:justify-start 3xl:justify-start  space-x-2 '>
      
     
      <div className="flex items-center">
      <a href="mailto:example@example.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faEnvelope} className='text-white mr-2' />
      </a>
      <a href="https://wa.me/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className='text-white mr-2' />
      </a>
      <Divider />
      <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faXTwitter}  className="text-white ml-2 " />

</a>
      <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faYoutube} className="text-white ml-2 mr-2" />
</a>

      <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} className='text-white mr-2 ' />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} className='text-white mr-2' />
      </a>
      <a href="https://tiktok.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTiktok} className='text-white mr-2' />
      </a>
      <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedinIn} className='text-white mr-2' />
      </a>
    
                </div>
        </div>
</footer>
        {/* <div className="flex space-x-5">
          <ButtonUnderLine name='Clients' />
          <Divider />
          <ButtonUnderLine name='Career' />
          <Divider />
          <ButtonUnderLine name='Locations' />
          <Divider />
          <ButtonUnderLine name='Consultations' />
        </div> */}
      </div>
    </div>
  );
}
