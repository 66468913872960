import React from 'react';

const MakeAReview = () => {
  return (
    <div className='xxxs:w-[350px] xxs:w-[350px] xs:w-[380px] 
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
    bg-white rounded-custom mt-5 p-4 relative'>
      <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
      Make a Review
      </h3>
      <div className='flex justify-center items-center space-x-4 mt-2'>
        <button 
          className="bg-primary-color text-white font-bold 
          xxxs:py-1 xxxs:px-2  xxxs:mr-2 xxs:py-1 xxs:px-2  xxs:mr-2 xs:py-1 xs:px-2  xs:mr-2
          sm:py-2 sm:px-12  sm:mr-5 lg::py-2 lg::px-12  lg::mr-5 xl:py-2 xl:px-12  xl:mr-5
          1xl:py-2 1xl:px-12  1xl:mr-5 11xl:py-2 11xl:px-12  11xl:mr-5 2xl:py-2 2xl:px-12  2xl:mr-5
          rounded
            text-base sm:text-base md:text-sm lg:text-sm xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm
            flex items-center justify-center
            transition duration-300 ease-in-out transform hover:scale-105 
            focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color z-0"

        >
          Sign Up   
        </button>
        <button
          className="text-sm border-2 border-big-text-color text-big-text-color
           font-normal bg-transparent hover:border-opacity-80 hover:text-opacity-80 
          xxxs:py-1 xxxs:px-4 xxxs:mr-2 xxs:py-1 xxs:px-4  xxs:mr-2 xs:py-1 xs:px-4 xs:mr-2
          sm:py-2 sm:px-14 lg::py-2 lg::px-14 xl:py-2 xl:px-14
          1xl:py-2 1xl:px-14   11xl:py-2 11xl:px-14   2xl:py-2 2xl:px-14  rounded-lg
            transition duration-300 ease-in-out transform hover:scale-105"
   
        >
          Login
        </button>
      </div>
    </div>
  );
}

export default MakeAReview;
