import React ,{useEffect,useState ,useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarReview from './reviews';
import image from '../../asset/images/top10-fiction-1600x800.jpg';
import CustomIconAndText from '../users/sharedcomponent/customiconandtext';
import { CreditCardIcon, UsersIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import CustomTitleAndImageOnly from '../users/sharedcomponent/imageandtitleonly';
import CustomButton from '../users/sharedcomponent/custombutton';
import imagecourse from '../../asset/images/images.png';
import CustomBigTitle from '../users/homeuser/custombigtitle';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

const products = [
  {
    id: 1,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 1,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'International Company',
    href: '#',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  // Add more products here if needed
];

export default function ListOfCompaniesToAddProductAdmin() {
    return (
        <div className="bg-white shadow-lg shadow-black/5 ">
          <div className=" px-4 py-2 sm:px-2 sm:py-2  lg:px-2">
            <h2 className="text-3xl font-bold tracking-tight text-big-text-color px-4 mb-4">Companies</h2>
       <hr className="px-8 py-4">
       </hr>
            <div className="mt-2 grid grid-cols-1 gap-x-6 gap-y-10 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-8">
              {products.map((product) => (
                <div key={product.id} className="group relative shadow-lg rounded-custom p-1">
                  <div className=" overflow-hidden rounded-md   group-hover:opacity-75 ">
                    <img
                      alt={image}
                      src={image}
                      className="h-30 w-full object-cover object-center p-4"
                    />
                  </div>
                  <div className="mt-4 flex justify-between pr-4 pl-4 ">
                    <div>
  <StarReview/>
                      <h3 className="text-lg text-gray-700">
                        <a href={product.href}>
                          <span aria-hidden="true" className="absolute inset-0 text-big-text-color font-bold" />
                          {product.name}
                        </a>
                      </h3>
                    <h3 className="text-big-text-color font-normal text-sm">industary</h3>
                    <div className="mt-4 mb-5 flex justify-end items-end">

<button className="w-[100px] mt-1  mr-1 text-sm bg-primary-color text-white border rounded-custom border-gray-400 px-2 py-1 flex items-center justify-center">
Add Product
</button>
    </div>
                    </div>
                    <p className="text-sm font-medium text-gray-900">{product.price}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )
    }