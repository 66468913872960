import React, { useState, useEffect, useRef } from 'react';
import ArrowWhiteIcon from '../../../asset/images/arrowwhiteicon.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobe } from '@fortawesome/free-solid-svg-icons';

const menuItemsLanguages = [
    { id: 0, code: 'Region', label: 'Region' },  // Default item to show as 'Region'
    { id: 1, code: 'FR', label: 'France' },
    { id: 2, code: 'EN', label: 'United States' },
    { id: 3, code: 'ES', label: 'Spain' },
    { id: 4, code: 'DE', label: 'Germany' },
    { id: 5, code: 'IT', label: 'Italy' },
];

const FlagDropdown = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState('Region'); // Default to 'Region'

    const dropdownRef = useRef(null);  // Reference to the dropdown menu
    const buttonRef = useRef(null);    // Reference to the button

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    const handleSelect = (code) => {
        setSelectedLanguage(code);
        setIsOpen(false);
        console.log(`Selected Language Code: ${code}`);
    };

    // Handle clicks outside the dropdown to close it
    useEffect(() => {
        const handleClickOutside = (event) => {
            // If the click is outside both the dropdown and button, close the dropdown
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) &&
                buttonRef.current && !buttonRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };

        // Add the event listener
        document.addEventListener('click', handleClickOutside);

        // Cleanup the event listener on component unmount
        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    return (
        <div style={{ position: 'relative' }}>
            <button 
                ref={buttonRef}  // Attach ref to button
                className='ml-2' 
                onClick={toggleDropdown} 
                style={{ display: 'flex', alignItems: 'center', background: 'transparent', border: 'none', color: 'white' }}
            >
                <FontAwesomeIcon icon={faGlobe} />
                <span style={{ marginLeft: '5px', color: 'white' }}>
                    {menuItemsLanguages.find(item => item.code === selectedLanguage)?.label}
                </span>
                <img 
                    src={ArrowWhiteIcon} 
                    alt="Arrow" 
                    style={{ marginLeft: '5px', height: '6px', width: '12px' }} 
                />
            </button>
            {isOpen && (
                <ul 
                    ref={dropdownRef}  // Attach ref to dropdown menu
                    className='z-50 rounded-custom'
                    style={{ 
                        listStyle: 'none', 
                        padding: 2, 
                        margin: 0, 
                        top: 30, 
                        right: 0, 
                        position: 'absolute', 
                        zIndex: 10, 
                        background: 'white', 
                        borderRadius: '5px', 
                        boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)', 
                        width: '150px',
                    }}
                >
                    {menuItemsLanguages.map(item => (
                        <li 
                            key={item.id} 
                            onClick={() => handleSelect(item.code)} 
                            style={{ 
                                padding: '5px', 
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                background: selectedLanguage === item.code ? '#f0f0f0' : 'transparent',
                                borderRadius: '5px',
                            }}
                        >
                            <span>{item.label}</span>
                        </li>
                    ))}
                </ul>
            )}
        </div>
    );
};

export default FlagDropdown;
