import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';
const MarkWithText =({title})=>{
  return(
   <div className='flex mb-2'>
   <CheckIcon className='
   xxxs:w-3 xxxs:h-3  xxs:w-3 xxs:h-3  xs:w-3 xs:h-3 
   sm:w-6 sm:h-6 lg:w-6 lg:h-6  xl:w-6 xl:h-6 1xl:w-6 1xl:h-6 11xl:w-6 11xl:h-6  2xl:w-6 2xl:h-6  3xl:w-6 3xl:h-6    text-primary-color mr-2 '/>
   <p className='
   xxxs:text-xs xxs:text-xs xs:text-xs
   sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg   text-big-text-color'>
     {title}
   </p>
   </div>
  )
}
export default MarkWithText;