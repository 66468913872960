import React from 'react';
import StarReview from "../../admin/reviews";
import { ShoppingCartIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import image from '../../../asset/images/events.png';
import img from '../../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from '../sharedcomponent/customParagraph';
import BookmarkButton from '../sharedcomponent/customBookMark';
const products = [
  {
    id: 1,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    priceBefore: '$50',
    color: 'Black',
  },
  
];

const AllShopsList = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/storeInside'); 
  };

  return (
    <div className=" mb-20 grid grid-cols-1 gap-5 px-2  lg:w-[1100px] xl:w-[1100px] 2xl:w-full ml-10">
      {products.map((product) => (
        <div key={product.id} className="group relative bg-white p-4 flex rounded-md shadow-md h-60">
          <div className="w-1/3 flex-shrink-0 rounded-md">
            <img
              alt={image}
              src={image}
              className="w-full h-full object-cover object-center rounded-md"
            />
          </div>
          <div className="pl-4 flex flex-col justify-between w-full">
            <div>
            <h3 
  className="text-lg font-semibold text-gray-900 cursor-pointer" 
  onClick={handleClick} // Replace 'handleClick' with your actual function
>
  {product.name}
</h3>

              <h4 className="text-sm font-normal leading-5 tracking-tight text-big-text-color">{product.name}</h4>
              <div className='flex justify-between items-center w-full mb-2'>
                <StarReview />
                <BookmarkButton/>
              </div>
              <CustomParagraph
              title='Research your target market and understand their needs and preferences. your target market and understand their needs.'
              />
            </div>
            <div className="flex items-center justify-between w-full mt-2">
              <h3 className="text-lg font-semibold leading-6 tracking-tight text-yellow-600">
                {product.price}
              </h3>
              <button
                onClick={handleClick}
                className="text-base text-custom-border border rounded-md border-custom-border bg-transparent p-1.5 flex items-center hover:bg-gray-100 transition-colors">
                Add To Cart
                <img
                  src={img}
                  alt="Course Icon"
                  className="ml-2"
                />
              </button>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllShopsList;

