import React from 'react';
import CustomIconAndText from '../../users/sharedcomponent/customiconandtext';
import image from '../../../asset/images/62b1de2e8e142538f54863b6_What is course design.jpg';
import { BookmarkIcon ,ClockIcon ,PaperClipIcon } from '@heroicons/react/24/outline';
import imageResourses from '../../../asset/images/resourseimage.png';
import StarReview  from '../../admin/reviews';
import CustomTitleAndImage from '../../users/sharedcomponent/customtitleandimage';
import img from '../../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from '../sharedcomponent/customParagraph';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomIconAndTextDate from "../sharedcomponent/customTitleAndIconDates";

const products = [
  {
    id: 1,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
];

const AllResoursesList = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/resourcesInside'); // Update with the path you want to navigate to
  };
  return (
    <div className=" mb-20 grid grid-cols-1 gap-5 px-2
    lg:w-[1100px] xl:w-[1100px] 2xl:w-full ml-10">
      {products.map((product) => (
        <div key={product.id} className="group relative bg-white pl-5 pt-5 pb-5 flex rounded-custom shadow-lg w-full">
          <div className="w-1/3 flex-shrink-0 rounded-custom">
            <img
              alt={imageResourses}
              src={imageResourses}
              className="h-50 w-full object-cover object-center rounded-md"
            />
          </div>
          <div className="w-2/3 pl-5 flex flex-col justify-between">
       
        
          <div className="pt-1.5 min-w-0 flex-1 sm:pt-0 p-4">
                  <div className='justify-between flex'>
                    <StarReview />
                    <BookmarkButton/>
                  </div>
  
                  <h3 
  className="text-lg font-semibold text-gray-900 cursor-pointer" 
  onClick={handleClick} 
>
  {product.name}
</h3>

                  <div className="flex space-x-4 mt-3 mb-3">
                    <CustomIconAndTextDate icon={<ClockIcon className="h-4 w-4 text-gray-500" />} title='16 Nov, 2024' />
                    <CustomIconAndText icon={<PaperClipIcon className="h-4 w-4 text-gray-500" />} title='15 Downloads' />
                  </div>
                
                  <CustomParagraph
                  title=' Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.
                  '
                  />
                  <div className='flex justify-between'>
                    <CustomTitleAndImage
                      imageSrc={image}
                      title="Card Title"
                    />
             <button
  onClick={handleClick}
  className="mt-1 text-xs text-gray-500 border rounded border-gray-400 bg-transparent px-1 py-1 flex items-center justify-center"
>
  Read More
  <img
    src={img}
    alt="Course Icon"
    className="ml-2"
  />
</button>

                  </div>
                  </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllResoursesList;
