import React, { useEffect, useState, useRef } from 'react';
import StarReview from '../../admin/reviews';
import { ShoppingCartIcon } from '@heroicons/react/24/outline';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';

import storeOne from '../../../asset/images/v6yJvmklfriHNMqvBYlwsVzmLaxw3nGjCcWvziCm 1.png';
import storeTwo from '../../../asset/images/Cost_reduction__2._SL1280_FMjpg_.jpg';
import storeThree from '../../../asset/images/imagesstore.jpg';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';

const products = [
  {
    id: 1,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeThree,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 4,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'INSTRUCTIONAL DESIGN',
    subtile: 'jumia',
    href: '#',
    imageSrc: storeThree,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  // Add more products here if needed
];

const CustomItemInStore = () => {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const itemsPerScroll = 1; // Number of items per scroll action
  const numberOfDots = 2; // Always show two dots

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth * itemsPerScroll : itemWidth * itemsPerScroll;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
      
      // Update arrow visibility
      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < (scrollRef.current.scrollWidth - scrollRef.current.clientWidth));
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='relative flex flex-col items-center
      xxxs:mx-12 xxs:mx-12 xs:mx-12
      xxxs:px-1 xxs:px-1 xs:px-1
      lg:px-12 xl:px-12 1xl:px-12 11xl:px-12 2xl:px-12 22xl:px-12 3xl:px-12
      mx-2'>
      <div className="relative flex items-center w-full">
        {/* Left Arrow */}
        {showLeftArrow && (
          <button
            onClick={() => scrollOneItem('left')}
            className="absolute left-[-30px] z-10 p-2 text-white bg-custom-blue rounded-full
             shadow-md hover:bg-primary-color focus:outline-none"
          >
            <ArrowLeftIcon className='w-6 h-6' />
          </button>
        )}

        {/* Scrollable Container */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto space-x-6 p-2 w-full scrollbar-hidden"
          style={{ scrollSnapType: 'x mandatory', paddingLeft: '30px', paddingRight: '30px' }}
        >
          {/* List Items */}
          {products.map((product) => (
            <div
              key={product.id}
              className="relative flex-none w-full sm:w-1/2 lg:w-1/3 xl:w-1/3 
                rounded-lg shadow-lg bg-white
                xxxs:px-2 xxs:px-2 xs:px-2
                sm:px-10 lg:px-10 xl:px-10 1xl:px-10 11xl:px-10 2xl:px-10 22xl:px-10 3xl:px-10 py-4"
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className='w-full flex flex-col'>
                <h3 className="sm:text-lg lg:text-lg xl:text-lg 2xl:text-2xl font-bold leading-8 tracking-tight text-big-text-color text-center mb-2">{product.name}</h3>
                <div className='flex items-center justify-center mb-2'>
                  <h4 className="sm:text-xs lg:text-sm 2xl:text-lg font-semibold leading-6 tracking-tight text-gray-500 mr-2">By</h4>
                  <h4 className="sm:text-xs lg:text-sm 2xl:text-lg font-semibold leading-6 tracking-tight text-gray-500 underline items-center">{product.subtile}</h4>
                </div>
                <img
                  src={product.imageSrc}
                  alt={product.imageAlt}
                  className="w-full h-32 rounded-md object-center object-cover mb-2"
                />
                <div className='justify-between flex'>
                  <StarReview />
                  <BookmarkButton />
                </div>
                <CustomParagraph
                  title='Research your target market and understand their needs and preferences.'
                />
                <div className="flex justify-between items-center">
                  <h3 className="sm:text-lg lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl
                     2xl:text-xl 22xl:text-xl 3xl:text-xl font-semibold leading-8 tracking-tight text-yellow-600 flex-grow mr-6">
                    {product.price}
                  </h3>
                  <button className="text-base text-primary-color border rounded-custom border-primary-color bg-transparent p-2 flex items-center hover:bg-gray-100 transition-colors">
                    <ShoppingCartIcon className="h-6 w-6 mr-2" />
                    Add To Cart
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        {showRightArrow && (
          <button
            onClick={() => scrollOneItem('right')}
            className="absolute right-[-30px] z-10 p-2 text-white bg-custom-blue
             rounded-full shadow-md hover:bg-primary-color focus:outline-none"
          >
            <ArrowRightIcon className='w-6 h-6' />
          </button>
        )}
      </div>

      {/* Dots Container */}
      <div className="flex justify-center mt-4">
        {Array.from({ length: numberOfDots }).map((_, index) => (
          <div
            key={index}
            onClick={() => scrollToIndex(index)}
            className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-big-text-color' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
}

export default CustomItemInStore;