import React from "react";
import {UsersIcon ,TableCellsIcon ,BookmarkIcon} from "@heroicons/react/24/outline";
import StarReview from "../admin/reviews";
import image from '../../asset/images/events.png';
import img from '../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';

const products = [
  {
    id: 1,
    name: 'phone',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    subname: '12 item',
  },
  {
      id: 1,
      name: 'Tv',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
    {
      id: 1,
      name: 'playstation',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
    {
      id: 1,
      name: 'playstation',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
    {
      id: 1,
      name: 'playstation',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
    {
      id: 1,
      name: 'tv',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 item',
    },
]

  export default function ListOfWishListProducts() {
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/eventInside'); 
    };
    return (
      <div className="bg-white shadow-lg shadow-black/5  ">
        <div className=" px-4 py-4 sm:px-4 sm:py-8  lg:px-4">
          <h2 className="text-3xl font-bold tracking-tight text-big-text-color px-4 mb-4">Courses</h2>
     <hr className="px-8 py-4">
     </hr>
          <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-4">
            {products.map((product) => (
              <div key={product.id} className="group relative shadow-lg rounded-custom">
                <div className=" overflow-hidden rounded-md   group-hover:opacity-75 ">
                  <img
                    alt={image}
                    src={image}
                    className="object-cover object-center p-4"
                  />
                </div>
                <div className="mt-4 flex justify-between pr-4 pl-4 ">
                  <div>
<StarReview/>
                    <h3 className="text-lg text-gray-700">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0 text-big-text-color font-bold" />
                        {product.name}
                      </a>
                    </h3>
                    <div className="flex">
                    <TableCellsIcon className="w-4 h-4 text-gray-500 mr-2"/>
                  <p className="text-sm text-gray-500">{product.subname}</p>
                    </div>
                  </div>
                
                  </div>
                  <div className="flex justify-between px-4 mb-2">
                    <p className="text-lg font-medium text-gray-900">{product.price}</p>
                    <button 
onClick={handleClick}
className="mt-1 text-sm text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center justify-center">
See Details
<img
    src={img}
    alt="Course Icon"
    className="ml-2 justify-center items-center"
  />
</button>
                    </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }