import React, { useState } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus'; // Check this import
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomDivAuth from '../../components/auth/customdivauth';
import LoginForm from '../../components/auth/loginForm';
const Login = () => {
    return (
        <div>
            {/* <div className="head-user-container">
                <HeadUser />
            </div> */}
            {/* <HeaderHomeUserMobile />
            <CustomDivider />
            <div className="head-user-container">
                <div className='flex justify-center items-center hidden-mobile'>
                    <h2 className='text-custom-border text-lg mr-5'>Home</h2>
                    <FlyoutMenu />
                </div>
            </div> */}
            {/* <CustomDivider /> */}
            {/* <div className={`fixed mobile-only top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
                <HeaderHomeUserMobile />
            </div>
            <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
                <CustomNavBarWithFlayout />
            </div> */}
            <div className='bg-gray-50 z-0'>
                <CustomDivAuth />
                <div className='relative sm:top-[-220px] lg:top-[-140px] mx-24 px-1 mt-10 mb-10 flex justify-center'>
                <LoginForm/>
                </div>
                {/* <FooterUser /> */}
            </div>
        </div>
    );
};

export default Login;
