import React from 'react';
import ButtonUnderLine from './buttonunderline';
import Divider from './divider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faTiktok, faLinkedinIn, faWhatsapp ,faYoutube,faXTwitter } from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons';
import LanguageDropdown from './customDropDownLanguages';



const TextRotator = () => {
    const texts = [
        "Enjoy new courses in September",
        "Enjoy new courses in August",
        "Enjoy new courses in January",
        "Enjoy new courses in February",
    ];

    const [currentText, setCurrentText] = React.useState(texts[0]);

    React.useEffect(() => {
        const intervalId = setInterval(() => {
            setCurrentText(prevText => {
                const currentIndex = texts.indexOf(prevText);
                const nextIndex = (currentIndex + 1) % texts.length;
                return texts[nextIndex];
            });
        }, 900); // Adjust the timing as needed

        return () => clearInterval(intervalId);
    }, [texts]);
    const handleTextClick = () => {
        // Add your click handling logic here
        alert("Text clicked!"); // Example action
    };

    return ( 
        <p className="text-sm leading-6 text-white">
            <a href="#" className="flex-none rounded-custom bg-primary-color px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700">
                Latest News
            </a>
            <svg viewBox="0 0 2 2" aria-hidden="true" className="mx-2 inline h-0.5 w-0.5 fill-current">
                <circle r={1} cx={1} cy={1} />
            </svg>
             <a href="#" onClick={handleTextClick} className="text-white hover:underline cursor-pointer">
                {currentText}
            </a>
        </p>
    );
};

export default function HeadUser() {
    return (
        <div className="px-20 pt-2 pb-2 relative isolate flex justify-between items-center
         bg-custom-blue z-10 hidden-mobile">
            <div className="flex items-center mr-5 ml-5">
                <ButtonUnderLine name='Consultations' />
                <Divider />
                <ButtonUnderLine name='Clients' />
                <Divider />
                <ButtonUnderLine name='Careers' />
                <Divider />
                <ButtonUnderLine name='Locations' />
            </div>

            <div className="flex items-center gap-x-4 gap-y-2 mx-5">
                <TextRotator />
            </div>

            <div className="flex items-center gap-x-4 gap-y-2 ">
            
               <div className="flex items-center">
      <a href="mailto:example@example.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faEnvelope} className='text-white mr-2' />
      </a>
      <a href="https://wa.me/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faWhatsapp} className='text-white mr-2' />
      </a>
      <Divider />
      <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
      <FontAwesomeIcon icon={faXTwitter}  className="text-white ml-2 " />

</a>
      <a href="https://www.youtube.com/channel/YourChannelID" target="_blank" rel="noopener noreferrer">
    <FontAwesomeIcon icon={faYoutube} className="text-white ml-2 " />
</a>

      <a href="https://facebook.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faFacebookF} className='text-white mr-2 ml-2' />
      </a>
      <a href="https://instagram.com" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faInstagram} className='text-white mr-2' />
      </a>
      <a href="https://tiktok.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faTiktok} className='text-white mr-2' />
      </a>
      <a href="https://linkedin.com/" target="_blank" rel="noopener noreferrer">
        <FontAwesomeIcon icon={faLinkedinIn} className='text-white mr-2' />
      </a>
    
                    <Divider />
                    <div className='flex items-center z-10'>
                <LanguageDropdown/>

                </div>
                </div>
             
            </div>
        </div>
    );
}
