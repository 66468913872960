import React from "react";
import image from '../../../asset/images/Rectangle 42337.png';
import CustomTextTerms from './customTextInsideTerms';

const StackTerms = () => {
  return (
    <div className="flex flex-col min-h-screen">
      {/* Gradient Background */}
      <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        Gradient Background
      </div>
      <div
        className="relative top-[-230px] xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-28 lg:mx-28 xl:mx-28 1xl:mx-28 11xl:mx-28 2xl:mx-28 3xl:mx-28  mt-10 mb-10 bg-white flex flex-col  shadow-lg items-center justify-center rounded-lg shadow-lg"
      >
        <h3 className="text-2xl font-bold mb-2 mt-10 text-big-text-color">
        Terms and Conditions 
        </h3>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
           Acceptance of Terms
          </h3>
          <CustomTextTerms title='By using the Website, you agree to comply with these Terms and all applicable laws, rules, and regulations. If you do not agree with these Terms, you must not use the Website.'/>
        </div>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className=" xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
            Changes to Terms
          </h3>
          <CustomTextTerms title='We reserve the right to modify or update these Terms at any time. Changes will be posted on this page with an updated "Last Updated" date. It is your responsibility to review these Terms periodically. Your continued use of the Website after any changes to these Terms signifies your acceptance of those changes.'/>
        </div>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
          Use of Website
          </h3>
         <h3 className="xxxs:text-sm xxs:text-sm xs:text-sm
          sm:text-lg lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg
            text-start mb-3 text-gray-800">
         By using the Website, you agree to comply with these Terms and all applicable laws, rules, and regulations. 
         If you do not agree with these Terms, you must not use the Website.
         </h3>
         <CustomTextTerms title='1.Use the Website in any way that violates any applicable local, state, national, or international law or regulation.'/>
         <CustomTextTerms title='2.Interfere with or disrupt the Website is operation or servers or networks connected to the Website.'/>
         <CustomTextTerms title='3.Attempt to gain unauthorized access to any part of the Website, other accounts, or systems connected to the Website.'/>

        </div>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className=" xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
        Account Registration
          </h3>
          <CustomTextTerms title='In some areas of the Website, you may be required to create an account. You are responsible for maintaining the confidentiality of your account login information and for all activities that occur under your account. You agree to notify us immediately if you suspect any unauthorized use of your account.'/>
        </div>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className=" xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
       Privacy Policy
          </h3>
          <CustomTextTerms title='Your use of the Website is also governed by our [Privacy Policy](insert link to Privacy Policy), which outlines how we collect, use, and protect your personal information.'/>
        </div>
        <div className='  xxxs:mx-4 xxs:mx-4 xs:mx-4
        sm:mx-20 lg:mx-20 xl:mx-20 1xl:mx-20 
        11xl:mx-20 2xl:mx-20 22xl:mx-20 3xl:mx-20 mb-10'>
          <h3 className=" xxxs:text-xl xxs:text-xl xs:text-xl
          sm:text-2xl lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl
           font-bold text-start mb-3 text-big-text-color">
      Disclaimers and Limitation of Liability
          </h3>
          <CustomTextTerms title='We will not be liable for any damages arising from the use or inability to use the Website, including but not limited to any direct, indirect, incidental, special, consequential, or punitive damages, even if we have been advised of the possibility of such damages.'/>
        </div>
      </div>
    </div>
  );
};

export default StackTerms;
