import React ,{useState,useEffect} from 'react';
import { hasFlag } from 'country-flag-icons'

import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import AboutUsSectionHome from '../../components/users/homeuser/aboutushomesection';
import CustomButton  from '../../components/users/sharedcomponent/custombutton';
import ListOfCourseHome from '../../components/users/homeuser/listofCoursesHome';
import ListOfUpCommingEventsHome from '../../components/users/homeuser/upcomingeventshome';
import BlogSectionHome from '../../components/users/homeuser/blogsectionhome';
import MemberShipSectionInHome from '../../components/users/homeuser/membershipsectioninhome';
import ResoursesSectionInHome from '../../components/users/homeuser/resoursesectioninhome';
import HorizontalScrollList from '../../components/users/homeuser/Consultationssectioninhome';
import TeamSectionInHome from '../../components/users/homeuser/teamsectioninhome';
import ReviewsSectionInHome from  '../../components/users/homeuser/reviewssectioninhome';
import StoreSectionInHome from '../../components/users/homeuser/storesectioninhome';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import NavBarHomeUser from '../../components/users/sharedcomponent/navbarhomeuser';
import DragCards from '../../components/users/homeuser/animatedcard';
import WhyUsSectionInHome from '../../components/users/homeuser/whyussectioninhome';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import AllServicesSections  from  '../../components/users/homeuser/allServicesSection';
const HomeUser = () => {
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
    const [scrollingDown, setScrollingDown] = useState(true);
  
    useEffect(() => {
      let lastScrollTop = 0;
  
      const handleScroll = () => {
        const currentScrollTop = window.scrollY;
  
        setScrollingDown(currentScrollTop > lastScrollTop);
        setIsNavBarVisible(currentScrollTop > 100); // Adjust threshold as needed
  
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
    return (
      <div className="relative ">
        {/* This div will handle the padding-top adjustment for the fixed navbar */}
        <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
         <div className="head-user-container">
  <HeadUser />
</div>
          <HeaderHomeUse />
          <CustomDivider />
          <div className="head-user-container">
          <div className='flex justify-center items-center hidden-mobile sm:hidden-mobile md:hidden-mobile mid:hidden-mobile xxxxs:hidden-mobile xxxxxs:hidden-mobile '>
       <CustomStaticFlyoutMenu/>
       </div>
          </div>
        </div>
        <CustomDivider />
        <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-50'>
      <DragCards/>
        <AboutUsSectionHome/>
        <WhyUsSectionInHome/>
        <AllServicesSections />
        {/* <ResoursesSectionInHome/> */}
    <ListOfUpCommingEventsHome/>
    <HorizontalScrollList/>
    <StoreSectionInHome/>
    <MemberShipSectionInHome/>
    {/* <TeamSectionInHome/> */}
    <FooterUser/>
      </div>
  
        </div>

    )
}
export default HomeUser;