import React from 'react';

const ButtonUnderLine = ({ name }) => {
  return (
    <a 
      href="#!" 
      className="mr-1 ml-1 px-1 relative text-sm text-white hover:text-primary-color focus:text-primary-color active:primary-color "
    >
      {name}
      <span className=" absolute inset-x-0 bottom-0 h-0.5 bg-blue-500 transition-transform duration-300 transform scale-x-0 group-active:scale-x-100 group-focus:scale-x-100" />
    </a>
  );
};

export default ButtonUnderLine;
