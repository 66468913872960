import React from 'react';
import HeadAdmin from '../../components/admin/headadmin';
import NavBarAdmin from '../../components/admin/navbaradmin';
import StackContainer from '../../components/instructor/stackContainer';
import SidebarAdmin from '../../components/admin/sidebardashboard';
import Footer from '../../components/users/sharedcomponent/footeruser';
import CustomStackAdmin from '../../components/admin/customstackinadmin';
const MyDashBoardAdmin =()=>{
  return(
    <div>
          {/* <HeadAdmin/> */}
    <NavBarAdmin/>
    <CustomStackAdmin/>
    <SidebarAdmin/>

    <Footer/>
    </div>
  )
}
export default  MyDashBoardAdmin;