import React from 'react';

const CollapsAll = ({ onClick }) => {
  return (
    <div>
      <h3 className="mt-5 mb-2 text-lg font-bold text-big-text-color">Refine by</h3>
      <h3 className='text-sm underline cursor-pointer' onClick={onClick}>
        Collapse All
      </h3>
    </div>
  );
};

export default CollapsAll;
