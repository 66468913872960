import React from 'react';
import HeadAdmin from '../../components/admin/headadmin';
import StackContainerCompany from '../../components/company/stackContainercompany';
import SidebarAdmin from '../../components/admin/sidebardashboard';
import Footer from '../../components/users/sharedcomponent/footeruser';
import SidebarCompany from '../../components/company/sidbarcompany';
import NavBarCompanyAdmin from '../../components/company/navbarcompany';
const DashBoardCompany =()=>{
  return(
    <div>
          {/* <HeadAdmin/> */}
          <NavBarCompanyAdmin/>
    <StackContainerCompany/>
    <SidebarCompany/>

    <Footer/>
    </div>
  )
}
export default  DashBoardCompany;