import React , { useState } from 'react';
import { HomeIcon,UserIcon,BookOpenIcon,BookmarkIcon ,StarIcon,ExclamationCircleIcon,
    DocumentDuplicateIcon,
    ComputerDesktopIcon,
    SpeakerWaveIcon,
    ChatBubbleLeftIcon,
    Bars3Icon,
    Cog8ToothIcon,
    ArrowLeftEndOnRectangleIcon
} from '@heroicons/react/24/outline'; 
import ListOfWishListProducts from './listofwishlist';
import ReviewAdmin from '../admin/reviewadminview';
import MyAttempts from '../instructor/myattempts';
import HistoryOrders from '../admin/historyorders';
import CustomDashBoardCompany from './customdashboard';
import {Tablecoursesdashboard} from './tablecourse';
import ListOfProducts from './listofproducts';
import AnnouncementsViewCompany from './announcemnetviewcompany';
import AssignmentsView from './assignmentsviewcompany';
import MyProfileDescriptionCardCompany from './descriptionmyprofilecardCompany';
import {TableAnnouncementClient} from './tableclients';
import SettingCompany from './settingcompany';
const SidebarCompany = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard'); // State to keep track of selected menu item

    const handleMenuItemClick = (menuItem) => {
      setSelectedMenuItem(menuItem);
    };
  return (
   <div className='flex'>
     <div className=" border border-primary-color rounded-custom mt-5 mr-10 ml-20 mb-20 w-64 h-200 ">
      <div className="p-4  text-2xl font-semibold  text-big-text-color">
Welcome, Target Company
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'dashboard' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('dashboard')}>
      <HomeIcon className="h-5 w-5 mr-2"/>
        Dashboard</li>
        <li className={`flex items-center px-4 py-2  text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'profile' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('profile')}>
        <UserIcon className="h-5 w-5 mr-2"/>
        My Profile</li>
        {/* <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Enrolled' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Enrolled')}>
            <BookOpenIcon className="h-5 w-5 mr-2"/>
         Products</li> */}
        <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Wishlist' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Wishlist')}>
            <BookmarkIcon className="h-5 w-5 mr-2"/>
        Products</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Reviews' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Reviews')}>
            <StarIcon  className="h-5 w-5 mr-2"/>
        Reviews</li>
      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Clients
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'MyClients' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('MyClients')}>
      <ComputerDesktopIcon className="h-5 w-5 mr-2"/>
            My Clients</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Announcements' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Announcements')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            Announcements</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'ClientReviews' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('ClientReviews')}>
            <Bars3Icon className="h-5 w-5 mr-2"/>
            Clients Reviews</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Company
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Settings' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Settings')}>
      <Cog8ToothIcon className="h-5 w-5 mr-2"/>          
        Settings</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div  cursor-pointer ${selectedMenuItem === 'Logout' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Logout')}>
        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2"/>         
           Logout</li>
      </ul>
   
    </div>
    {selectedMenuItem === 'dashboard' && (
          <div>
         <div className='w-[1050px]'>
         <CustomDashBoardCompany/>
          </div>
          </div>
        )}
        {
          selectedMenuItem === 'Settings'&&(
            <div className='w-[1050px]' > 
            <SettingCompany/>
            </div>
          )
        }
        {
          selectedMenuItem === 'MyClients'&&(
            <div>
              <TableAnnouncementClient/>
              </div>
          )
        }
{
  selectedMenuItem === 'ClientReviews'&&(
    <div className='w-[1050px]'>
        <ReviewAdmin/>
      </div>
  )
}
        {
          selectedMenuItem === 'Reviews'&&(
            <div className='w-[1050px]'>
                <Tablecoursesdashboard />
              </div>
          )
        }
          {selectedMenuItem === 'profile' && (
        <div className='w-[1050px]'>
            <MyProfileDescriptionCardCompany/>
          </div>
        )}
        {selectedMenuItem === 'Wishlist'&&(
             <div className='w-[1050px]'>
                <ListOfWishListProducts/>
                </div>
        )}
       
        {selectedMenuItem === 'Attempts'&& (
         <div className='w-[1050px]'>
                <MyAttempts/>
                </div>
        )}
          {selectedMenuItem === 'Enrolled'&& (
            <div className='w-[1050px]'>
                <ListOfProducts/>
                </div>
        )}
        {selectedMenuItem === 'History'&&(
         <div className='w-[1050px]'>
               <HistoryOrders/>
                </div>
        )
        }
        {
          selectedMenuItem === 'Courses' && (
            <div>
              <div className='w-[1050px]'>
                <ListOfWishListProducts/>
                </div>
              </div>
          )
        }
        {
          selectedMenuItem === 'Announcements'&& (
            <div className='w-[1050px]'>
              <AnnouncementsViewCompany/>
              </div>
          )
        }
        {
          selectedMenuItem === 'MyAttempts'&&(
            <div className='w-[1050px]'>
              <MyAttempts/>
              </div>

          )
        }
        {
          selectedMenuItem === 'Assignments'&&(
            <div className='w-[1050px]'>
              <AssignmentsView/>
              </div>
          )
        }

   </div>
  );
};

export default SidebarCompany;

