import React ,{useEffect,useState ,useRef} from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import StarReview from '../../admin/reviews';
import imageTeamOne from '../../../asset/images/team-1.webp.png';
import imageTeamTwo from '../../../asset/images/team-02.webp.png';
import imageTeamThree from '../../../asset/images/team-03.webp.png';
import imagecoursethree from '../../../asset/images/istock-1220226086.jpg'
import imageCourseTwo from '../../../asset/images/Capstone_Course.jpeg.webp';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { CreditCardIcon, UsersIcon, BookmarkIcon } from '@heroicons/react/24/outline';
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import CustomButton from '../sharedcomponent/custombutton';
import imagecourseone from '../../../asset/images/events.png';
import CustomBigTitle from './custombigtitle';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import { useNavigate } from 'react-router-dom';
import { Button } from '@headlessui/react';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
const products = [
  {
    id: 1,
    name: 'INSTRUCTIONAL ',
    href: '#',
    imageTeam : imageTeamOne,
    imageSrc : imagecourseone,
    studentNum : '22',
    courseNum : '44',
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$84',
    color: 'Black',
  },
  {
    id: 2,
    name: 'UI DESIGN',
    href: '#',
    studentNum : '65',
    courseNum : '87',
    imageTeam : imageTeamTwo,
    imageSrc : imagecoursethree,
    contant : 'This course offers the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$98',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Frontend DESIGN',
    href: '#',
    studentNum : '88',
    courseNum : '12',
    imageTeam : imageTeamThree,
    imageSrc : imageCourseTwo,
    contant : 'This course offers a common course  to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$85',
    color: 'Black',
  },
  {
    id: 1,
    name: 'DESIGN',
    href: '#',
    studentNum : '85',
    courseNum : '14',
    imageTeam : imageTeamOne,
    imageSrc : imagecourseone,
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$65',
    color: 'Black',
  },
  {
    id: 2,
    name: 'INSTRUCTIONAL DESIGN',
    href: '#',
    studentNum : '22',
    courseNum : '44',
    imageTeam : imageTeamTwo,
    imageSrc : imageCourseTwo,
    contant : 'This course offers the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$55',
    color: 'Black',
  },
  {
    id: 3,
    name: 'INSTRUCTIONAL DESIGN',
    href: '#',
    studentNum : '22',
    courseNum : '44',
    imageTeam : imageTeamThree,
    imageSrc : imagecoursethree,
    contant : 'This course offers a education to the principles and practices of instructional design, aimed at equipping students with the skills offers a comprehensive introduction.',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$41',
    color: 'Black',
  },
];

export default function ListOfCourseHome() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const [itemsPerScroll, setItemsPerScroll] = useState(1); // Default to 1 for mobile
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/courseInside'); 
  };

  const updateItemsPerScroll = () => {
    const width = window.innerWidth;
    if (width >= 768) { // Tailwind's md breakpoint
      setItemsPerScroll(3);
    } else {
      setItemsPerScroll(1);
    }
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const scrollLeft = () => {
    scrollOneItem('left');
  };

  const scrollRight = () => {
    scrollOneItem('right');
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    updateItemsPerScroll();
    window.addEventListener('resize', updateItemsPerScroll);
    return () => {
      window.removeEventListener('resize', updateItemsPerScroll);
    };
  }, []);

  const numberOfDots = Math.ceil(products.length / itemsPerScroll);

  return (
    <div className='bg-white justify-center items-center px-2  pb-5'>
      {/* <div className='flex items-center justify-center mt-5 mb-5'>
        <CustomButton name='Popular Courses'/>
      </div>

      <CustomBigTitle title='ONLINE COUCHING LESSONS FOR ONLINE LEARNING'/> */}
      <div className=' mb-20 justify-center items-center
       lg:px-12 xl:px-12 1xl:px-12 11xl:px-12 2xl:px-12 3xl:px-12 xxxs:px-2 xxs:px-2 xs:px-2'>
        <div className="relative flex items-center">
          {/* Left Arrow - Visible on all screen sizes */}
          {/* <button
            onClick={scrollLeft}
            className="absolute left-0 z-10 p-2  text-white bg-custom-blue rounded-full shadow-md hover:bg-yellow-800 focus:outline-none"
          >
            <ArrowLeftIcon className='w-6 h-6'/>
          </button> */}

          {/* Scrollable Container */}
          <div
            ref={scrollRef}
            className={`flex overflow-x-auto p-4 w-full scrollbar-hidden 
              scroll-container ${itemsPerScroll === 1 ? 'space-x-3' : 'space-x-3'}`}
            style={{ scrollSnapType: 'x mandatory' }}
          >
            {products.map((product) => (
              <div 
                key={product.id} 
                className={`relative flex-none ${itemsPerScroll === 1 ? 'w-full' : 'lg:w-1/4 xl:w-1/4 1xl:w-1/4 11xl:w-1/4 2xl:w-1/4 3xl:w-1/4 xxs:w-full xxxs:w-full xs:w-full'} min-w-[100px] rounded-lg shadow-sm bg-white
                 p-4`} 
                style={{ scrollSnapAlign: 'start' }}
              >
                <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-white lg:aspect-none group-hover:opacity-75 lg:h-40 mb-3">
                  <img
                    alt={product.imageAlt}
                    src={product.imageSrc}
                    className="h-full w-full object-cover object-center"
                  />
                </div>
                <div className="mr-5 ml-5">
                  <div className="flex justify-between">
                    <StarReview />
                  <BookmarkButton/>
                  </div>
                  <h2 className="lg:text-lg xl:text-lg 1xl:text-lg 11xl:text-lg 2xl:text-xl 22xl:text-xl 3xl:text-xl
                   text-big-text-color font-semibold mt-4">
                    {product.name}
                  </h2>
                  <div className="flex space-x-4 mt-2">
                    <CustomIconAndText
                     icon={<CreditCardIcon className="sm:h-4 sm:w-4 mid:h-4 mid:w-4 md:h-4 md:w-4
                      lg:h-4 lg:w-4 xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-4 2xl:w-4
                      text-gray-500" />}
                      title={`${product.courseNum} Courses `} />
                    <CustomIconAndText 
                    icon={<UsersIcon className="h-4 w-4 text-gray-500" />} 
                    title={`${product.studentNum} Students `}/>
                  </div>
             
                  <CustomParagraph title=  {product.contant}/>
                  <CustomTitleAndImageOnly
                    imageSrc={product.imageTeam}
                    title="Tarek Tantawy"
                  />
                  <div className="mt-4 mb-5 flex justify-between items-center">
                    <p className="text-lg font-medium text-primary-color">{product.price}</p>
                    <button 
                     onClick={handleClick}
                    className="mt-1 text-sm text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center">
                      ENROLL COURSE
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Right Arrow - Visible on all screen sizes */}
          {/* <button
            onClick={scrollRight}
            className="absolute right-0 z-10 p-2 text-white bg-custom-blue rounded-full shadow-md hover:bg-yellow-800 focus:outline-none"
          >
            <ArrowRightIcon className='w-6 h-6'/>
          </button> */}
        </div>

        {/* Dots Container */}
        {/* <div className="flex justify-center mt-4">
          {Array.from({ length: numberOfDots }).map((_, index) => (
            <div
              key={index}
              onClick={() => scrollToIndex(index)}
              className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-big-text-color' : 'bg-gray-300'}`}
            />
          ))}
        </div> */}
      </div>
    </div>
  );
}