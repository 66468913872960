import React, { useState } from 'react';

const ShoppingCartDrawer = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative">
    

      {/* Drawer */}
      <div
        className={`fixed top-0 right-0 h-full w-64 bg-white shadow-lg transition-transform transform ${
          isOpen ? 'translate-x-0' : 'translate-x-full'
        }`}
      >
        <div className="p-4">
          <h2 className="text-lg font-bold">Drawer Content</h2>
          <p>This is the content of the drawer.</p>
          <button
            onClick={toggleDrawer}
            className="mt-4 bg-red-500 text-white px-2 py-1 rounded"
          >
            Close Drawer
          </button>
        </div>
      </div>

      {/* Overlay to close the drawer when clicking outside */}
      {isOpen && (
        <div
          className="fixed inset-0 bg-black opacity-50"
          onClick={toggleDrawer}
        ></div>
      )}
    </div>
  );
};

export default ShoppingCartDrawer;
