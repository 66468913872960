import React from 'react';

const CustomIconAndText = ({ icon, title, link }) => {
  return (
    <a
      href={link || "#"} // Add a link prop to handle external links
      target="_blank" 
      rel="noopener noreferrer"
      className="flex items-center space-x-1 text-gray-500 hover:underline cursor-pointer mr-4 sm:mr-4 lg:mr-4 xl:mr-2 1xl:mr-2 11xl:mr-2 2xl:mr-4 22xl:mr-4 3xl:mr-4"
    >
      <div className="text-gray-500">
        {icon}
      </div>
      <h3 className="xxxs:text-xs xxs:text-ss xs:text-xs sm:text-xs 
       lg:text-xs xl:text-xs 1xl:text-xs 11xl:text-xs 2xl:text-xs 22xl:text-xs 3xl:text-xs 
      
       text-gray-500">
        {title}
      </h3>
    </a>
  );
}

export default CustomIconAndText;
