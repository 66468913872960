import React from 'react';
import { useNavigate } from 'react-router-dom';

const CustomTitleAndImageOnly = ({ imageSrc, title, navigateTo }) => {
  const navigate = useNavigate(); // Initialize the navigate function

  // Handler for the title click event
  const handleTitleClick = () => {
    // Navigate to the provided route when the title is clicked
    if (navigateTo) {
      navigate(navigateTo);
    }
  };

  return (
    <div className="flex items-center mt-2">
      <img 
        alt="" 
        src={imageSrc} 
        className="xxxs:h-5 xxxs:w-5 xxs:h-5 xxs:w-5 xs:h-5 xs:w-5 sm:h-10 sm:w-10 lg:h-10 lg:w-10 xl:h-10 xl:w-10 1xl:h-10 1xl:w-10 11xl:h-10 11xl:w-10 2xl:h-10 2xl:w-10 3xl:h-10 3xl:w-10 rounded-custom" 
      />
      <div className='flex'>
        <h3 className="text-base font-semibold leading-7 tracking-tight text-gray-500 ml-2">By</h3>
        <button 
          onClick={handleTitleClick} 
          className="text-base font-semibold leading-7 tracking-tight text-gray-500 ml-1 underline cursor-pointer"
        >
          {title}
        </button>
      </div>
    </div>
  );
};

export default CustomTitleAndImageOnly;
