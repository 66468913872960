import React from 'react';
import imageMemberShip from '../../../asset/images/membershipimage.png';
import CustomRightCheckBox from './customrightcheckbox';
import img from '../../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../sharedcomponent/custombutton';
const products = [
  {
    id: 4,
    name: 'Basic Tee',
    subTitle: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 5,
    name: 'Basic Tee',
    subTitle: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 6,
    name: 'Basic Tee',
    subTitle: 'Basic Tee',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Engage in conversations about renewable energy, zero waste initiatives, and green building practices. Research your target market and understand their needs and preferences.",
    price: '$35',
    color: 'Black',
  },
];

const AllMemberShipList = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/memberShipInside'); 
  };
  return (
    <div className=" mb-20 grid grid-cols-1 gap-5 px-2
    lg:w-[1100px] xl:w-[1100px] 2xl:w-[1050px] ml-10">
      {products.map((product) => (
        <div key={product.id} className="group relative bg-white p-5 flex rounded-custom shadow-lg">
         <div className="w-1/3 flex-shrink-0 rounded-custom">
           <img
              alt={product.imageAlt}
              src={imageMemberShip}
              className="h-full w-full object-cover object-center rounded-md"
            />
          </div>
          <div className="pt-1.5 min-w-0 flex-1 sm:pt-0 p-4">
            <div>
              <h2 
  className="text-xl font-bold leading-8 tracking-tight text-big-text-color cursor-pointer" 
  onClick={handleClick}
>
{product.name}
</h2>
              <h4 className="text-md font-semibold leading-6 tracking-tight text-gray-600">{product.subTitle}</h4>
              <div>
                <CustomRightCheckBox title='NEW! Course Body of Knowledge' />
                <CustomRightCheckBox title='Templates and Tools Library' />
                <CustomRightCheckBox title='Videos and Webinars' />
                <CustomRightCheckBox title='Digital Magazine Subscription' />
                <CustomRightCheckBox title='Annual State of the Industry Report' />
                <CustomRightCheckBox title='Members-Only Newsletters & Articles' />
                <CustomRightCheckBox title='Case Studies' />
                <CustomRightCheckBox title='Research Whitepapers' />
                <CustomRightCheckBox title='Members-Only Discounts' />
                <CustomRightCheckBox title='Unlimited Access to Professional Specialists' />
              </div>
              <div className="flex justify-between items-center mt-4">
                <h3 className="text-xl font-semibold leading-8 tracking-tight text-yellow-600">{product.price}</h3>
                <button
                  onClick={handleClick}
                  className="text-base text-custom-border border rounded-custom border-custom-border bg-transparent px-4 py-2 flex items-center hover:bg-gray-100 transition-colors justify-center items-center">
                  Join Now 
                  <img
src={img}
alt="Course Icon"
className="ml-2 justify-center items-center"
/>
                  </button>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AllMemberShipList;
