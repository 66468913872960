import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { twMerge } from "tailwind-merge";
import ShadowButton from '../sharedcomponent/buttonshadow';
import imageleader from '../../../asset/images/leader.png';
import arrowRightImage from '../../../asset/images/arrowrightwhite.png';

const DragCards = () => {
  // Array of text content with associated background image URLs
  const textContent = [
    { text: "Welcome to the Future of Education", bgImage: "https://dashboard.iboostweb.com/uploads/blog/list/1706572840.jpg" },
    { text: "Empowering Professionals Worldwide", bgImage: "https://img.freepik.com/premium-vector/megaphone-with-plant-leaf-dark-blue-bubble-speech_44481-340.jpg?w=740" },
  ];

  const [activeTextIndex, setActiveTextIndex] = useState(0);

  // Change text every 5 seconds (5000ms)
  useEffect(() => {
    const intervalId = setInterval(() => {
      setActiveTextIndex((prevIndex) => (prevIndex + 1) % textContent.length);
    }, 5000); // Change text every 5 seconds

    return () => clearInterval(intervalId); // Cleanup on unmount
  }, []);

  return (
    <motion.section
      key={activeTextIndex} // Ensure full re-render when text or background changes
      className="xs:px-2 xxs:px-2 xxxs:px-2 lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 3xl:px-24 relative xs:h-full xxs:h-full xxxs:h-full sm:h-full lg:h-full xl:h-full 2xl:h-full w-full overflow-visible pt-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      {/* Wrapper for background image */}
      <motion.div
        className="absolute inset-0 bg-cover bg-center transition-all duration-1000"
        style={{
          backgroundImage: `url(${textContent[activeTextIndex]?.bgImage})`,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
      />

      <div className="flex-1 flex flex-col justify-center px-4 pt-0 pb-4 relative z-10">
        {/* Leader section */}
        <div className="flex bg-white lg:w-[350px] xl:w-[350px] 2xl:w-[500px] justify-center items-center mb-10 rounded-custom p-2">
          <img src={imageleader} alt="Leader" />
          <h4 className="ml-2 text-sm lg:text-lg xl:text-lg 2xl:text-2xl font-bold tracking-tight text-gray-900 font-sans">
            The Leader In Online Learning
          </h4>
        </div>

        {/* Main Heading */}
        <div className="mb-3 font-k2d">
          <div className="flex flex-col sm:flex-row">
            <h1 className="text-2xl sm:text-4xl lg:text-4xl xl:text-4xl 2xl:text-5xl 3xl:text-6xl font-normal tracking-tight text-big-text-color pr-1">
              {textContent[activeTextIndex]?.text}
            </h1>
          </div>
        </div>

        {/* Paragraph text */}
        <p className="mt-4 text-sm sm:text-base sm:text-lg lg:text-lg xl:text-lg 2xl:text-2xl 3xl:text-3xl text-gray-500 sm:w-[600px] lg:w-[600px] xl:w-[600px] 1xl:w-[650px] 11xl:w-[650px] 2xl:w-[900px] 3xl:w-[1100px] font-k2d">
          Business Index Sentiments Education is your source for understanding talent development, and professionalizing. Special thanks to the business development consultant Dr. Tarek Tantawi.
        </p>

        {/* Call to Action */}
        <div className="w-200 mt-6 sm:mb-15 lg:mb-5 xl:mb-5 11xl:mb-5 1xl:mb-5 2xl:mb-5 3xl:mb-5 xxxs:mb-80 xxs:mb-80 xs:mb-80">
          <ShadowButton image={arrowRightImage}>Create Account</ShadowButton>
        </div>
      </div>
    </motion.section>
  );
};

export default DragCards;
