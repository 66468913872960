import React from 'react';

const CustomBigTitle =({title})=>{

    return(
        <h2 className="lg:text-3xl xl:text-3xl  2xl:text-4xl 
        font-bold tracking-tight text-big-text-color items-center text-center font-sans  ">
        {title}
      </h2>
    ) 
}
export default CustomBigTitle;