import React, { useEffect, useState, useRef } from 'react';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from '../../../asset/images/goodevent.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { MapPinIcon, ClockIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import arrowRightImage from '../../../asset/images/arrowrightwhite.png';
import imageteam from '../../../asset/images/d1af7d099058eb24786ab692d7f35bbc.png';
import imageResourseOne from '../../../asset/images/imagesres.jpg';
import imageResourseTwo from '../../../asset/images/imagesr.jpg';
import { useNavigate } from 'react-router-dom';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
import CustomIconAndTextDate from '../sharedcomponent/customTitleAndIconDates';
import CustomButton from '../sharedcomponent/custombutton';
const products = [
  {
    id: 1,
    name: 'How To Upgrade Your Clients',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources to create effective, results-driven training.',
    imageSrc: imageResourseOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Advices In Your Business',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: imageResourseTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 1,
    name: 'How To Upgrade Your Clients',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: image,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business',
    href: '#',
    contant: 'Utilize innovative learning frameworks and resources to create effective, results-driven training.',
    imageSrc: imageResourseOne,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Advices In Your Business',
    href: '#',
    contant: 'Leverage exclusive ATD templates and tools to develop the most powerful, bottom-line focused learning',
    imageSrc: imageResourseTwo,
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  // Additional products...
];

export default function ListOfUpCommingEventsHome() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerScroll = 3; // Number of items per scroll action
  const numberOfDots = Math.ceil(products.length / itemsPerScroll);
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/eventInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="bg-custom-blue justify-center items-center pt-20 pb-2">
      <div className='items-center justify-center mr-10 ml-10'>
        <div className='flex items-center justify-center mb-5'>
          {/* <div
            className="rounded-custom bg-white 
            xxxs:px-2 xxxs:py-1 xxs:px-2 xxs:py-1 xs:px-2 xs:py-1
          sm:px-6 sm:py-1 lg:px-10 lg:py-1 xl:px-10 xl:py-1 1xl:px-10 1xl:py-1
           11xl:px-10 11xl:py-1 2xl:px-10 2xl:py-1 3xl:px-10 3xl:py-1 
           sm:text-sm lg:text-sm xl:text-sm 1xl:text-sm
           11xl:text-lg 2xl:text-lg 22xl:text-lg 3xl:text-lg
            font-semibold text-yellow-700 shadow-sm  font-sans
            "
          >
            Latest Events
          </div> */}
          <CustomButton name='Latest Events'/>
        </div>

        <h2 className="
        xxxs:text-lg xxs:text-lg xs:text-lg xxxxs:text-lg xxxxxs:text-lg 
        sm:text-xl mid:text-xl md:text-xl
        lg:text-2xl xl:text-2xl 1xl:text-2xl 11xl:text-2xl 2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-bold tracking-tight text-white text-center mb-5">
        UPCOMING EVENTS THAT FIT YOUR INTEREST
        </h2>

        <div className='xxxs:px-1 xxs:px-1 xs:px-1 
        lg:px-24 xl:px-24 1xl:px-24 11xl:px-24 2xl:px-24 22xl:px-24 3xl:px-24
        mx-2
         mb-20 justify-center items-center'>
          <div className="relative flex items-center">
            {/* Left Arrow */}
            <button
              onClick={() => scrollOneItem('left')}
              className="absolute left-[-50px] 
                mr-12
              z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
            >
              <ArrowLeftIcon className='w-6 h-6' />
            </button>

            <div
              ref={scrollRef}
              className="flex overflow-x-auto
              xxxs:space-x-8 xxs:space-x-8 xs:space-x-8 xxxxs:space-x-8 xxxxxs:space-x-8
              lg:space-x-8  xl:space-x-8  1xl:space-x-8  11xl:space-x-8  2xl:space-x-8  22xl:space-x-8
               3xl:space-x-8 p-4 w-full 
               xxxs:px-4 xxs:px-4 xs:px-4 xxxxs:px-4 xxxxxs:px-4
               scrollbar-hidden"
              style={{ scrollSnapType: 'x mandatory', paddingLeft: '30px', paddingRight: '30px' }}
            >
              {products.map((product) => (
                <div
                  key={product.id}
                  className="relative flex-none w-full xxs:w-full xxxs:w-full xs:w-full
                  sm:w-1/2 lg:w-1/3  xl:w-1/3 1xl:w-1/3 11xl:w-1/3 2xl:w-1/3 22xl:w-1/3 3xl:w-1/3 
                  rounded-lg shadow-lg bg-white
                  xxxs:p-3 xxs:p-3 xs:p-3
                  sm:p-4 lg:p-4 xl:p-4 1xl:p-4 11xl:p-4 2xl:p-4 22xl:p-4 3xl:p-4" 
                  style={{ scrollSnapAlign: 'start' }}
                >
                  <div className="aspect-h-1 aspect-w-1 w-full overflow-hidden rounded-md bg-gray-200 lg:aspect-none group-hover:opacity-75 lg:h-40 mb-3">
                    <img
                      alt={product.imageAlt}
                      src={product.imageSrc}
                      className="h-full w-full object-cover object-center"
                    />
                  </div>
                  <div className="flex justify-between mt-5">
                  <h2 
  className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold cursor-pointer" 
  onClick={handleClick}
>
  {product.name}
</h2>

                    <BookmarkButton />
                  </div>
                  <div className="flex mt-2 ">
                  <CustomIconAndText 
  icon={<MapPinIcon className="h-4 w-4 text-gray-500" />} 
  title="Cairo, Egypt" 
  link="https://www.google.com/maps/search/?api=1&query=72+Gameat+Al-dewal+Al-Arabia,+Mohandessien" 
/>                    <CustomIconAndTextDate icon={<ClockIcon className="h-4 w-4 text-gray-500" />} title='16 Nov, 2024' />
                  </div>
              <CustomParagraph title= {product.contant}/>
              
                  <CustomTitleAndImageOnly
                     navigateTo="/eventInside" 
                    imageSrc={imageteam}
                    title="Business Index sentiments"
                  />
                  <div className="flex justify-between mt-2 items-center">
                    <p className="
                    xxxs:text-lg xxs:text-lg  xs:text-lg xxxxs:text-lg xxxxxs:text-lg
                    sm:text-lg lg:text-lg xl:text-lg 1xl:text-xl 11xl:text-xl
                     2xl:text-2xl 22xl:text-2xl 3xl:text-2xl font-medium text-primary-color">{product.price}</p>
                    <button
                      onClick={handleClick}
                      className="text-sm text-white border rounded-custom bg-primary-color px-3 py-2 flex items-center">
                      See Details
                      <img
                        src={arrowRightImage}
                        alt="Arrow Right"
                        className="h-4 w-4 ml-2"
                      />
                    </button>
                  </div>
                </div>
              ))}
            </div>

            {/* Right Arrow */}
            <button
              onClick={() => scrollOneItem('right')}
              className="absolute right-[-50px]
              ml-12
              z-10 p-2 text-white bg-primary-color rounded-full shadow-md focus:outline-none"
            >
              <ArrowRightIcon className='w-6 h-6' />
            </button>
          </div>

          {/* Dots Container */}
          <div className="flex justify-center mt-4">
            {Array.from({ length: numberOfDots }).map((_, index) => (
              <div
                key={index}
                onClick={() => scrollToIndex(index)}
                className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-primary-color' : 'bg-gray-300'}`}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}
