import React, { useRef, useState, useEffect } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import StarReview from "../../admin/reviews";
import imageRes from '../../../asset/images/62b1de2e8e142538f54863b6_What is course design.jpg';
import CustomIconAndText from '../../users/sharedcomponent/customiconandtext';
import { ClockIcon, PaperClipIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../../users/sharedcomponent/imageandtitleonly';
import imageResourses from '../../../asset/images/resourseimage.png';
import { ArrowRightIcon, ArrowLeftIcon } from '@heroicons/react/24/solid';
import CustomResourcesButton from "./customResourcesButton";
import imageResourseOne from '../../../asset/images/imagesgrow.jpg';
import imageResourseTwo from '../../../asset/images/70242659-requirements-concept-on-tablet-pc-screen.jpg';
import { useNavigate } from 'react-router-dom';
import BookmarkButton from '../sharedcomponent/customBookMark';
import CustomParagraph from '../sharedcomponent/customParagraph';
import image from '../../../asset/images/sideimage.png'
import CustomIconAndTextDate from "../sharedcomponent/customTitleAndIconDates";
const products = [
  { id: 1, name: 'Grow your business ', imageResourse: imageResourses, href: '#' },
  { id: 2, name: 'Expand your reach ', imageResourse: imageResourseOne, href: '#' },
  { id: 3, name: 'Optimize your strategy ', imageResourse: imageResourseTwo, href: '#' },
  { id: 4, name: 'Scale your team ', imageResourse: imageResourses, href: '#' },
  { id: 5, name: 'Maximize your efficiency ', imageResourse: imageResourseOne, href: '#' },
  { id: 6, name: 'Innovate continuously ', imageResourse: imageResourseTwo, href: '#' },
];

export default function AllResourcesListInHome() {
  const scrollRef = useRef(null);
  const [scrollIndex, setScrollIndex] = useState(0);
  const itemsPerScroll = 1; // Number of items per scroll action for mobile
  const numberOfDots = 2; // Always show two dots
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/resourcesInside');
  };

  const scrollOneItem = (direction) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const scrollAmount = direction === 'left' ? -itemWidth : itemWidth;
      scrollRef.current.scrollBy({ left: scrollAmount, behavior: 'smooth' });
    }
  };

  const scrollToIndex = (index) => {
    if (scrollRef.current) {
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      scrollRef.current.scrollTo({ left: index * itemWidth * itemsPerScroll, behavior: 'smooth' });
      setScrollIndex(index);
    }
  };

  const scrollLeft = () => {
    scrollOneItem('left');
  };

  const scrollRight = () => {
    scrollOneItem('right');
  };

  const updateScrollIndex = () => {
    if (scrollRef.current) {
      const scrollLeft = scrollRef.current.scrollLeft;
      const itemWidth = scrollRef.current.children[0].offsetWidth;
      const newIndex = Math.floor(scrollLeft / (itemWidth * itemsPerScroll));
      setScrollIndex(newIndex);
    }
  };

  useEffect(() => {
    const handleScroll = () => updateScrollIndex();
    const container = scrollRef.current;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className='xxxs:mx-12 xxs:mx-12 xs:mx-12 
      xxxs:px-1 xxs:px-1 xs:px-1 
      lg:px-32 xl:px-1 1xl:px-1 11xl:px-1 2xl:px-1 22xl:px-1 3xl:px-1
       mb-20 justify-center items-center'>
      <div className="relative flex items-center">
        {/* Left Arrow */}
        {/* <button
          onClick={scrollLeft}
          className="absolute left-[-40px] z-10 p-2 text-white bg-custom-blue
           rounded-full shadow-md hover:bg-primary-color focus:outline-none"
        >
          <ArrowLeftIcon className='w-6 h-6' />
        </button> */}

        {/* Scrollable Container */}
        <div
          ref={scrollRef}
          className="flex overflow-x-auto space-x-3 p-4 w-full scrollbar-hidden"
          style={{ scrollSnapType: 'x mandatory' }}
        >
          {/* List Items */}
          {products.map((product) => (
            <div 
              key={product.id} 
              className="relative flex-none w-full xxs:w-full xxxs:w-full xs:w-full
               sm:w-1/2 lg:w-1/4 xl:w-1/4 1xl:w-1/4 11xl:w-1/4 2xl:w-1/4 22xl:w-1/4 3xl:w-1/4 rounded-lg shadow-sm bg-white
               xxxs:p-1 xxs:p-1 xs:p-1
               sm:p-2 lg:p-2 xl:p-2 1xl:p-2 11xl:p-2 2xl:p-2 22xl:p-2 3xl:p-2" 
              style={{ scrollSnapAlign: 'start' }}
            >
              <div className="flex justify-center items-center p-4">
                <img
                  alt={product.name}
                  src={product.imageResourse}
                  className="h-40 w-full object-cover object-center rounded-custom"
                />
              </div>

              <div className="pt-1.5 min-w-0 flex-1 sm:pt-0 p-4">
                <div className='justify-between flex'>
                  <StarReview />
                  <BookmarkButton />
                </div>

                <h3 className="lg:text-lg xl:text-lg 2xl:text-2xl font-semibold text-big-text-color"
                 onClick={handleClick} style={{ cursor: 'pointer' }}>
  {product.name}
</h3>

                <div className="flex space-x-4 mt-3 mb-3">
                <CustomIconAndTextDate
        icon={<ClockIcon className="h-4 w-4 text-gray-500" />} 
        title="16 Nov, 2024" 
      />
                  <CustomIconAndText icon={<PaperClipIcon className="h-4 w-4 text-gray-500" />} title='15 Downloads' />
                </div>
             
                <CustomParagraph
                  title='Engage in conversations about renewable energy, zero waste initiatives.'
                />
                <div className='flex justify-between'>
                  <CustomTitleAndImageOnly
                  onTitleClick={handleClick}
                  imageSrc={image} title="tantawy" />
                  <CustomResourcesButton
                    onClick={handleClick}
                    children='Read More' />
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* Right Arrow */}
        {/* <button
          onClick={scrollRight}
          className="absolute right-[-40px] z-10 p-2 text-white bg-custom-blue
           rounded-full shadow-md hover:bg-primary-color focus:outline-none"
        >
          <ArrowRightIcon className='w-6 h-6' />
        </button> */}
      </div>

      {/* Dots Container */}
      {/* <div className="flex justify-center mt-4">
        {Array.from({ length: numberOfDots }).map((_, index) => (
          <div
            key={index}
            onClick={() => scrollToIndex(index)}
            className={`w-3 h-3 mx-1 rounded-full cursor-pointer ${index === scrollIndex ? 'bg-big-text-color' : 'bg-gray-300'}`}
          />
        ))}
      </div> */}
    </div>
  );
}