import React from "react";
import CustomContactInfo from './customcontactinfo';
import { MapPinIcon,PhoneIcon,EnvelopeIcon  } from "@heroicons/react/24/outline";
const CustomContactInsideCourse =()=>{
    return (
        <div className='xxxs:w-[350px] xxs:w-[350px] xs:w-[380px] 
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
        bg-white rounded-custom
         mt-5 p-4 relative bg-white'>
        <h3 className='xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color'>
        Contact 
        </h3>
        <div className="sm:flex lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 3xl:flex 
        ">
      <CustomContactInfo icon={PhoneIcon} title="Phone Number" children='01029999210'/>
      <CustomContactInfo  icon={EnvelopeIcon} title="E-mail" children='Info@businessindexsolutions.com' />
      <CustomContactInfo  icon={MapPinIcon} title="Location"  children='72 Gameat Al-dewal Al-Arabia'/>
        </div>
        <div className='flex justify-center items-center'>
            <h2 className='text-sm text-big-text-color mr-1'>
            Have any question?
            </h2>
            <h2 className='text-sm text-big-text-color font-bold'>
            Contact Us
            </h2>
            </div>
     </div>
    )
}

export default CustomContactInsideCourse;