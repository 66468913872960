import React from 'react';
import { StarIcon } from '@heroicons/react/24/solid'; // Importing from heroicons as an example

const CustomStar = ({ colors, }) => {
  return (
    <div className="flex items-center">
      <div className="flex">
        {colors.map((color, index) => (
          <StarIcon key={index} className={`h-4 w-4 ${color}`} />
        ))}
      </div>
    </div>
  );
};

export default CustomStar;
