import React, { useState } from 'react';
import DualRangeSlider from './rangeslider';
import CustomSideTitle from './customsidetitle';
import CustomCheckBox from './checkbox';
import CustomCheckBoxStar from './checkboxreview';
import LocationFilter from './locationfilter';
import DataFilter from './datafilteration';
import CollapsAll from './collapsall';

const SortedSideBar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Default collapsed
  const handleCollapseToggle = () => setIsCollapsed(prev => !prev);

  // State to manage all checkboxes
  const [checkedState, setCheckedState] = useState({
    "Member Benefits": false,
    "Student": false,
    "Employee": false,
    "Manager": false,
    "Technology Application": false,
    "Collaboration": false,
    "Data and Analytics": false,
    "All Levels": false,
    "Beginner": false,
    "Intermediate": false,
    "Expert": false,
    "Arabic": false,
    "English": false,
  });

  // Handle checkbox toggle
  const handleCheckboxChange = (title) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the specific checkbox
    }));
  };

  return (
    <div className={`lg:w-[300px] xl:w-[300px] 2xl:w-[300px] 
    border border-custom-border p-4 rounded-lg bg-white sm:mr-10 lg:mr-10 xl:mr-10 1xl:mr-10 11xl:mr-10 2xl:mr-10 3xl:mr-10 transition-all duration-300 ${isCollapsed ? 'sticky top-[80px] z-30' : 'relative'}`}>
      <CollapsAll onClick={handleCollapseToggle} />
      <div className={`transition-all ${isCollapsed ? 'opacity-100 ' : 'opacity-100 max-h-[1500px]'}`}>
        <div className={`transition-all ${isCollapsed ? 'block' : 'hidden'}`}>
          <CustomSideTitle title="SORT By ACCESS" />
          <CustomCheckBox isChecked={checkedState["Member Benefits"]} onChange={() => handleCheckboxChange("Member Benefits")} title="Member Benefits" />
          <CustomSideTitle title="SORT By START TIME" />
          <DualRangeSlider />
          <CustomSideTitle title="Role" />
          <CustomCheckBox isChecked={checkedState["Student"]} onChange={() => handleCheckboxChange("Student")} title="Student" />
          <CustomCheckBox isChecked={checkedState["Employee"]} onChange={() => handleCheckboxChange("Employee")} title="Employee" />
          <CustomCheckBox isChecked={checkedState["Manager"]} onChange={() => handleCheckboxChange("Manager")} title="Manager" />
          <CustomSideTitle title="TOPIC" />
          <CustomCheckBox isChecked={checkedState["Technology Application"]} onChange={() => handleCheckboxChange("Technology Application")} title="Technology Application" />
          <CustomCheckBox isChecked={checkedState["Collaboration"]} onChange={() => handleCheckboxChange("Collaboration")} title="Collaboration" />
          <CustomCheckBox isChecked={checkedState["Data and Analytics"]} onChange={() => handleCheckboxChange("Data and Analytics")} title="Data and Analytics" />
        </div>
        <div className={`transition-all ${isCollapsed ? 'hidden' : 'block'}`}>
          <CustomSideTitle title="SORT By ACCESS" />
          <CustomCheckBox isChecked={checkedState["Member Benefits"]} onChange={() => handleCheckboxChange("Member Benefits")} title="Member Benefits" />
          <CustomSideTitle title="SORT By START TIME" />
          <DualRangeSlider />
          <CustomSideTitle title="Role" />
          <CustomCheckBox isChecked={checkedState["Student"]} onChange={() => handleCheckboxChange("Student")} title="Student" />
          <CustomCheckBox isChecked={checkedState["Employee"]} onChange={() => handleCheckboxChange("Employee")} title="Employee" />
          <CustomCheckBox isChecked={checkedState["Manager"]} onChange={() => handleCheckboxChange("Manager")} title="Manager" />
          <CustomSideTitle title="TOPIC" />
          <CustomCheckBox isChecked={checkedState["Technology Application"]} onChange={() => handleCheckboxChange("Technology Application")} title="Technology Application" />
          <CustomCheckBox isChecked={checkedState["Collaboration"]} onChange={() => handleCheckboxChange("Collaboration")} title="Collaboration" />
          <CustomCheckBox isChecked={checkedState["Data and Analytics"]} onChange={() => handleCheckboxChange("Data and Analytics")} title="Data and Analytics" />
          <CustomSideTitle title="Levels" />
          <CustomCheckBox isChecked={checkedState["All Levels"]} onChange={() => handleCheckboxChange("All Levels")} title="All Levels" />
          <CustomCheckBox isChecked={checkedState["Beginner"]} onChange={() => handleCheckboxChange("Beginner")} title="Beginner" />
          <CustomCheckBox isChecked={checkedState["Intermediate"]} onChange={() => handleCheckboxChange("Intermediate")} title="Intermediate" />
          <CustomCheckBox isChecked={checkedState["Expert"]} onChange={() => handleCheckboxChange("Expert")} title="Expert" />
          <CustomSideTitle title="LOCATIONS" />
          <LocationFilter />
          <CustomSideTitle title="DATE" />
          <DataFilter />
          <CustomSideTitle title="Sort By Language" />
          <CustomCheckBox isChecked={checkedState["Arabic"]} onChange={() => handleCheckboxChange("Arabic")} title="Arabic" />
          <CustomCheckBox isChecked={checkedState["English"]} onChange={() => handleCheckboxChange("English")} title="English" />
          <CustomSideTitle title="Rating" />
          <CustomCheckBoxStar starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border']} title="5 STAR" />
          <CustomCheckBoxStar starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300']} title="4 STAR" />
          <CustomCheckBoxStar starColors={['text-custom-border', 'text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300']} title="3 STAR" />
          <CustomCheckBoxStar starColors={['text-custom-border', 'text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300']} title="2 STAR" />
          <CustomCheckBoxStar starColors={['text-custom-border', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} title="1 STAR" />
          <CustomCheckBoxStar starColors={['text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300', 'text-gray-300']} title="0 STAR" />
        </div>
      </div>
    </div>
  );
};

export default SortedSideBar;
