import React, { useState } from 'react';
import CustomCheckBox from '../coursesuser/checkbox';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/24/solid';

const IndustryFilter = () => {
  // State to manage visibility of additional checkboxes
  const [showMore, setShowMore] = useState(false);

  // State to track the checked state of each checkbox
  const [checkboxState, setCheckboxState] = useState({
    'Media': false,
    'Oil & Gas': false,
    'Finance': false,
    'HealthCare': false,
    'Oil': false,
    'Gas': false,
    'Technolog': false,
    'Learning': false,
    // You can add more categories if needed
  });

  // List of industries
  const Industrys = [
    'Media ', 'Oil', 'Finance', 'HealthCare',
    'Gas', 'Technolog', 'Learning',
     'Oil & Gas',
  ];

  // Toggle checkbox state
  const handleCheckboxChange = (industry) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [industry]: !prevState[industry], // Toggle the checkbox state
    }));
  };

  return (
    <div>
      {/* Displaying the first 3 checkboxes */}
      {Industrys.slice(0, 3).map((industry, index) => (
        <CustomCheckBox
          key={index}
          title={industry}
          isChecked={checkboxState[industry]} // Pass the state of the checkbox
          onChange={() => handleCheckboxChange(industry)} // Toggle checkbox state on change
        />
      ))}

      {/* Displaying remaining checkboxes when "Show More" is toggled */}
      {showMore &&
        Industrys.slice(3).map((industry, index) => (
          <CustomCheckBox
            key={index + 3}
            title={industry}
            isChecked={checkboxState[industry]} // Pass the state of the checkbox
            onChange={() => handleCheckboxChange(industry)} // Toggle checkbox state on change
          />
        ))}

      {/* Show More / Show Less Button */}
      <div className="flex justify-between items-center mt-4">
        <button
          className="text-custom-border font-bold flex items-center space-x-2"
          onClick={() => setShowMore(!showMore)}
        >
          <span>{showMore ? 'Show Less' : 'Show More'}</span>
          <div className="flex justify-between items-center">
            {showMore ? (
              <ChevronUpIcon className="h-5 w-5" />
            ) : (
              <ChevronDownIcon className="h-5 w-5" />
            )}
          </div>
        </button>
      </div>
    </div>
  );
};

export default IndustryFilter;
