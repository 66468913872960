import React from 'react';
import {TableAttemptQuizes} from './myattemptstable';
const MyAttempts =()=>{
    return (
<div className='mt-5'>
   <div className='text-2xl font-bold text-big-text-color mb-10'>
    My Quiz Attempts 
    </div>   
    <hr className='w-300'>
    </hr>
    <div className='w-[1000px]'>
  <TableAttemptQuizes/>
    </div>

</div>
    )
}
export default MyAttempts;