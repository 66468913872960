import React from 'react';
import PropTypes from 'prop-types';

const CustomImage = ({ src, alt }) => {
  return (
    <div className="w-40 h-40  overflow-hidden rounded-md bg-white lg:aspect-none group-hover:opacity-75  ">
      <img
        alt={alt}
        src={src}
        className="h-full w-full object-cover object-center"
      />
    </div>
  );
};

CustomImage.propTypes = {
  src: PropTypes.string.isRequired,
  alt: PropTypes.string,
};

CustomImage.defaultProps = {
  alt: '',
};

export default CustomImage;
