 import React from 'react';

 const CustomInput =({title,Label})=>{
    return (
        <div className=" pr-2 m-2">
              <h3 className='text-lg font-bold text-big-text-color '>
{Label}
     </h3>
        <input
          id="first-name"
          type="text" 
          placeholder={title}
          className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
        />
      </div>
    )
 }

 export default CustomInput;