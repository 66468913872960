import React, { useState, useEffect, useRef } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivCourseInside from '../../components/users/coursesuser/customdivcourseinside';
import SideBarCourseInside from '../../components/users/coursesuser/sidebarcourseinside';
import ThreeButtonsInRow from '../../components/users/coursesuser/threebuttonsinrow';
import AboutCourse from '../../components/users/coursesuser/aboutcourse';
import InstructorSection from '../../components/users/coursesuser/instructorsection';
import ReviewsInsideCourse from '../../components/users/coursesuser/reviewsinsidecours';
import MakeAReview from '../../components/users/coursesuser/makereview';
import CustomThingsToDo from '../../components/users/coursesuser/customthingstodo';
import CustomContactInsideCourse from '../../components/users/coursesuser/customcontactinsidecourse';
import CustomButton from '../../components/users/sharedcomponent/custombutton';
import ScrollInROWCourseList from '../../components/users/coursesuser/scrollinrowcourselist';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import Participants from '../../components/users/eventsuser/participants';
import MapEvent from '../../components/users/eventsuser/mapevent';
import EventButtons from '../../components/users/eventsuser/eventbuttons';
import BlogButtons from '../../components/users/bloguser/blogbuttons';
import ResourcsSection from '../../components/users/bloguser/resourcessection';
import img from '../../asset/images/yellorighticon.png';
import { useNavigate } from 'react-router-dom';
import ListInsideBlog  from '../../components/users/bloguser/listblogsinside';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import SideBarBlogInsideMobile  from '../../components/users/bloguser/customInsideBlogMobile';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import ListInsideBlogMobile from '../../components/users/bloguser/listInsideBlogMobile';
const BlogInside = () => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/allblogs');
  };
  const [isNavBarVisible, setIsNavBarVisible] = useState(false);
  const [activeSection, setActiveSection] = useState('Information');
  const [isThreeButtonsVisible, setIsThreeButtonsVisible] = useState(false);

  const aboutCourseRef = useRef(null);
  const participantsRef = useRef(null);
  const speakerRef = useRef(null);
  const reviewsRef = useRef(null);
  const mapRef = useRef(null);
  const handleButtonClick = (sectionId) => {
    const section = document.getElementById(sectionId);
    if (section) {
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
      setActiveSection(sectionId); // Update the active section
    }
  };

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollTop = window.scrollY;

      // Update navbar visibility
      setIsNavBarVisible(currentScrollTop > 100);

      // Update active section based on scroll position
      const sections = ['Information', 'Resources', 'Author', 'Reviews',];
      sections.forEach(sectionId => {
        const element = document.getElementById(sectionId);
        if (element) {
          const { offsetTop, clientHeight } = element;
          if (currentScrollTop >= offsetTop && currentScrollTop < offsetTop + clientHeight) {
            setActiveSection(sectionId);
          }
        }
      });

      // Handle visibility of ThreeButtonsInRow
      if (aboutCourseRef.current) {
        const courseContentTop = aboutCourseRef.current.offsetTop;
        setIsThreeButtonsVisible(currentScrollTop >= courseContentTop);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="relative">
      <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
        <HeadUser />
        <HeaderHomeUse />
        <CustomDivider />
        <div className='flex justify-center items-center hidden-mobile'>
          <CustomStaticFlyoutMenu  />
        </div>
      </div>
      <CustomDivider />
      <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
    <HeaderHomeUserMobile />
</div>

        <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
          <CustomNavBarWithFlayout />
        </div>
      <div className='bg-gray-100 z-0'>
        <CustomDivCourseInside
        navTitle='Blogs'
        navTitleTwo='Grow Your Business'
        title='GROW YOU BUSINESS'
        onClick={handleClick}
        />
        <div className='mobile-only'>
        <SideBarBlogInsideMobile />
        </div>
          <div className="flex  
        xxxs:m-2 xxs:m-2 xs:m-2 sm:m-10 lg:m-10 xl:m-10 1xl:m-10 11xl:m-10 2xl:m-10 3xl:m-10
        lg:px-10 xl:px-16 1xl:px-16 11xl:px-16 2xl:px-16 3xl:px-20  ">
          <div className="flex-grow ">
            {isThreeButtonsVisible && (
              <div className={`transition-transform duration-200 ${isThreeButtonsVisible ? 'fixed top-[80px] z-40' : 'relative'}`}>
                <BlogButtons activeSection={activeSection} 
                 onButtonClick={handleButtonClick} 
                />
              </div>
            )}
                  <BlogButtons activeSection={activeSection} 
                   onButtonClick={handleButtonClick} 
                  />
            <div id="Information" ref={aboutCourseRef}>
              <AboutCourse />
            </div>
          <div id='Resources' ref={participantsRef} >
          <ResourcsSection/>
          </div>
          <div className="xxxs:w-[350px] xxs:w-[350px] xs:w-[380px]
        sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px] 
              ">
          <div
                id='Author' ref={speakerRef} className='flex flex-col items-start justify-start mt-5 bg-white p-5'>
                <h3 className="xxxs:text-xl xxs:text-xl xs:text-xl sm:text-3xl lg:text-3xl xl:text-3xl 1xl:text-3xl 11xl:text-3xl 2xl:text-3xl 3xl:text-3xl font-bold text-big-text-color">
                Author</h3>
                <div className='shadow-lg mt-3'>
               <InstructorSection />
               </div>
              </div>
            </div>
            <div id='Reviews' ref={reviewsRef}>
              <ReviewsInsideCourse />
            </div>
            <MakeAReview />
            <CustomContactInsideCourse />
          </div>
          <div className=' relative top-[-350px] w-[400px] mb-10 mt-10
           sm:ml-10 lg:ml-10 xl:ml-20 1xl:ml-20 11xl:ml-20 2xl:ml-20 3xl:ml-20 pl-3 hidden-mobile '>
            <div className='sticky top-[80px] '> {/* Adjust top value to match height of CustomNavBarWithFlayout */}
              <SideBarCourseInside />
            </div>
          </div>
        </div>
      <div className='hidden-mobile'>
      <ListInsideBlog className="m-0 p-0" />
      </div>
      <div className='mobile-only'>
      <ListInsideBlogMobile/>
      </div>

      </div>
      <FooterUser />
    </div>
  );
};

export default BlogInside;
