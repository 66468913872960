import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import IndustryFilter from '../eventsuser/industryfilter';

const SortedSideBarMemberShip = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Default collapsed

  // State to manage the checked status of checkboxes
  const [checkedState, setCheckedState] = useState({
    Student: false,
    Employee: false,
    Manager: false,
    '1 Month': false,
    '5 Month': false,
    '1 Year': false,
    '2 Year': false,
    '5 Year': false,
    '10 Year': false,
  });

  const handleCollapseToggle = () => {
    setIsCollapsed((prev) => !prev);
  };

  // Handle checkbox state change
  const handleCheckboxChange = (title) => {
    setCheckedState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the checked state for this checkbox
    }));
  };

  return (
    <div
      className={`lg:w-[300px] xl:w-[300px] 2xl:w-[300px] border border-custom-border p-4 rounded-lg bg-white
         sm:mr-10 lg:mr-10 xl:mr-10 1xl:mr-10 11xl:mr-10 2xl:mr-10 3xl:mr-10 
         transition-all duration-300 ${isCollapsed ? 'sticky top-[80px] z-30' : 'relative'}`}
    >
      <div className={`transition-all ${isCollapsed ? 'opacity-100 ' : 'opacity-100 max-h-[1500px]'}`}>
        {/* Default content visible when collapsed */}
        <div className={`transition-all ${isCollapsed ? 'block' : 'hidden'}`}>
          <CustomSideTitle title="INDUSTRY" />
          <IndustryFilter />
          <CustomSideTitle title="Role" />
          <CustomCheckBox
            title="Student"
            isChecked={checkedState.Student}
            onChange={() => handleCheckboxChange('Student')}
          />
          <CustomCheckBox
            title="Employee"
            isChecked={checkedState.Employee}
            onChange={() => handleCheckboxChange('Employee')}
          />
          <CustomCheckBox
            title="Manager"
            isChecked={checkedState.Manager}
            onChange={() => handleCheckboxChange('Manager')}
          />
          <CustomSideTitle title="DURATION" />
          <CustomCheckBox
            title="1 Month"
            isChecked={checkedState['1 Month']}
            onChange={() => handleCheckboxChange('1 Month')}
          />
          <CustomCheckBox
            title="5 Month"
            isChecked={checkedState['5 Month']}
            onChange={() => handleCheckboxChange('5 Month')}
          />
          <CustomCheckBox
            title="1 Year"
            isChecked={checkedState['1 Year']}
            onChange={() => handleCheckboxChange('1 Year')}
          />
          <CustomCheckBox
            title="2 Year"
            isChecked={checkedState['2 Year']}
            onChange={() => handleCheckboxChange('2 Year')}
          />
          <CustomCheckBox
            title="5 Year"
            isChecked={checkedState['5 Year']}
            onChange={() => handleCheckboxChange('5 Year')}
          />
          <CustomCheckBox
            title="10 Year"
            isChecked={checkedState['10 Year']}
            onChange={() => handleCheckboxChange('10 Year')}
          />
        </div>
      </div>
    </div>
  );
};

export default SortedSideBarMemberShip;
