
import React , { useState } from 'react';
import { HomeIcon,
  UserIcon,
  BookOpenIcon,
  BookmarkIcon ,
  StarIcon,
  ViewColumnsIcon,
  UsersIcon,
  ArrowTrendingUpIcon,

  ExclamationCircleIcon,
    DocumentDuplicateIcon,
    ComputerDesktopIcon,
    SpeakerWaveIcon,
    ChatBubbleLeftIcon,
    Bars3Icon,
    Cog8ToothIcon,
    ArrowLeftEndOnRectangleIcon
} from '@heroicons/react/24/outline'; 
import DashboardCard  from './carddashboard';
import ListofCoursesAdmin from './listofcoursesadmin';
import ListBooksAdmin from './listofbooksAdmin';
import ListInstructorAdmin from './listinstructorsadmin';
import ListCompaniesAdmin from './listcompaniesadmin';
import ListOfCourseInstructorAdmin from './listofcourseinstructor';
import ListOfAdminCourse from './listofadmincourses';
import MyAttempts from '../instructor/myattempts';
import QuizAttemptsCourseAdmin from './quizattemptscourseadmin';
import QuizAttemptsStudentsAdmin from './attemptsstudent';
import AnnouncementsView from '../instructor/announcemnetview';
import  AssignmentsView  from '../instructor/assignmentsview';
import ListOfCompaniesToAddProductAdmin from './addproduct';
import AdminBooks from './adminbooks';
const SidebarAdmin = () => {
    const [selectedMenuItem, setSelectedMenuItem] = useState('dashboard'); 

    const handleMenuItemClick = (menuItem) => {
      setSelectedMenuItem(menuItem);
    };
  return (
   <div className='flex'>
     <div className=" border border-primary-color rounded-custom mt-5 mr-20 ml-20 mb-20 w-64 h-200 ">
      <div className="p-4  text-2xl font-semibold  text-big-text-color">
Welcome 
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'dashboard' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('dashboard')}>
      <HomeIcon className="h-5 w-5 mr-2"/>
        Dashboard</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Courses' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Courses')}>
            <ViewColumnsIcon className="h-5 w-5 mr-2"/>
         Courses</li>
         <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'book' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('book')}>
            <BookOpenIcon className="h-5 w-5 mr-2"/>
         Books</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Instructor' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Instructor')}>
            <UserIcon className="h-5 w-5 mr-2"/>
        Instructors</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div    cursor-pointer ${selectedMenuItem === 'Company' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Company')}>
            <StarIcon  className="h-5 w-5 mr-2"/>
        Comapanies</li>
     
      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Instructors
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'CoursesInstructor' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('CoursesInstructor')}>
      <ComputerDesktopIcon className="h-5 w-5 mr-2"/>
           Courses</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AnnouncementsInstructor' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('AnnouncementsInstructor')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            Announcements</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'MyAttemptsInstructor' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('MyAttemptsInstructor')}>
            <ChatBubbleLeftIcon className="h-5 w-5 mr-2"/>
            Quiz Attempts</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AssignmentsInstructor' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('AssignmentsInstructor')}>
            <Bars3Icon className="h-5 w-5 mr-2"/>
            Assignments</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Comapanies
</div>
      <ul className="p-2">
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'product' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('product')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            products</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Books
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AdminBooks' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('AdminBooks')}>
      <ComputerDesktopIcon className="h-5 w-5 mr-2"/>
           My Books</li>
      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Courses
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color  hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'CoursesCourses' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('CoursesCourses')}>
      <ComputerDesktopIcon className="h-5 w-5 mr-2"/>
            My Courses</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AnnouncementsCourse' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('AnnouncementsCourse')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            Announcements</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'MyAttemptsCourse' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('MyAttemptsCourse')}>
            <ChatBubbleLeftIcon className="h-5 w-5 mr-2"/>
            Quiz Attempts</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AssignmentsCourse' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('AssignmentsCourse')}>
            <Bars3Icon className="h-5 w-5 mr-2"/>
            Assignments</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Students
</div>
      <ul className="p-2">
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AnnouncementsStudents' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('AnnouncementsStudents')}>
            <SpeakerWaveIcon className="h-5 w-5 mr-2"/>
            Announcements</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'MyAttemptsStudents' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('MyAttemptsStudents')}>
            <ChatBubbleLeftIcon className="h-5 w-5 mr-2"/>
            Quiz Attempts</li>
            <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'AssignmentsStudents' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('AssignmentsStudents')}>
            <Bars3Icon className="h-5 w-5 mr-2"/>
            Assignments</li>

      </ul>
      <div className="p-4  text-xl font-semibold text-big-text-color">
      Admin
</div>
      <ul className="p-2">
      <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div   cursor-pointer ${selectedMenuItem === 'Settings' ? 'bg-custom-gradient-div  ' : ''}`} onClick={() => handleMenuItemClick('Settings')}>
      <Cog8ToothIcon className="h-5 w-5 mr-2"/>          
        Settings</li>
        <li className={`flex items-center px-4 py-2 text-big-text-color hover:bg-custom-gradient-div  cursor-pointer ${selectedMenuItem === 'Logout' ? 'bg-custom-gradient-div ' : ''}`} onClick={() => handleMenuItemClick('Logout')}>
        <ArrowLeftEndOnRectangleIcon className="h-5 w-5 mr-2"/>         
           Logout</li>
      </ul>
   
    </div>
    {selectedMenuItem === 'dashboard' && (
          <div>
         <div className='w-[1100px]'>
         <DashboardCard/>
          </div>
          </div>
        )}
     {
      selectedMenuItem === 'CoursesInstructor'&&(
        <div className='w-[1000px]'>
          <ListOfCourseInstructorAdmin/>
          </div>
      )
     }
     {
      selectedMenuItem === 'CoursesStudents'&&(
        <div className='w-[1000px]'>
          
          </div>
      )
     }
     {
      selectedMenuItem === 'CoursesCourses'&&(
        <div className='w-[1000px]'>
          <ListOfAdminCourse/>
          </div>
      )
     }
        {
          selectedMenuItem === 'book'&&(
            <div className='w-[1000px]'>
              <ListBooksAdmin/>
              </div>
          )
        }
        {
          selectedMenuItem === 'Company'&&(
            <div className='w-[1000px]'>
              <ListCompaniesAdmin/>
              </div>
          )
        }
        {
          selectedMenuItem ==='AssignmentsInstructor'&&(
            <div className='w-[1000px]'>
              <AssignmentsView/>
              </div>
          )
        }
         {
          selectedMenuItem ==='AssignmentsStudents'&&(
            <div className='w-[1000px]'>
              <AssignmentsView/>
              </div>
          )
        }
          {
          selectedMenuItem ==='AssignmentsCourse'&&(
            <div className='w-[1000px]'>
              <AssignmentsView/>
              </div>
          )
        }
          {selectedMenuItem === 'MyAttemptsStudents'&& (
           <div className='w-[1000px]'>
                <QuizAttemptsStudentsAdmin/>
                </div>
        )}
          {selectedMenuItem === 'MyAttemptsCourse'&& (
           <div className='w-[1000px]'>
                <QuizAttemptsCourseAdmin/>
                </div>
        )}
           {selectedMenuItem === 'MyAttemptsInstructor'&& (
           <div className='w-[1000px]'>
                <MyAttempts/>
                </div>
        )}
          {
          selectedMenuItem === 'AnnouncementsStudents'&& (
            <div className='w-[1000px]'>
              <AnnouncementsView/>
              </div>
          )
        }
          {
          selectedMenuItem === 'AnnouncementsCourse'&& (
            <div className='w-[1000px]'>
              <AnnouncementsView/>
              </div>
          )
        }
          {
          selectedMenuItem === 'AnnouncementsInstructor'&& (
            <div className='w-[1000px]'>
              <AnnouncementsView/>
              </div>
          )
        }
        {
          selectedMenuItem === 'Instructor' &&(
            <div className='w-[1000px]'>
              <ListInstructorAdmin/>
              </div>
          )
        }
          {selectedMenuItem === 'profile' && (
          <div>
            {/* <MyProfileDescriptionCard/> */}
          </div>
        )}
        {selectedMenuItem === 'Wishlist'&&(
             <div className='w-[1000px]'>
                {/* <ListOfWishListCourses/> */}
                </div>
        )}
       
        {selectedMenuItem === 'Reviews' && (
            <div>
                {/* <ReviewAdmin/> */}
            </div>
        )}
        {selectedMenuItem === 'Attempts'&& (
           <div className='w-[1000px]'>
                {/* <MyAttempts/> */}
                </div>
        )}
          {selectedMenuItem === 'Enrolled'&& (
            <div className='w-[1000px]'>
                {/* <ListOfMyCourses/> */}
                </div>
        )}
        {selectedMenuItem === 'History'&&(
            <div>
               {/* <HistoryOrders/> */}
                </div>
        )
        }
        {
          selectedMenuItem === 'Courses' && (
            <div>
               <div className='w-[1000px]'>
                <ListofCoursesAdmin/>
                </div>
              </div>
          )
        }
        {
          selectedMenuItem === 'Announcements'&& (
            <div className='w-[1000px]'>
              {/* <AnnouncementsView/> */}
              </div>
          )
        }
      {
        selectedMenuItem === 'product'&&(
          <div className='w-[1000px]'>
            <ListOfCompaniesToAddProductAdmin/>
            </div>
        )
      }
        {
          selectedMenuItem === 'AdminBooks'&&(
            <div className='w-[1000px]'>
              <AdminBooks/>
              </div>
          )
        }

   </div>
  );
};

export default SidebarAdmin;