import React from "react";
import image from '../../../asset/images/Rectangle 42337.png';
import { MapPinIcon,PhoneIcon,EnvelopeIcon } from '@heroicons/react/24/outline';
import CustomData from './customdata';
const CustomStackContactUs = ()=>{

    return (
         <div className="flex flex-col ">
      {/* Gradient Background */}
      <div className='bg-custom-gradient-div h-80 text-white flex items-center justify-center'>
        Gradient Background
      </div>
      <div
        className="relative top-[-230px]  xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-28 lg:mx-28
         xl:mx-28 1xl:mx-28 11xl:mx-28 2xl:mx-28 3xl:mx-28 mt-10  bg-white flex flex-col 
          shadow-lg items-center justify-center rounded-lg "
      >
        <h3 className="text-2xl font-bold mb-2 mt-10 text-big-text-color ">
        CONTACT US
        </h3>
        <h3 className="text-lg text-gray-600 text-center">
        Course Leads Here.
        </h3>
        <img
          src={image}
          alt="Privacy Policy"
          className='w-full h-[400px] object-cover mt-20'
        />
           <div className='sm:flex lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 22xl:flex 3xl:flex
           xxxs:mx-2 xxs:mx-2 xs:mx-2
           sm:mx-10 lg:mx-10 xl:mx-10 1xl:mx-10 11xl:mx-10 2xl:mx-10 22xl:mx-10 3xl:mx-10 pb-5 mt-5
           '>
     <CustomData icon={PhoneIcon} title="Phone Number" >
                <p className='text-sm mt-2 text-gray-600'>01205193333</p>
                <p className='text-sm mt-2 text-gray-600'>01029999210</p>
                <p className='text-sm mt-2 text-gray-600'>+44 7418 351039</p>
            </CustomData>

            <CustomData icon={EnvelopeIcon} title="Our Email Address" >
                <p className='text-sm mt-2 text-gray-600'>info@workbrand.org</p>
            </CustomData>
            <CustomData icon={MapPinIcon} title="Our Location" >
                <p className='text-sm mt-2 text-center whitespace-normal text-gray-600'>72 Gameat Al-dewal Al-Arabia , mohandessien , giza , 24 el-shaheed mostafa riyad , Nasr city , cairo</p>
            </CustomData>
        </div> 
        </div>

      </div>
    )
}

export default CustomStackContactUs;