import React from 'react';
import CustomMarkTextLink from './custommarktextlink';

const  ResourcsSection =()=>{
   return (
    <div className='xxxs:w-[350px] xxs:w-[350px] xs:w-[380px] 
    sm:w-[600px] lg:w-[600px] xl:w-[700px] 1xl:w-[800px] 11xl:w-[760px] 2xl:w-[900px] 3xl:w-[1100px]   bg-white rounded-custom mt-5 p-2'>
      <h3 className='text-3xl font-bold text-big-text-color'>
      Resources
      </h3>
      <hr className="mb-2 mt-2 w-full " />
     <CustomMarkTextLink title='Katy Chandler on LinkedIn:' url='https://www.linkedin.com/feed/' />
     <CustomMarkTextLink title='DuraServ Website:' url='https://www.linkedin.com/feed/' />
     <CustomMarkTextLink title='DuraServ LinkedIn:' url='https://www.linkedin.com/feed/'/>
    </div>
   )

}

export default ResourcsSection;