import React from 'react';
import CustomInput from './custominput';
import SocialMediaAuth from './socialmediaauth';
import ImageLogin from '../../asset/images/Aboutheaderimage-1024x475.jpg';
import CustomLogo from './customlogo';
import CustomButtonAuth from './custombuttonauth';
import { useNavigate } from 'react-router-dom';

const RegistrationForm =()=>{
    const navigate = useNavigate();

    const handleClick = () => {
      navigate('/courseInside'); 
    };
   return(
  <div className='flex justify-center  bg-white sm:w-[1000px] lg:w-[1000px] xl:w-[1000px] 1xl:w-[1000px] 11xl:w-[1000px] 2xl:w-[1200px] 3xl:w-[1200px] p-4'>
   <div className='w-full mr-10'>
  <CustomLogo/>
  <CustomInput title= "Enter Your Full Name" Label='Full Name'/>
     <CustomInput title= "Enter Your Email" Label='Email'/>
      <CustomInput title= "Enter Your Password" Label='Password'/> 
      <CustomInput title= "Enter Your Confirm Password" Label=' Confirm Password'/>  
 
      <SocialMediaAuth/>
      <CustomButtonAuth  name='Registration'/>
      <div className='flex flex-col items-center'>
  <h3 className='text-xl text-big-text-color font-bold'>OR</h3>
  <h3 className='text-xl text-big-text-color'>You Already Have An Account</h3>
</div>
<CustomButtonAuth  name='Login'/>


   </div>
<img
src={ImageLogin}
alt={ImageLogin}
className='justify-center sm:w-[400px] lg:w-[400px] xl:w-[400px] 1xl:w-[400px] 11xl:w-[400px] 2xl:w-[550px] 3xl:w-[550px] sm:h-full lg:h-full xl:h-full 1xl:h-full 11xl:h-full 2xl:h-full 3xl:h-full'

/>  
  </div>

   )

}
export default RegistrationForm;