import React from "react";
import DropdownWithHint from './dropdowncourselist';

export default function LeadFormUser() {
  return (
    <div className="xxxs:mx-4 xxs:mx-4 xs:mx-4 sm:mx-28 lg:mx-28 xl:mx-28 1xl:mx-28 11xl:mx-28 2xl:mx-28 3xl:mx-28 block rounded-lg bg-white p-6 shadow-lg mb-20 p-6">
      <h3 className="text-2xl font-bold text-center mb-10 text-big-text-color">
      CONTACT FORM
      </h3>
      <form>
        {/* Label and Input for First Name and Last Name */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="first-name"
              type="text" 
              placeholder="Enter your first name"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="last-name"
              type="text"
              placeholder="Enter your last name"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
        </div>

        {/* Label and Input for Email and Phone */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="email"
              type="email"
              placeholder="Enter your email"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <input
              id="phone"
              type="tel"
              placeholder="Enter your phone number"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
        </div>

        {/* Label and Input for Nationality and Dropdown */}
        <div className="flex mb-6">
          <div className="w-1/2 pr-2">
            <input
              id="nationality"
              type="text"
              placeholder="Enter your nationality"
              className="w-full px-4 py-3 text-lg border border-gray-300 rounded-lg"
            />
          </div>
          <div className="w-1/2 pl-2">
            <DropdownWithHint />
          </div>
        </div>
        <div className="w-full  pr-2 mb-10"> 
  <input
    id="nationality"
    type="text"
    placeholder="Your Message..."
    className="w-full px-4 text-lg border border-gray-300 rounded-lg h-40 " 
  />
</div>


<div className="flex justify-center mb-10">
<button
  type="submit"
  className="block w-1/2 rounded-lg bg-primary-color px-8 py-3 text-base font-medium uppercase leading-normal text-white shadow-[0_4px_9px_-4px_#3b71ca] transition duration-150 ease-in-out hover:bg-primary-600 hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:bg-primary-600 focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] focus:outline-none focus:ring-0 active:bg-primary-700 active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.3),0_4px_18px_0_rgba(59,113,202,0.2)] dark:shadow-[0_4px_9px_-4px_rgba(59,113,202,0.5)] dark:hover:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:focus:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)] dark:active:shadow-[0_8px_9px_-4px_rgba(59,113,202,0.2),0_4px_18px_0_rgba(59,113,202,0.1)]"
>
Send Now
</button>
</div>
      </form>
    </div>
  );
}
