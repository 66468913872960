import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import image from '../../../asset/images/events.png';
import CustomIconAndText from '../sharedcomponent/customiconandtext';
import { BookmarkIcon,MapPinIcon,ClockIcon, ArrowRightIcon } from "@heroicons/react/24/outline";
import CustomTitleAndImageOnly from '../sharedcomponent/imageandtitleonly';
import img from '../../../asset/images/Icon.png';
import { useNavigate } from 'react-router-dom';
import CustomParagraph from "../sharedcomponent/customParagraph";
import BookmarkButton from '../sharedcomponent/customBookMark';
const products = [
  {
    id: 1,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 1,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 2,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
  {
    id: 3,
    name: 'Grow your business ',
    href: '#',
    imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
    imageAlt: "Front of men's Basic Tee in black.",
    price: '$35',
    color: 'Black',
  },
];

export default function AllEventsGrid() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate('/eventInside');
  };
  return (
    <div className="flex w-full mx-auto xxxs:px-0 xxs:px-0 xs:px-0 
    sm:px-6 lg:px-8 xl:px-2 1xl:px-2  11xl:px-2  2xl:px-2 22xl:px-2 3xl:px-2">
    <div className="w-full mx-auto">
      <div className="grid grid-cols-1 gap-x-8 gap-y-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 
      11xl:grid-cols-3 1xl:grid-cols-3 2xl:grid-cols-3 3xl:grid-cols-4">
         {products.map((product) => (
 <div key={product.id} 
 className="group relative 
xxxs:w-full xxs:w-full xs:w-full sm:w-full 
            lg:w-[240px] xl:w-[240px] 11xl:w-[250px] 1xl:w-[260px] 2xl:w-[310px]
             22xl:w-[350px]
              3xl:w-[300px] lg:mx-0 xl:mx-0 2xl:mx-10 xxxs:2xl:mx-0 xxs:mx-0 xs:mx-0 sm:mx-0  md:mx-0 mid:mx-0
              p-2
  bg-white rounded-lg shadow-lg overflow-hidden transition-transform duration-300 transform hover:scale-105">
       <div className="relative justify-center aspect-h-1 aspect-w-1 w-full
        overflow-hidden rounded-md bg-white lg:aspect-none group-hover:opacity-75 lg:h-40">
  <img
    alt={image}
    src={image}
    className="absolute inset-0 h-[150px] w-full object-cover object-center p-1"
  />
</div>

          <div className="flex justify-between mt-1 mr-2 ml-2">
          <h2 
  className="lg:text-lg xl:text-lg 2xl:text-2xl text-big-text-color font-semibold cursor-pointer" 
  onClick={handleClick}
>
  {product.name}
</h2>

          <BookmarkButton/>
          </div>
         <div className="flex ml-1">
         <CustomIconAndText icon={<MapPinIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3 text-gray-500" />}title='Cairo, Egypt'/>
         <CustomIconAndText icon={<ClockIcon className="sm:h-3 sm:w-3 lg:h-3 lg:w-3
                   xl:h-3 xl:w-3 1xl:h-3 1xl:w-3 11xl:h-3 11xl:w-3 2xl:h-5 2xl:w-5 22xl:h-5 22xl:w-5 
                    3xl:h-3 3xl:w-3 text-gray-500" />} title='16 Nov, 2024'/>
         </div>
       <div className="mr-2 ml-2">
       <CustomParagraph
            title='Research your target market and understand their needs and preferences.'
            />
         <CustomTitleAndImageOnly
            imageSrc={image}
            title="Shams"
          />
         </div>
          <div className="flex justify-between mt-2 mr-2 ml-2 mb-2">
          <p className="text-lg font-medium text-big-text-color">{product.price}</p>

<button 
onClick={handleClick}
className="mt-1 text-sm text-gray-500 border rounded-custom border-gray-400 bg-transparent px-2 py-1 flex items-center justify-center">
See Details
<img
    src={img}
    alt="Course Icon"
    className="ml-2 justify-center items-center"
  />
</button>
            </div>
        
        </div>
          ))}
        </div>
      </div>
    </div>
  );
}
