import React, { useState } from 'react';

const SortByDropdown = ({ onSort }) => {
  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (e) => {
    setSelectedOption(e.target.value);
    onSort(e.target.value); // Trigger the sorting action on selection
  };

  return (
    <div className="flex w-400 h-8 items-center 
    justify-center border border-white rounded-custom ">
      <select
        value={selectedOption}
        onChange={handleChange}
        className="w-full max-w-md p-2 placeholder-white text-white bg-transparent
         rounded-custom focus:outline-none"
      >
        <option value="" disabled className="bg-white text-black">
          Sort By
        </option>
        <option value="name" className="bg-white text-black">
          Name
        </option>
        <option value="date" className="bg-white text-black">
          Date
        </option>
        <option value="rating" className="bg-white text-black">
          Rating
        </option>
        <option value="price" className="bg-white text-black">
          Price
        </option>
      </select>
    </div>
  );
};

export default SortByDropdown;
