import React, { useState } from 'react';
import CustomSideTitle from '../coursesuser/customsidetitle';
import CustomCheckBox from '../coursesuser/checkbox';
import CustomCheckBoxStar from '../coursesuser/checkboxreview';
import CategoryFilter from '../shopuser/categoryfilter';
import IndustryFilter from '../eventsuser/industryfilter';
import DataFilter from '../coursesuser/datafilteration';
import CollapsAll from '../coursesuser/collapsall';

const SortedSideBarResourses = () => {
  const [isCollapsed, setIsCollapsed] = useState(true); // Default collapsed
  const handleCollapseToggle = () => setIsCollapsed((prev) => !prev);

  // Checkbox states
  const [checkboxState, setCheckboxState] = useState({
    'Member Benefits': false,
    'Paper Back': false,
    'Pdf': false,
    Arabic: false,
    English: false,
  });

  // Star rating states
  const [starState, setStarState] = useState({
    '5 STAR': false,
    '4 STAR': false,
    '3 STAR': false,
    '2 STAR': false,
    '1 STAR': false,
    '0 STAR': false,
  });

  const handleCheckboxChange = (title) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the checked state
    }));
  };

  const handleStarCheckboxChange = (title) => {
    setStarState((prevState) => ({
      ...prevState,
      [title]: !prevState[title], // Toggle the checked state for star ratings
    }));
  };

  // Star colors based on ratings
  const starColorsFive = [
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
  ];
  const starColorsFour = [
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
    'text-gray-300',
  ];
  const starColorsThree = [
    'text-custom-border',
    'text-custom-border',
    'text-custom-border',
    'text-gray-300',
    'text-gray-300',
  ];
  const starColorsTwo = [
    'text-custom-border',
    'text-custom-border',
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
  ];
  const starColorsOne = [
    'text-custom-border',
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
  ];
  const starColorsZero = [
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
    'text-gray-300',
  ];

  return (
    <div
      className={`lg:w-[300px] xl:w-[300px] 2xl:w-[300px] border border-custom-border p-4 rounded-lg bg-white
         sm:mr-10 lg:mr-10 xl:mr-10 1xl:mr-10 11xl:mr-10 2xl:mr-10 3xl:mr-10 
         transition-all duration-300 ${isCollapsed ? 'sticky top-[80px] z-30' : 'relative'}`}
    >
      <div className={`transition-all ${isCollapsed ? 'opacity-100 ' : 'opacity-100 max-h-[1500px]'}`}>
        {/* Default content visible when collapsed */}
        <div className={`transition-all ${isCollapsed ? 'block' : 'hidden'}`}>
          <CustomSideTitle title="ACCESS" />
          <CustomCheckBox
            title="Member Benefits"
            isChecked={checkboxState['Member Benefits']}
            onChange={() => handleCheckboxChange('Member Benefits')}
          />
          <CustomSideTitle title="CATEGORIES" />
          <CategoryFilter />
          <CustomSideTitle title="INDUSTRY" />
          <IndustryFilter />
          <CollapsAll onClick={handleCollapseToggle} />
        </div>

        {/* Full content visible when expanded */}
        <div className={`transition-all ${isCollapsed ? 'hidden' : 'block'}`}>
          <CustomSideTitle title="ACCESS" />
          <CustomCheckBox
            title="Member Benefits"
            isChecked={checkboxState['Member Benefits']}
            onChange={() => handleCheckboxChange('Member Benefits')}
          />
          <CustomSideTitle title="CATEGORIES" />
          <CategoryFilter />
          <CustomSideTitle title="INDUSTRY" />
          <IndustryFilter />
          <CollapsAll onClick={handleCollapseToggle} />
          <CustomSideTitle title="Format" />
          <CustomCheckBox
            title="Paper Back"
            isChecked={checkboxState['Paper Back']}
            onChange={() => handleCheckboxChange('Paper Back')}
          />
          <CustomCheckBox
            title="Pdf"
            isChecked={checkboxState['Pdf']}
            onChange={() => handleCheckboxChange('Pdf')}
          />
          <CustomSideTitle title="DATE" />
          <DataFilter />
          <CustomSideTitle title="Language" />
          <CustomCheckBox
            title="Arabic"
            isChecked={checkboxState['Arabic']}
            onChange={() => handleCheckboxChange('Arabic')}
          />
          <CustomCheckBox
            title="English"
            isChecked={checkboxState['English']}
            onChange={() => handleCheckboxChange('English')}
          />
          <CustomSideTitle title="Rating" />
          <CustomCheckBoxStar
            starColors={starColorsFive}
            title="5 STAR"
            isChecked={starState['5 STAR']}
            onChange={() => handleStarCheckboxChange('5 STAR')}
          />
          <CustomCheckBoxStar
            starColors={starColorsFour}
            title="4 STAR"
            isChecked={starState['4 STAR']}
            onChange={() => handleStarCheckboxChange('4 STAR')}
          />
          <CustomCheckBoxStar
            starColors={starColorsThree}
            title="3 STAR"
            isChecked={starState['3 STAR']}
            onChange={() => handleStarCheckboxChange('3 STAR')}
          />
          <CustomCheckBoxStar
            starColors={starColorsTwo}
            title="2 STAR"
            isChecked={starState['2 STAR']}
            onChange={() => handleStarCheckboxChange('2 STAR')}
          />
          <CustomCheckBoxStar
            starColors={starColorsOne}
            title="1 STAR"
            isChecked={starState['1 STAR']}
            onChange={() => handleStarCheckboxChange('1 STAR')}
          />
          <CustomCheckBoxStar
            starColors={starColorsZero}
            title="0 STAR"
            isChecked={starState['0 STAR']}
            onChange={() => handleStarCheckboxChange('0 STAR')}
          />
        </div>
      </div>
    </div>
  );
};

export default SortedSideBarResourses;
