import React from 'react';
import { FaBeer } from 'react-icons/fa'; // Ensure this import is correct

const CustomSectionDashBoard = ({
  title,
  subTitle,
  icon: Icon,
  iconColor = 'bg-blue-500',
  backgroundColor = 'bg-gray-200',
  titleColor = 'text-black',
  subTitleColor = 'text-gray-600',
}) => {
  return (
    <div className={`flex flex-col items-center p-14 ${backgroundColor} rounded-lg m-6`}>
      <div
        className={`w-48 h-48 ${iconColor} rounded-full flex items-center justify-center p-4`}
      >
        {Icon && <Icon className="w-16 h-16 text-white" />}
      </div>
      <h3 className={`text-2xl mt-2 ${titleColor}`}>
        {title}
      </h3>
      <h3 className={`text-lg ${subTitleColor}`}>
        {subTitle}
      </h3>
    </div>
  );
}

export default CustomSectionDashBoard;
