import React, { useEffect, useState, useRef } from 'react';
import CustomButton from '../sharedcomponent/custombutton';
import circle from '../../../asset/images/circle.png';
import CustomWhyUsCard from './whyuscustomcard';
import one from '../../../asset/images/online-learning 1 (1).png';
import two from '../../../asset/images/online-learning 1 (2).png';
import three from '../../../asset/images/online-learning 1 (3).png';
import four from '../../../asset/images/online-learning 1.png';
import CustomBigTitle from './custombigtitle';

const WhyUsSectionInHome = () => {
  const [startCounting, setStartCounting] = useState(false);
  const sectionRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setStartCounting(true); // Start counting when the section is in view
          observer.disconnect(); // Stop observing once we start counting
        }
      },
      { threshold: 0.1 } // Trigger when at least 10% of the section is visible
    );

    if (sectionRef.current) {
      observer.observe(sectionRef.current); // Observe the section
    }

    return () => {
      observer.disconnect(); // Cleanup on unmount
    };
  }, []);

  return (
    <div className="items-center justify-center  pb-20" ref={sectionRef}>
      <CustomButton name='Why Us' />
      <CustomBigTitle title='MASTERPIECES OF ACHIEVEMENT ARENT BY NUMBER OF WINS' />
      <div className='px-16'>
        <div className="flex items-center justify-center mr-40 ml-40 mt-10 px-2 hidden-mobile">
          <img src={circle} alt="circle" className="w-4 h-4" />
          <hr className="flex-grow border-t-2.5 border-custom-border " />
          <img src={circle} alt="circle" className="w-4 h-4" />
          <hr className="flex-grow border-t-2.5 border-gray-500" />
          <img src={circle} alt="circle" className="w-4 h-4" />
          <hr className="flex-grow border-t-2.5 border-gray-400" />
          <img src={circle} alt="circle" className="w-4 h-4" />
        </div>
        <div className="flex items-center justify-center mr-40 ml-40 px-4 hidden-mobile">
          <div className="inline-block h-[20px] min-h-[1em] w-0.5 self-stretch bg-gray-300 items-center"></div>
          <hr className="flex-grow border-t-2 border-transparent" />
          <div className="inline-block h-[20px] min-h-[1em] w-0.5 self-stretch bg-gray-300 items-center"></div>
          <hr className="flex-grow border-t-2 border-transparent" />
          <div className="inline-block h-[20px] min-h-[1em] w-0.5 self-stretch bg-gray-300 items-center"></div>
          <hr className="flex-grow border-t-2 border-transparent" />
          <div className="inline-block h-[20px] min-h-[1em] w-0.5 self-stretch bg-gray-300 items-center"></div>
        </div>
        <div className='flex items-center justify-center'>
  {/* Mobile version */}
  <div className='block sm:hidden'>
  <div className=' items-center justify-center'>
        <div className='flex '>
            
<div className='m-2'>
<CustomWhyUsCard image={four} title='500' subTitle='Partners & Count' startCounting={startCounting} />

  </div>  
         <div className='m-2'>
         <CustomWhyUsCard image={one} title='800' subTitle='Courses & Video' startCounting={startCounting} />

         </div>
        </div>
      <div className='flex'>
        <div className='m-2'>
          
      <CustomWhyUsCard image={two} title='1000' subTitle='Certified Students' startCounting={startCounting} />
        </div>
       <div className='m-2'>
       <CustomWhyUsCard image={three} title='100' subTitle='Registered Enrolls' startCounting={startCounting} />

       </div>
      </div>
    </div>
  </div>

  {/* Desktop version */}
  <div className='hidden sm:flex  lg:flex xl:flex 1xl:flex 11xl:flex 2xl:flex 3xl:flex'>
   <div className='m-0 xxxxs:m-1 xxxxxs:m-1 sm:m-1 '>
   <CustomWhyUsCard image={four} title='500' subTitle='Partners & Count' startCounting={startCounting} />
   </div>
   <div className='m-0 xxxxs:m-1 xxxxxs:m-1 sm:m-1 '>
    <CustomWhyUsCard image={one} title='800' subTitle='Courses & Video' startCounting={startCounting} />
    </div>
    <div className='m-0 xxxxs:m-1 xxxxxs:m-1 sm:m-1 '>
    <CustomWhyUsCard image={two} title='1000' subTitle='Certified Students' startCounting={startCounting} />
    </div>
    <div className='m-0 xxxxs:m-1 xxxxxs:m-1 sm:m-1 '>
    <CustomWhyUsCard image={three} title='100' subTitle='Registered Enrolls' startCounting={startCounting} />
    </div>
  </div>
</div>

      </div>
    </div>
  );
};

export default WhyUsSectionInHome;
