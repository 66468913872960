import React, { useState } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus'; // Check this import
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomDivAuth from '../../components/auth/customdivauth';
import LoginForm from '../../components/auth/loginForm';
import RegistrationForm from '../../components/auth/registrationForm';
const Registration = () => {
    return (
        <div>
            <div className='bg-gray-50 z-0'>
                <CustomDivAuth />
                <div className='relative sm:top-[-220px] lg:top-[-140px] mx-24 px-1 mt-10 mb-10 flex justify-center'>
                <RegistrationForm/>
                </div>
            </div>
        </div>
    );
};

export default Registration;