import React from "react";
import {UsersIcon ,TableCellsIcon} from "@heroicons/react/24/outline";
import StarReview from "./reviews";
import image from '../../asset/images/events.png';
const products = [
    {
      id: 1,
      name: 'react',
      href: '#',
      imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
      imageAlt: "Front of men's Basic Tee in black.",
      price: '$35',
      subname: '12 student',
      numberOfLessons : '12 lesson'
    },
    {
        id: 1,
        name: 'javascript',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
        numberOfLessons : '12 lesson'
      },
      {
        id: 1,
        name: 'Html',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
        numberOfLessons : '12 lesson'
      },
      {
        id: 1,
        name: 'Css',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
        numberOfLessons : '12 lesson'
      },
      {
        id: 1,
        name: 'Vue js',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
        numberOfLessons : '12 lesson'
      },
      {
        id: 1,
        name: 'Node js',
        href: '#',
        imageSrc: 'https://tailwindui.com/img/ecommerce-images/product-page-01-related-product-01.jpg',
        imageAlt: "Front of men's Basic Tee in black.",
        price: '$35',
        subname: '12 student',
        numberOfLessons : '12 lesson'
      },
  ]
  
  export default function ListOfEnrolledCourses() {
    return (
      <div className="bg-white shadow-lg shadow-black/5  ">
        <div className=" px-4 py-4 sm:px-4 sm:py-8  lg:px-4">
          <div className="mt-2 grid grid-cols-1 gap-x-4 gap-y-6 sm:grid-cols-2 lg:grid-cols-3 xl:gap-x-4">
            {products.map((product) => (
              <div key={product.id} className="group relative shadow-lg rounded-custom">
                <div className=" overflow-hidden rounded-md   group-hover:opacity-75 ">
                  <img
                    alt={image}
                    src={image}
                    className="object-cover object-center p-4"
                  />
                </div>
                <div className="mt-4 flex justify-between pr-4 pl-4 ">
                  <div>
<StarReview/>
                    <h3 className="text-lg text-gray-700">
                      <a href={product.href}>
                        <span aria-hidden="true" className="absolute inset-0 text-big-text-color font-bold" />
                        {product.name}
                      </a>
                    </h3>
                    <div className="flex">
                    <UsersIcon className="w-4 h-4 text-gray-500 mr-2"/>
                  <p className="text-sm text-gray-500">{product.subname}</p>
                    </div>
                    <div className="flex">
                    <TableCellsIcon className="w-4 h-4 text-gray-500 mr-2"/>
                  <p className="text-sm text-gray-500">{product.numberOfLessons}</p>
                    </div>
                  </div>
                  <p className="text-sm font-medium text-gray-900">{product.price}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }