import React from 'react';
import { CheckIcon } from '@heroicons/react/24/solid';
export default function CustomRightCheckBox({title}) {
  return (
      <div className="flex p-1">
  <div className="w-5 h-5 border border-custom-border rounded-custom p-1 flex items-center justify-center mr-1">
  <CheckIcon className='w-4 h-4 text-custom-border' />
</div>

        <label
          className="inline-block  hover:cursor-pointer text-sm"
          htmlFor="checkboxDefault">
          {title}
        </label>
      </div>

  );
}