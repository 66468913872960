import React, { useState, useRef, useEffect } from 'react';
import { ChevronDownIcon } from '@heroicons/react/24/solid'; // Adjust the import path based on your setup
import imageCourse from '../../../asset/images/coursebar.png';
import imageResources from '../../../asset/images/resoursesimage.png';
import imageBlog from '../../../asset/images/blog.png';
import imageEvent from '../../../asset/images/events.png';
import imageStore from '../../../asset/images/store.png';
import imageMembership from '../../../asset/images/membership.png';
import imageAboutAndContact from '../../../asset/images/aboutcontact.png';
import arrowIcon from '../../../asset/images/Iconarrow.png';

const menuData = {
  Courses: {
      image: imageCourse,
      name:'Courses',
      footerText: 'Explore our Courses',
      footerLinks: [
          { name: 'Approach To Learn', link: '#' },
          { name: 'Training Locations', link: '#' },
          { name: 'Registration Method', link: '#' },
          { name: 'Talk to Specialist', link: '#' },
          { name: 'FAQs', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'Find Course',
              subItems: [
                  { name: 'Course Home', link: '#' },
                  { name: 'Course Categories', link: '#' },
                  { name: 'Explore By Role', link: '#' },
                  { name: 'Explore By Levels', link: '#' },
                  { name: 'Search All Courses', link: '#' },
              ]
          },
          {
              id: 2,
              headline1: 'Delivery Methods',
              subItems: [
                  { name: 'Offline', link: '#' },
                  { name: 'Online', link: '#' },
                  { name: 'Face To Face', link: '#' },
                  { name: 'On Demand', link: '#' },
                  { name: 'Corporate Training', link: '#' }
              ]
          },
          {
              id: 3,
              headline1: 'Get Certified',
              subItems: [
                  { name: 'Certification Home', link: '#' },
                  { name: 'Why get Certification?', link: '#' },
                  { name: 'Certification Portal', link: '#' },
                  { name: 'Recertification', link: '#' },
              ]
          },
      ]
  },
  Resources: {
      image: imageResources,
      name:'Resources',
      footerText: 'Explore our Resources',
      footerLinks: [
          { name: 'Order information', link: '#' },
          { name: 'Rights & Permissions', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'Books',
              subItems: [
                  { name: 'Books Home', link: '#' },
                  { name: 'Browse All Books', link: '#' },
              ]
          },
          {
              id: 2,
              headline1: 'Research',
              subItems: [
                  { name: 'Research Home', link: '#' },
                  { name: 'Browse All Reports', link: '#' },
              ]
          },
          {
              id: 3,
              headline1: 'Booklet',
              subItems: [
                  { name: 'Booklet Home', link: '#' },
                  { name: 'Browse All Files', link: '#' },
              ]
          },
      ]
  },
  Store: {
      image: imageStore,
      name:'Store',
      footerText: 'Shop at our Store',
      footerLinks: [
          { name: 'Join Today', link: '#' },
          { name: 'Contact Us', link: '#' },
          { name: 'Downloads', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'Shop',
              subItems: [
                  { name: 'Store Home', link: '#' },
                  { name: 'Suppliers', link: '#' },
                  { name: 'See all Merchandise', link: '#' }
              ]
          },
          {
              id: 2,
              headline1: 'Store Resources',
              subItems: [
                  { name: 'Merchandise', link: '#' },
                  { name: 'Articles', link: '#' },
                  { name: 'Offers', link: '#' },
                  { name: 'Courses', link: '#' },
                  { name: 'Certifications', link: '#' },
                  { name: 'Membership', link: '#' },
                  { name: 'Webinar', link: '#' }
              ]
          },
      ]
  },
  Membership: {
      image: imageMembership,
      name:'Membership',
      footerText: 'Join our Membership',
      footerLinks: [
          { name: 'Refer Your Friend', link: '#' },
          { name: 'Support (Live)', link: '#' },
          { name: 'FAQs', link: '#' },
          { name: 'Training Locations', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'About Membership',
              subItems: [
                  { name: 'Convince Your Boss', link: '#' },
                  { name: 'Forum Membership', link: '#' },
                  { name: 'Membership Options', link: '#' },
                  { name: 'Join Today', link: '#' }
              ]
          },
          {
              id: 2,
              headline1: 'For Member',
              subItems: [
                  { name: 'Benefits Center', link: '#' },
                  { name: 'Member Toolkit', link: '#' },
                  { name: 'Renew Now', link: '#' }
              ]
          },
      ]
  },
  Events: {
      image: imageEvent,
      name:'Events',
      footerText: 'Upcoming Events',
      footerLinks: [
          { name: 'About Us', link: '#' },
          { name: 'Advertise With Us ', link: '#' },
          { name: 'Contact Us ', link: '#' },
          { name: 'Career Hub', link: '#' },

      ],
      sections: [
          {
              id: 1,
              headline1: 'Event Home',
              subItems: [
                  { name: 'Business Index Sentiments Event', link: '#' },
                  { name: 'Global Events', link: '#' },
                  { name: 'See All Events', link: '#' }
              ]
          },
          {
              id: 2,
              headline1: 'Opportunities',
              subItems: [
                  { name: 'Speaking Opportunities', link: '#' },
                  { name: 'Sponsorship Opportunities', link: '#' },
              ]
          },
      ]
  },
  Blog: {
      image: imageBlog,
      name:'Blogs',
      footerText: 'Read our Blog',
      footerLinks: [
          { name: 'Order information', link: '#' },
          { name: 'Rights & Permissions', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'Latest',
              subItems: [
                  { name: 'Podcasts', link: '#' },
                  { name: 'Blog Home', link: '#' },
                  { name: 'Videos', link: '#' },
                  { name: 'Articles', link: '#' },
                  { name: 'Webinars', link: '#' },
                  { name: 'Blogs', link: '#' },
                  { name: 'Conference', link: '#' },
                  { name: 'See all Blogs', link: '#' }
              ]
          },
          {
              id: 2,
              headline1: 'Topics',
              subItems: [
                  { name: 'Career Development', link: '#' },
                  { name: 'Coaching', link: '#' },
                  { name: 'Training & Facilities', link: '#' },
                  { name: 'All Topics', link: '#' }
              ]
          },
      ]
  },
  'About': {
      image: imageAboutAndContact,
      name:'About',
      footerText: 'About and Contact Us',
      footerLinks: [
          { name: 'Refer Your Friend', link: '#' },
          { name: 'Support (Live)', link: '#' },
          { name: 'FAQs', link: '#' },
          { name: 'Training Locations', link: '#' },
      ],
      sections: [
          {
              id: 1,
              headline1: 'About',
              subItems: [
                  { name: 'About Home', link: '#' },
                  { name: 'Board Of Directors', link: '#' },
                  { name: 'Awards', link: '#' },
                  { name: 'Press Room', link: '#' },
                  { name: 'Clients', link: '#' },
                  { name: 'Our Services', link: '#' }
              ]
          },
          {
              id: 2,
              headline1: 'Contact',
              subItems: [
                  { name: 'Reviews', link: '#' },
                  { name: 'Support (Live)', link: '#' },
                  { name: 'Submit a Request', link: '#' },
                  { name: 'Refer a Friend', link: '#' },
                  { name: 'Career Hub', link: '#' },
                  { name: 'Contact Us', link: '#' }
              ]
          },
      ]
  }
};


const FlyoutMenu = () => {
    const [openMenu, setOpenMenu] = useState(null);
    const menuRef = useRef(null);
  
    // Handle toggling the menu
    const toggleMenu = (menuName) => {
      setOpenMenu(openMenu === menuName ? null : menuName);
    };
  
    // Close the menu if clicked outside the menu container
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setOpenMenu(null);
      }
    };
  
    useEffect(() => {
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, []);
  
    // Open the menu when mouse enters
    const handleMouseEnter = (menuName) => {
      setOpenMenu(menuName);
    };
  
    // Close the menu when mouse leaves the flyout container
    const handleMouseLeave = () => {
      setOpenMenu(null);
    };
  
    return (
      <div className="relative" ref={menuRef}>
        <div className="flex justify-center gap-1 py-2">
          {Object.keys(menuData).map((menuName) => (
       <div>
          <button
              key={menuName}
              onClick={() => toggleMenu(menuName)}
              onMouseEnter={() => handleMouseEnter(menuName)}
              className={`flex items-center gap-1 
                lg:text-sm xl:text-sm 2xl:text-lg px-1 py-1 mr-2
                text-big-text-color font-semibold rounded focus:outline-none
                font-sans 
                ${openMenu === menuName ? 'bg-gray-100' : 'hover:bg-gray-100'}`}
            >
              {menuName}
              <img
                src={arrowIcon}
                alt={arrowIcon}
                className="w-4 h-2 items-center justify-center"
              />
            </button>
            <div
                            onClick={() => toggleMenu(menuName)}
                            className={`
                                w-[70px] h-[3px]
                                ${openMenu === menuName ? 'bg-primary-color' : 'hover:bg-primary-color'}
                            `}
                        ></div>

       </div>
          ))}
        </div>
  
        {openMenu && (
          <div 
            className="absolute top-13 left-0 w-full bg-white text-black shadow-lg z-50 flex flex-col rounded-custom"
            onMouseEnter={() => handleMouseEnter(openMenu)} // Keep open when hovering over the menu content
            onMouseLeave={handleMouseLeave} // Close the menu when mouse leaves the menu content
          >
            <div className="flex-grow flex">
            <div className="flex-shrink-0 px-4 pb-5">
                            
                            <h1 className='font-bold text-big-text-color text-2xl pb-2'>
                            {menuData[openMenu].name}
                            </h1>
                            <img
                                src={menuData[openMenu].image}
                                alt={openMenu}
                                className="w-60 h-full object-cover pb-5"
                            />
                        </div>
              <div className="flex-grow p-1">
                <div className="flex flex-col lg:flex-row">
                  {menuData[openMenu].sections.map((section) => (
                    <div key={section.id} className="flex-1 mb-4 lg:mb-0 ml-5">
                      <h1 className="xs:text-xs xxs:text-xs xxxs:text-xs
                        lg:text-sm xl:text-sm
                        1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg
                        font-bold mb-2 text-big-text-color">{section.headline1}</h1>
  
                      <ul>
                        {section.subItems.map((item, index) => (
                          <li key={index} className="xxxs:mb-2 xxs:mb-2 xs:mb-2
                            sm:mb-0 lg:mb-0 xl:mb-0 1xl:mb-0 11xl:mb-0 2xl:mb-0 22xl:mb-0
                            3xl:mb-0">
                            <a href={item.link} className="xs:text-xs xxs:text-xs
                              xxxs:text-xs
                              lg:text-sm xl:text-sm
                              1xl:text-lg 11xl:text-lg 2xl:text-lg 3xl:text-lg
                              block hover:underline text-gray-500">{item.name}</a>
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
              </div>
             
            </div>
            <div className="text-white text-center p-6 rounded-custom bg-custom-blue mt-3">
              <div className="flex text-center justify-center ">
                <h2 className="xxxs:text-xs xxs:text-xs xs:text-xs
                    sm:text-xs
                    lg:text-sm xl:text-sm
                    1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm
                    text-primary-color font-bold mr-5">
                  Quick Links:
                </h2>
                <div className="flex justify-center space-x-4">
                  {menuData[openMenu].footerLinks.map((link, index) => (
                    <a key={index} href={link.link} className="hover:underline
                      xxxs:text-xs xxs:text-xs xs:text-xs
                      sm:text-xs
                      lg:text-sm xl:text-sm
                      1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm
                      ">
                      {link.name}
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };
  
  export default FlyoutMenu;