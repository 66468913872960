import React from 'react';

const CustomButtonAuth = ({ name, onClick }) => {
  return (
    <div className='flex justify-center'>
      <a
        href="#"
        onClick={onClick} 
        className="rounded-custom bg-primary-color
        w-full
        my-6
        xxxs:py-1 xxs:py-1 xs:py-1
        sm:py-3 lg:py-3 xl:py-3 11xl:py-3 2xl:py-3 3xl:py-3 
        text-sm font-semibold text-white shadow-sm 
        hover:bg-custom-border focus-visible:outline 
        focus-visible:outline-2 focus-visible:outline-offset-2 
        focus-visible:outline-gray-900 text-center"
      >
        {name}
      </a>
    </div>
  );
};

export default CustomButtonAuth;
