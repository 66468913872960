import React,{useState} from 'react';
import TableReviewRecived from '../instructor/reviewtable';
const ReviewAdmin =()=>{
    const [selectedMenuItem, setSelectedMenuItem] = useState('Received'); 

    const handleMenuItemClick = (menuItem) => {
      setSelectedMenuItem(menuItem);
    };
    return (
        <div className="bg-white w-full max-w-screen-xl mt-5 mr-20 ">
          <div className='text-2xl font-bold text-big-text-color'>
            Reviews
          </div>
          <hr className="px-8 py-4">
          </hr>
        <div className="flex bg-white mb-5">
          <button
            className={` text-lg text-big-text-color font-bold hover:text-primary-color focus:outline-none ${
              selectedMenuItem === 'Enrolled' ? 'text-primary-color' : 'text-big-text-color'
            }`}
            onClick={() => handleMenuItemClick('Received')}
          >
            Received
          </button>
          <button
            className={`text-lg  ml-10 text-big-text-color font-bold hover:text-primary-color focus:outline-none ${
              selectedMenuItem === 'Active' ? 'text-primary-color' : 'text-big-text-color'
            }`}
            onClick={() => handleMenuItemClick('Given')}
          >
            Given
          </button>
       
        </div>
        <div>
          {selectedMenuItem === 'Received' && (
            <div>
              <TableReviewRecived />
            </div>
          )}
           {selectedMenuItem === 'Given' && (
            <div>
              <TableReviewRecived/>
            </div>
          )}
        
        </div>
      </div>
    )
}

export default ReviewAdmin;