import React from 'react';
import {TableAttemptQuizesStudents} from './attemptstablestudents';
const QuizAttemptsStudentsAdmin =()=>{
    return (
<div className='mt-5'>
   <div className='text-2xl font-bold text-big-text-color mb-10'>
    Quiz Attempts courses
    </div>   
    <hr className='w-300'>
    </hr>
    <div className='w-[1000px]'>
  <TableAttemptQuizesStudents/>
    </div>

</div>
    )
}
export default QuizAttemptsStudentsAdmin;