import React from 'react';
import CustomButton from '../admin/custombutton';
import {TableAnnouncement} from './tablelistannouncment';
const AnnouncementsViewCompany =()=>{

    return(
        <div className="bg-white shadow-lg shadow-black/5  ">
        <div className=" px-4 py-4 sm:px-6 sm:py-4  lg:px-8">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">
Announcements
</h2>
<hr className="px-8 py-4">
</hr>    
      <div className='flex justify-between bg-blue-100 p-4'>
        <div>
          <h2 className='font-bold text-2xl text-big-text-color '>
          Notify your all Products.
          </h2>
          <h2 className='font-lg text-big-text-color'>
          Create Announcement
          </h2>
        </div>
        <CustomButton name ='Add Announcement' />
      </div>
      <TableAnnouncement/>
    </div>
  </div>
    )
}
export default AnnouncementsViewCompany;