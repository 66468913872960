import React from 'react';
import img from '../../asset/images/BIS.png';

const CustomLogo = () => {
    return (
        <div className="flex justify-center items-center m-4">
            <img
                src={img}
                alt="Logo"
                className="sm:w-[200px] lg:w-[200px] xl:w-[200px] 2xl:w-[220px] 3xl:w-[220px] sm:h-[50px] lg:h-[50px] xl:h-[50px] 2xl:h-[70px] 3xl:h-[70px]"
            />
        </div>
    );
}

export default CustomLogo;
