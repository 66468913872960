import React, { useState, useEffect } from 'react';
import HeadUser from '../../components/users/sharedcomponent/headuser';
import HeaderHomeUse from '../../components/users/sharedcomponent/headerhomeuser';
import FlyoutMenu from '../../components/users/sharedcomponent/flayoutmenus';
import CustomDivider from '../../components/users/sharedcomponent/customdivider';
import ContactUsFormUser from '../../components/users/contactususer/formcontactus';
import mapImage from '../../asset/images/map.png';
import FooterUser from '../../components/users/sharedcomponent/footeruser';
import CustomNavBarWithFlayout from '../../components/users/sharedcomponent/customnavbarwithflayout';
import CustomStackContactUs from '../../components/users/contactususer/customstackcontactus';
import HeaderHomeUserMobile from '../../components/users/sharedcomponent/customMobileHead';
import CustomStaticFlyoutMenu from '../../components/users/sharedcomponent/customFlayoutinStaticHeader';
import StackTerms from '../../components/users/termsandconditions/stackTerms';

const TermsAndConditions =()=>{
    const [isNavBarVisible, setIsNavBarVisible] = useState(false);
    const [scrollingDown, setScrollingDown] = useState(true); // Corrected line
  
    useEffect(() => {
      let lastScrollTop = 0;
  
      const handleScroll = () => {
        const currentScrollTop = window.scrollY;
  
        setScrollingDown(currentScrollTop > lastScrollTop);
        setIsNavBarVisible(currentScrollTop > 100); // Adjust threshold as needed
  
        lastScrollTop = currentScrollTop <= 0 ? 0 : currentScrollTop; // For Mobile or negative scrolling
      };
  
      window.addEventListener('scroll', handleScroll);
  
      return () => window.removeEventListener('scroll', handleScroll);
    }, []);
  
   return(
    <div className="relative">
    {/* This div will handle the padding-top adjustment for the fixed navbar */}
    <div className={`transition-all duration-300 ${isNavBarVisible ? 'pt-16' : 'pt-0'}`}>
      <HeadUser />
      <HeaderHomeUse />
      <CustomDivider />
      <div className='flex justify-center items-center hidden-mobile'>
        <CustomStaticFlyoutMenu />
      </div>
    </div>
    <CustomDivider />
    <div className={`fixed mobile-only top-0 left-0 w-full  bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <HeaderHomeUserMobile />
    </div>

    <div className={`fixed hidden-mobile top-0 left-0 w-full bg-white z-50 transition-transform duration-300 ${isNavBarVisible ? 'translate-y-0' : '-translate-y-full'}`}>
      <CustomNavBarWithFlayout />
    </div>
    <StackTerms/>
    <FooterUser />
   </div>
   )
}

export default TermsAndConditions;