// src/components/ShadowButton.js
import React from 'react';
import PropTypes from 'prop-types';

const CustomResourcesButton = ({ children, image, onClick }) => {
  return (
    <button 
      onClick={onClick} // Add the onClick handler here
      className="bg-primary-color text-white font-bold  px-2 rounded-custom
        text-base sm:text-sm md:text-base lg:text-sm
        xl:text-sm 1xl:text-sm 11xl:text-sm 2xl:text-sm 3xl:text-sm
        inline-flex items-center justify-center
        transition duration-300 ease-in-out transform hover:scale-105 
        focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-color z-0 
        whitespace-nowrap font-sans "
    >
      {children}
      {image && (
        <img
          src={image}
          alt="Button icon"
          className='w-2.5 h-2.5 ml-2' 
        />
      )}
    </button>
  );
};

// Define prop types for better validation
CustomResourcesButton.propTypes = {
  children: PropTypes.node.isRequired,
  image: PropTypes.string,
  onClick: PropTypes.func, // Add onClick to prop types
};

// Set default props
CustomResourcesButton.defaultProps = {
  image: null,
  onClick: () => {}, // Default to a no-op function
};

export default CustomResourcesButton;

