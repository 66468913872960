// BorderButton.js

import React from 'react';

const BorderButton = ({ onClick, children, className = '', ...props }) => {
  return (
    <button
      onClick={onClick}
      className={`sm:text-xs lg:text-sm border-2 border-custom-border
         text-custom-border bg-transparent hover:border-opacity-80
          hover:text-opacity-80 py-1 px-2 rounded-lg  whitespace-nowrap font-sans ${className}`}
      {...props}
    >
      {children}
    </button>
  );
};

export default BorderButton;

