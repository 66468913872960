import React,{useState} from 'react';
import TableReviewRecived from './reviewtable';
import ListOfEnrolledCourses from '../admin/listofenrolledcourses';
const ListOfMyCourses =()=>{
    const [selectedMenuItem, setSelectedMenuItem] = useState('Publish'); 

    const handleMenuItemClick = (menuItem) => {
      setSelectedMenuItem(menuItem);
    };
    return (
        <div className="bg-white w-full max-w-screen-xl mt-5 mr-20 ">
             <h2 className="text-3xl font-bold tracking-tight text-big-text-color px-4 mb-4">Courses</h2>
     <hr className="px-8 py-4">
     </hr>
        <div className="flex bg-white mb-5">
          <button
            className={` text-lg text-big-text-color font-bold hover:text-primary-color focus:outline-none ${
              selectedMenuItem === 'Enrolled' ? 'text-primary-color' : 'text-big-text-color'
            }`}
            onClick={() => handleMenuItemClick('Publish')}
          >
            Publish
          </button>
          <button
            className={` text-lg text-big-text-color ml-10  font-bold hover:text-primary-color focus:outline-none ${
              selectedMenuItem === 'Enrolled' ? 'text-primary-color' : 'text-big-text-color'
            }`}
            onClick={() => handleMenuItemClick('Pending')}
          >
            Pending
          </button>
          <button
            className={`text-lg  ml-10 text-big-text-color font-bold hover:text-primary-color focus:outline-none ${
              selectedMenuItem === 'Active' ? 'text-primary-color' : 'text-big-text-color'
            }`}
            onClick={() => handleMenuItemClick('Draft')}
          >
            Draft
          </button>
       
        </div>
        <div>
          {selectedMenuItem === 'Publish' && (
            <div>
              <ListOfEnrolledCourses />
            </div>
          )}
           {selectedMenuItem === 'Pending' && (
            <div>
              <ListOfEnrolledCourses/>
            </div>
          )}
           {selectedMenuItem === 'Draft' && (
            <div>
              <ListOfEnrolledCourses/>
            </div>
          )}
        </div>
      </div>
    )
}

export default ListOfMyCourses;