import React, { useState } from 'react';
import DualRangeSlider from './rangeslider';
import CustomSideTitle from './customsidetitle';
import CustomCheckBox from './checkbox';
import CustomCheckBoxStar from './checkboxreview';
import LocationFilter from './locationfilter';
import DataFilter from './datafilteration';

const CustomSortedForMobileCourses = () => {
  const [isExpanded, setIsExpanded] = useState(false); // For main filters toggle
  const [expandedSections, setExpandedSections] = useState({}); // For individual sections

  // Toggles the visibility of the entire filter panel
  const toggleExpand = () => {
    setIsExpanded((prev) => !prev);
  };

  // Toggles the visibility of specific sections
  const toggleSection = (section) => {
    setExpandedSections((prev) => ({
      ...prev,
      [section]: !prev[section],
    }));
  };

  return (
    <div className="w-full max-w-xs mx-auto  bg-white mt-2 rounded-custom justify-center">
      {/* Master Header */}
      <div
        className="flex justify-between items-center p-4 cursor-pointer border-b border-gray-300"
        onClick={toggleExpand}
      >
        <h3 className="text-lg font-semibold">Filters</h3>
        <span>{isExpanded ? '-' : '+'}</span>
      </div>

      {/* Expandable Content */}
      {isExpanded && (
        <div className="p-4 space-y-4">
          {/* SORT BY ACCESS */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('access')}
            >
              <CustomSideTitle title="SORT By ACCESS" />
              <span>{expandedSections.access ? '-' : '+'}</span>
            </div>
            {expandedSections.access && <CustomCheckBox title="Member Benefits" />}
          </div>

          {/* SORT BY START TIME */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('startTime')}
            >
              <CustomSideTitle title="SORT By START TIME" />
              <span>{expandedSections.startTime ? '-' : '+'}</span>
            </div>
            {expandedSections.startTime && <DualRangeSlider />}
          </div>

          {/* Role */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('role')}
            >
              <CustomSideTitle title="Role" />
              <span>{expandedSections.role ? '-' : '+'}</span>
            </div>
            {expandedSections.role && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="Student" />
                <CustomCheckBox title="Employee" />
                <CustomCheckBox title="Manager" />
              </div>
            )}
          </div>

          {/* Topic */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('topic')}
            >
              <CustomSideTitle title="TOPIC" />
              <span>{expandedSections.topic ? '-' : '+'}</span>
            </div>
            {expandedSections.topic && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="Technology Application" />
                <CustomCheckBox title="Collaboration" />
                <CustomCheckBox title="Data and Analytics" />
              </div>
            )}
          </div>

          {/* Levels */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('levels')}
            >
              <CustomSideTitle title="Levels" />
              <span>{expandedSections.levels ? '-' : '+'}</span>
            </div>
            {expandedSections.levels && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="All Levels" />
                <CustomCheckBox title="Beginner" />
                <CustomCheckBox title="Intermediate" />
                <CustomCheckBox title="Expert" />
              </div>
            )}
          </div>

          {/* Locations */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('locations')}
            >
              <CustomSideTitle title="LOCATIONS" />
              <span>{expandedSections.locations ? '-' : '+'}</span>
            </div>
            {expandedSections.locations && <LocationFilter />}
          </div>

          {/* Date */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('date')}
            >
              <CustomSideTitle title="DATE" />
              <span>{expandedSections.date ? '-' : '+'}</span>
            </div>
            {expandedSections.date && <DataFilter />}
          </div>

          {/* Sort By Language */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('language')}
            >
              <CustomSideTitle title="Sort By Language" />
              <span>{expandedSections.language ? '-' : '+'}</span>
            </div>
            {expandedSections.language && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBox title="Arabic" />
                <CustomCheckBox title="English" />
              </div>
            )}
          </div>

          {/* Rating */}
          <div>
            <div
              className="flex justify-between items-center cursor-pointer"
              onClick={() => toggleSection('rating')}
            >
              <CustomSideTitle title="Rating" />
              <span>{expandedSections.rating ? '-' : '+'}</span>
            </div>
            {expandedSections.rating && (
              <div className="flex flex-col space-y-2">
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                  ]}
                  title="5 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-gray-300',
                  ]}
                  title="4 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-custom-border',
                    'text-custom-border',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="3 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-custom-border',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="2 STAR"
                />
                <CustomCheckBoxStar
                  starColors={[
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                    'text-gray-300',
                  ]}
                  title="0 STAR"
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default CustomSortedForMobileCourses;
