import React, { useMemo, useCallback } from "react"; 
import { useTable, useGlobalFilter, useFilters, useSortBy, usePagination } from "react-table";
import { ArrowLongRightIcon, ArrowLongLeftIcon } from "@heroicons/react/24/outline";
import { ArrowUpIcon, ArrowDownIcon } from "@heroicons/react/24/solid";

// Helper functions and static data

const names = [
  'Alice Johnson', 'Bob Smith', 'Carol Williams', 'David Brown', 'Eve Davis',
  'Frank Miller', 'Grace Wilson', 'Hank Moore', 'Ivy Taylor', 'John Anderson'
];

const instructors = [
  'Dr. John Doe', 'Prof. Jane Smith', 'Dr. Emily Johnson', 'Prof. Michael Brown', 
  'Dr. Lisa White', 'Prof. David Green', 'Dr. Sarah Black', 'Prof. James Blue'
];

function getRandomDate() {
  const start = new Date(2024, 0, 1);
  const end = new Date(2024, 11, 31);
  const date = new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
}

function getRandomGrade() {
  return Math.floor(Math.random() * 101);
}

function getRandomItem(arr) {
  return arr[Math.floor(Math.random() * arr.length)];
}

function getRandomInstructor() {
  return getRandomItem(instructors);
}

function generateData(numRows) {
  return Array.from({ length: numRows }, () => ({
    grade: getRandomGrade(),
    name: getRandomItem(names),
    data: getRandomDate(),
    instructor: getRandomInstructor(), // New field
  }));
}

// Filter Components

function GlobalFilter({ filter, setFilter }) {
  return (
    <div className="mb-4 flex items-center gap-4">
      <input
        type="text"
        value={filter || ''}
        onChange={e => setFilter(e.target.value)}
        placeholder="Search..."
        className="p-2 border rounded-lg w-full"
      />
      <button
        onClick={() => setFilter('')}
        className="p-2 border rounded-lg bg-gray-200 hover:bg-gray-300"
      >
        Clear
      </button>
      <button
        onClick={() => alert('Filter applied')}
        className="p-2 border rounded-lg bg-big-text-color text-white hover:bg-blue-600"
      >
        Apply
      </button>
    </div>
  );
}

function ColumnFilter({ column }) {
  const { filterValue, setFilter } = column;
  return (
    <div className="mb-2">
      <input
        value={filterValue || ''}
        onChange={e => setFilter(e.target.value || undefined)}
        placeholder={`Search ${column.Header}`}
        className="p-2 border rounded-lg w-full"
      />
    </div>
  );
}

// Column Definitions

function getColumns() {
  return [
    {
      Header: 'Student Name',
      accessor: 'name',
      Filter: ColumnFilter,
    },
    {
      Header: 'Date',
      accessor: 'data',
      Filter: ColumnFilter,
    },
    {
      Header: 'Grade',
      accessor: 'grade',
    },
    {
      Header: 'Instructor Name', // New column header
      accessor: 'instructor', // New accessor
      Filter: ColumnFilter,
    },
    {
      id: 'actions',
      Header: '',
      Cell: ({ row }) => (
        <div className="flex justify-around">
          <button
            onClick={() => alert(`Editing ${row.original.name}`)}
            className="px-4 py-2 bg-primary-color text-white rounded-lg hover:bg-yellow-600"
          >
            Edit
          </button>
          <button
            onClick={() => alert(`Deleting ${row.original.name}`)}
            className="px-4 py-2 bg-red-500 text-white rounded-lg hover:bg-red-600"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
}

// Table Component

function TableComponent({
  getTableProps,
  headerGroups,
  getTableBodyProps,
  rows,
  prepareRow,
  globalFilter,
  setGlobalFilter,
}) {
  return (
    <div className="w-full bg-white rounded-xl shadow-[0_4px_10px_rgba(0,0,0,0.03)]">
      <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      <table {...getTableProps()} className="w-full table-auto border-separate border-spacing-6">
        <thead>
          <tr>
            {headerGroups[0].headers.map(column => (
              <th
                {...column.getHeaderProps(column.getSortByToggleProps())}
                className="pr-6 py-4 text-start text-2xl cursor-pointer"
                style={{ width: column.width }}
                key={column.id}
              >
                <div className="flex flex-col">
                  <div className="flex gap-2 items-center">
                    <div className="text-big-text-color">
                      {column.render("Header")}
                    </div>
                    <div className="flex flex-col">
                      <ArrowUpIcon
                        className={`text-lg translate-y-1/2 ${column.isSorted && !column.isSortedDesc ? "text-black" : "text-black"}`}
                      />
                      <ArrowDownIcon
                        className={`text-lg -translate-y-1/2 ${column.isSortedDesc ? "text-black" : "text-black"}`}
                      />
                    </div>
                  </div>
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map(row => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="hover:bg-gray-200">
                {row.cells.map(cell => (
                  <td
                    {...cell.getCellProps()}
                    className="pr-6 py-3 text-xl font-normal text-gray-700 first:rounded-l-lg last:rounded-r-lg"
                    key={cell.column.id}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}

// Pagination Component

function PaginationNav1({
  gotoPage,
  canPreviousPage,
  canNextPage,
  pageCount,
  pageIndex,
}) {
  const renderPageLinks = useCallback(() => {
    if (pageCount === 0) return null;
    const visiblePageButtonCount = 5;
    let numberOfButtons =
      pageCount < visiblePageButtonCount ? pageCount : visiblePageButtonCount;
    const pageIndices = [pageIndex];
    numberOfButtons--;
    [...Array(numberOfButtons)].forEach((_item, itemIndex) => {
      const pageNumberBefore = pageIndices[0] - 1;
      const pageNumberAfter = pageIndices[pageIndices.length - 1] + 1;
      if (pageNumberBefore > 0) {
        pageIndices.unshift(pageNumberBefore);
      }
      if (pageNumberAfter < pageCount) {
        pageIndices.push(pageNumberAfter);
      }
    });
    return pageIndices.map((number) => (
      <button
        key={number}
        onClick={() => gotoPage(number)}
        className={`mx-1 px-3 py-1 text-base rounded-lg ${number === pageIndex ? "bg-big-text-color text-white" : "bg-gray-200"}`}
      >
        {number + 1}
      </button>
    ));
  }, [pageCount, pageIndex, gotoPage]);

  return (
    <div className="flex items-center justify-between mt-4">
      <button
        onClick={() => gotoPage(0)}
        disabled={!canPreviousPage}
        className="px-4 py-2 bg-gray-300 text-black rounded-lg disabled:opacity-50"
      >
        <ArrowLongLeftIcon className="w-6 h-6" />
      </button>
      <div className="flex items-center">
        {renderPageLinks()}
      </div>
      <button
        onClick={() => gotoPage(pageCount - 1)}
        disabled={!canNextPage}
        className="px-4 py-2 bg-gray-300 text-black rounded-lg disabled:opacity-50"
      >
        <ArrowLongRightIcon className="w-6 h-6" />
      </button>
    </div>
  );
}

// Main Table Component

function Table1() {
  const data = useMemo(() => generateData(10), []);
  const columns = useMemo(getColumns, []);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    state,
    setGlobalFilter,
    state: { globalFilter, pageIndex },
    page: rows,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    setPageSize,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    useGlobalFilter,
    useFilters,
    useSortBy,
    usePagination
  );

  return (
    <div className="flex flex-col gap-6 w-full">
      <TableComponent
        getTableProps={getTableProps}
        headerGroups={headerGroups}
        getTableBodyProps={getTableBodyProps}
        rows={rows}
        prepareRow={prepareRow}
        globalFilter={globalFilter}
        setGlobalFilter={setGlobalFilter}
      />
      <PaginationNav1
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        canNextPage={canNextPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
      />
    </div>
  );
}

function TableAttemptQuizesStudents() {
  return (
    <div className="flex flex-col overflow-auto py-6 w-full">
      <Table1 />
    </div>
  );
}

export { TableAttemptQuizesStudents };
