import React, { useState } from 'react';
import { BookmarkIcon } from '@heroicons/react/24/solid'; 

const BookmarkButton = () => {
  const [isBookmarked, setIsBookmarked] = useState(false);

  const toggleBookmark = () => {
    setIsBookmarked(!isBookmarked);
  };

  return (
    <button onClick={toggleBookmark} className="focus:outline-none">
      <BookmarkIcon className={`w-4 h-4 ${isBookmarked ? 'text-big-text-color' : 'text-gray-200'}`} />
    </button>
  );
};

export default BookmarkButton;
